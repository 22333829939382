<template>
    <v-dialog 
        v-if="dialog"
        v-model="dialog"
        max-width="800"
        persistent
        scrollable
        content-class="elevation-0 dialog-container"
    >
        <v-card fluid class="container">
            <v-card-title class="tab-header">
                <v-row no-gutters align="center">
                    <v-col class="pa-0">
                        <v-icon color="#FFF9C4" size="32">
                            mdi-information-variant-circle-outline
                        </v-icon>
                        <b v-if="action_status == 'view'" class="ml-1 text-center text-header">
                            รายละเอียดเจ้าหน้าที่
                        </b>
                        <b v-else class="ml-1 text-center text-header">
                            แก้ไขรายละเอียดเจ้าหน้าที่
                        </b>
                    </v-col>
                    <v-col
                        cols="auto"
                        sm="auto"
                        md="auto"
                        class="pa-0"
                    >
                        <v-btn
                            depressed
                            icon
                            class="close-button"
                            color="#FFF9C4"
                            @click="dialog = false"
                        >
                            <v-icon size="32">
                                mdi-close
                            </v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text class="card-text-container">
                <v-col
                    cols="12"
                    style="padding-bottom: 10px;"
                    align="center"
                    v-if="action_status == 'edit'"
                    >
                    <v-badge bottom avatar icon="mdi-pencil" color="#f57520" :loading="isSelecting"  @click.native="handleFileImport" class="change-image-cursor">
                        <v-avatar
                            color="grey"
                            size="180"
                            rounded="lg"
                        >
                        <v-img v-if="detailData.uploaded_image || detailData.image_path" 
                            :src="detailData.uploaded_image? detailData.uploaded_image:detailData.image_path"
                            :lazy-src="`https://intranet.inet.co.th/assets/upload/staff/noname.jpg`"
                            >
                        </v-img>
                        <v-img v-else class="background-image "></v-img>
                        </v-avatar>
                    </v-badge>
                </v-col>
                <v-col
                    cols="12"
                    style="padding-bottom: 10px;"
                    align="center"
                    v-else
                    >
                    <v-avatar
                        color="grey"
                        size="180"
                        rounded="lg"
                    >
                    <v-img v-if="detailData.uploaded_image || detailData.image_path" 
                        :src="detailData.uploaded_image? detailData.uploaded_image:detailData.image_path"
                        :lazy-src="`https://intranet.inet.co.th/assets/upload/staff/noname.jpg`"
                        >
                    </v-img>
                    <v-img v-else class="background-image "></v-img>
                    </v-avatar>
                </v-col>
                <input 
                    ref="uploader" 
                    class="d-none" 
                    type="file" 
                    accept="image/*"
                    @change="onFileChanged"
                >
                <v-row no-gutters class="detail-data-container">
                    <v-col 
                        cols="12"
                        sm="6"
                        md="6"
                        align-self="center"
                    >
                        <v-text-field
                            label="ชื่อ-นามสกุล"
                            color="rgb(60, 86, 147)"
                            hide-details
                            :readonly="!editing_mode"
                            prepend-icon="mdi-account-outline"
                            class="input-text"
                            v-model="detailData.full_name_th"
                        ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        align-self="center"
                    >
                        <v-text-field
                            label="เลขบัตรประชาชน"
                            prepend-icon="mdi-card-account-details-outline"
                            color="rgb(60, 86, 147)"
                            hide-details
                            :readonly="!editing_mode"
                            class="input-text"
                            v-model="detailData.id_card_num"
                        ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        align-self="center"
                    >
                        <v-text-field
                            label="ระดับ"
                            prepend-icon="mdi-certificate-outline"
                            color="rgb(60, 86, 147)"
                            hide-details
                            :readonly="!editing_mode"
                            class="input-text"
                            v-model="detailData.level"
                        ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        align-self="center"
                    >
                        <v-text-field
                            label="เลขที่ประกาศนียบัตร"
                            prepend-icon="mdi-account-file-text-outline"
                            color="rgb(60, 86, 147)"
                            hide-details
                            :readonly="!editing_mode"
                            class="input-text"
                            v-model="detailData.certificated_number"
                        ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        align-self="center"
                    >
                        <v-text-field
                            label="อนุมัติ"
                            prepend-icon="mdi-pen-plus"
                            color="rgb(60, 86, 147)"
                            hide-details
                            :readonly="!editing_mode"
                            class="input-text"
                            :value="detailData.approve"
                        ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        align-self="center"
                    >
                        <v-text-field
                            label="เกณฑ์ประกาศนียบัตร"
                            prepend-icon="mdi-slash-forward-box"
                            color="rgb(60, 86, 147)"
                            hide-details
                            v-if="!editing_mode"
                            class="input-text"
                            :value="detailData.criteria == 'old'? 'เกณฑ์เดิม':'เกณฑ์ใหม่'"
                            v-model="detailData.criteria.text"
                        ></v-text-field>
                        <v-combobox 
                            v-else
                            class="input-text"
                            prepend-icon="mdi-slash-forward-box"
                            label="เกณฑ์ประกาศนียบัตร" 
                            v-model="detailData.criteria"
                            :value="detailData.criteria == 'old'? 'เกณฑ์เดิม':'เกณฑ์ใหม่' " 
                            :items="criteria_list"
                            style="padding-bottom: unset;"
                            return-object
                            item-text="text"
                            item-value="value"
                            >
                        </v-combobox>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="3"
                        md="3"
                        align-self="center"
                    >
                        <v-text-field
                            label="วันออกให้"
                            prepend-icon="mdi-file-send-outline"
                            color="rgb(60, 86, 147)"
                            hide-details
                            :readonly="!editing_mode"
                            class="input-text"
                            v-model="detailData.certificated_date"
                        ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="3"
                        md="3"
                        align-self="center"
                    >
                        <v-text-field
                            label="วันหมดอายุ"
                            prepend-icon="mdi-clock-end"
                            color="rgb(60, 86, 147)"
                            hide-details
                            :readonly="!editing_mode"
                            class="input-text"
                            v-model="detailData.expire_date"
                        ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        align-self="center"
                    >
                        <v-text-field
                            label="ประเภท"
                            prepend-icon="mdi-account-file-text-outline"
                            color="rgb(60, 86, 147)"
                            hide-details
                            :readonly="!editing_mode"
                            class="input-text"
                            v-model="detailData.type"
                        ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="4"
                        md="4"
                        align-self="center"
                    >
                        <v-text-field
                            label="จังหวัดต้นสังกัด"
                            prepend-icon="mdi-home-city-outline"
                            color="rgb(60, 86, 147)"
                            hide-details
                            v-if="!editing_mode"
                            class="input-text"
                            v-model="detailData.province"
                        ></v-text-field>
                        <v-combobox
                            v-else
                            prepend-icon="mdi-home-city-outline"
                            v-model="detailData.province"
                            :items="province_list"
                            label="จังหวัดต้นสังกัด"
                            class="input-text"
                            @change="detailData.agency='';changeProvince($event)"
                            style="padding-bottom: unset;"
                        ></v-combobox>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="8"
                        md="8"
                        align-self="center"
                    >
                        <v-text-field
                            label="หน่วยงานต้นสังกัด"
                            prepend-icon="mdi-hospital-building"
                            color="rgb(60, 86, 147)"
                            hide-details
                            v-if="!editing_mode"
                            class="input-text"
                            v-model="detailData.agency"
                        ></v-text-field>
                        <v-combobox
                            v-else
                            prepend-icon="mdi-hospital-building"
                            v-model="detailData.agency"
                            :items="detailData.province in medical_with_province_list ? medical_with_province_list[detailData.province] : []"
                            label="หน่วยงานต้นสังกัด"
                            class="input-text"
                            style="padding-bottom: unset;"
                        ></v-combobox>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn v-if="action_status == 'edit'" outlined color="success" @click="EditInfoStaff">
                    บันทึกข้อมูล
                </v-btn>
                <v-btn v-if="action_status == 'edit'" outlined color="error" class="mx-3" @click="dialog = false">
                    ปิดหน้าต่าง
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { EventBus } from '../EventBus';
    export default {
        data: () => ({
            dialog: false,
            detailData: {},
            editing_mode: false,
            isSelecting: false,
            selectedFile: null,
            new_uploaded_image: {},
            action_status: '',
            medical_with_province_list:[],
            province_list: [],
            expire_date: new Date().toISOString().substr(0, 10),
            certificated_date: new Date().toISOString().substr(0, 10),
            certificated_date_menu: false,
            expire_date_menu: false,
            criteria_list: [{
                "text":"เกณฑ์เดิม",
                "value":"old"
            },{
                "text":"เกณฑ์ใหม่",
                "value":"new"
            }],
            criteria_obj: {text: "",value: ""},
            isUploadImage: false
        }),
        mounted() {
            EventBus.$on('openDetailStaffByRoundModal', this.openDialog);
            // this.init()
        },
        beforeDestroy() {
            EventBus.$off('openDetailStaffByRoundModal');
        },
        methods: {
            openDialog(data,action) {
                this.dialog = true;
                this.detailData = data;
                this.action_status = action
                action == 'edit'? this.editing_mode = true: this.editing_mode = false
                if(data.criteria == 'old'){
                    data.criteria = {
                        text : 'เกณฑ์เดิม',
                        value : 'old'
                    }
                }else{
                    data.criteria = {
                        text : 'เกณฑ์ใหม่',
                        value : 'new'
                    }
                }
                this.init()
                this.changeProvince(this.detailData.province)
            },
            handleFileImport() {
                this.isSelecting = true;
                // After obtaining the focus when closing the FilePicker, return the button state to normal
                window.addEventListener('change', () => {
                    this.isSelecting = false
                }, { once: true });
                // Trigger click on the FileInput
                this.$refs.uploader.click();
            },
            onFileChanged(e) {
                // this.editedItem.uploaded_image = e.target.files[0];
                this.detailData.uploaded_image = URL.createObjectURL(e.target.files[0])
                this.new_uploaded_image = e.target.files[0]
                this.isUploadImage = true
                // TO DO
                // Do whatever you need with the file, liek reading it with FileReader
            },
            async init() {
                try {
                    // const datenow = new Date()
                    // this.year_generation_list = Array.from({length: datenow.getFullYear() - 2016}, (_, i) => (datenow.getFullYear() + 543 - i).toString());
                    this.loading = true
                    const configProvice = { baseURL: process.env.VUE_APP_API_URL, url: '/manage/api/v1/getprovince' }
                    const provinceData = await this.axios(configProvice)
                    if(provinceData.data.status) {
                        this.province_list =  provinceData.data.data
                        this.medical_with_province_list = this.province_list.reduce((obj, key) => Object.assign(obj, {[key]: []}), {})
                    }
                } catch (error) {
                    EventBus.$emit('alert-error', 'เกิดปัญหาระหว่างดึงข้อมูล กรุณาลองใหม่ภายหลัง')
                    console.error(error);
                } finally {
                    this.loading = false
                }
            },
            async EditInfoStaff(){
                try {
                    this.loading = true
                    var body = {... this.detailData}
                    body.criteria = body.criteria.value
                    body.no_image = this.isUploadImage ? false : true
                    var url = `/register_round/api/v1/edit_regis_user/${this.detailData._id}`
                    const { data } = await this.axios.put(url,body)
                    this.sendData()
                    if(data.status){
                        this.dialog = false
                        EventBus.$emit('alert-success', 'แก้ไขข้อมูลสำเร็จ');
                        EventBus.$emit('reloadGenCertModal',{page: 1,itemsPerPage: 20,keyword:""});
                    }
                } catch (error) {
                    console.log(error);
                    EventBus.$emit('alert-error', 'แก้ไขข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง');
                } finally {
                    this.isUploadImage = false
                    this.loading = false
                }
            },
            async changeProvince(province_selected) {
                if(Object.prototype.hasOwnProperty.call(this.medical_with_province_list, province_selected)&& this.medical_with_province_list[province_selected].length <= 0) {
                    try {
                        const config = { baseURL: process.env.VUE_APP_API_URL, url: '/manage/api/v1/getmo', params: { province:province_selected } }
                        const { data } = await this.axios(config)
                        if(data.status) this.medical_with_province_list[province_selected] = data.data.map(item => item.medical_operation_name)
                    } catch (error) {
                        console.error(error);
                        this.medical_with_province_list[province_selected] = []
                    }
                }
            },
            checkEndDate () {
                const start_date = new Date(this.certificated_date),
                end_date = new Date(this.expire_date)
                if(end_date < start_date) this.expire_date = this.certificated_date
            },
            async sendData () {
                var staff_data = {
                    id_card_num : this.detailData.id_card_num,
                    from: 'regis_round'
                }
                try {
                    this.loading = true
                    let formdata = new FormData()
                    formdata.append(`register_data[]`, JSON.stringify(staff_data))
                    formdata.append(`file`, this.new_uploaded_image)
                    const config = {
                        baseURL: process.env.VUE_APP_API_URL,
                        url: '/public/api/v1/save_image',
                        method: 'POST',
                        data: formdata,
                        headers: {
                            "Content-Type": "multipart/form-data",
                        }
                    }
                    const { data } = await this.axios(config)
                    if(data.status) {
                        EventBus.$emit('alert-success', 'บันทึกสำเร็จ');
                    }
                } catch (error) {
                    EventBus.$emit('alert-error', 'เกิดข้อผิดพลาดในการบันทึก' + '\n' + JSON.stringify(error), 0)
                    console.log(error);
                } finally {
                    this.loading = false
                }
            },
        }
    }
</script>

<style scoped>
    .row {
        width: 100%;
        margin: 0px;
    }

    .container {
        padding: 0;
        border-radius: 18px;
        font-family: "Noto Sans Thai", sans-serif;
        
        /* height: calc(100vh - 96px);
        display: flex !important;
        flex-direction: column; */
    }

    .tab-header {
        padding: 8px 12px !important;
        color: #FFF9C4;
        background-color: rgb(60, 86, 147);
    }

    .card-text-container {
        /* flex-grow: 1;
        overflow: auto; */

        padding: 24px;
    }

    .detail-data-container {
        padding-top: 14px;
    }

    .input-text {
        padding: 14px 8px;
        margin-top: 0;
    }

    .input-text >>> .v-input__control > .v-input__slot > .v-text-field__slot > input {
        line-height: 30px;
    }

    .change-image-cursor {
        cursor: pointer;
    }

    .background-image {
        background-image: url('https://intranet.inet.co.th/assets/upload/staff/noname.jpg');
        background-size: 180px;
        background-position: top;
        /* You can also set other background properties like background-size, background-repeat, etc. */
    }

    @media only screen and (max-width: 37.5rem) {
        .text-header {
            font-size: 22px;
        }

        .card-text-container {
            padding: 12px !important;
        }
    }

    @media only screen and (max-width: 25rem) {
        .text-header {
            font-size: 18px;
        }
    }
</style>