<template>
  <v-container fluid class="login-page">
    <v-row justify="center" class="login-row login-card-row">
      <v-col cols="11" md="6" lg="6" align-self="center" class="pa-0">
        <v-card flat color="#FFF9C4" class="login-card">
          <v-card-title class="pl-0 pt-10 login-header-block">
            <div class="py-2 pl-2 pr-3 login-header">
              ระบบลงทะเบียนผู้ผ่านการฝึกอบรม และเปลี่ยนผ่าน/เทียบเท่า
            </div>
          </v-card-title>
          <v-card-text class="pt-13 pb-10">
            <v-form ref="form" lazy-validation @submit.prevent="login">
              <v-row class="login-row">
                <v-col cols="5" md="3" lg="3" align-self="center" class="pl-0 login-title">
                  <b>Username :</b>
                </v-col>
                <v-col cols="7" md="8" lg="8" class="px-0">
                  <v-text-field dense outlined hide-details rounded background-color="white"
                    class="login-text" :rules="rules.required" v-model="username" :readonly="loading"></v-text-field>
                </v-col>
              </v-row>
              <v-row class="mt-0 login-row">
                <v-col cols="5" md="3" lg="3" align-self="center" class="pl-0 login-title">
                  <b>Password :</b>
                </v-col>
                <v-col cols="7" md="8" lg="8" class="px-0">
                  <v-text-field dense outlined hide-details rounded background-color="white"
                    :type="open_password ? 'text' : 'password'" :append-icon="open_password ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="open_password = !open_password" class="login-text" :rules="rules.required" v-model="password" :readonly="loading"></v-text-field>
                </v-col>
              </v-row>
              <v-row justify="center" class="mt-11 login-row">
                <v-btn depressed large rounded color="#3495cf" :loading="loading" :disabled="loading" type="submit"
                  class="px-10 login-btn">
                  <b>Login</b>
                </v-btn>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>  
        <div class="pt-5" >
        <v-btn style="border-radius: 40px !important;" block depressed x-large dark color="#2a9d8f" class="select-regis-test-btn" to="/search_certificated" >
				<v-icon color="#fff9c4" class="select-regis-test-icon">mdi mdi-text-box-search-outline</v-icon>
					<b class="ml-2">ค้นหาข้อมูลประกาศนียบัตรผู้ปฏิบัติการจากระบบลงทะเบียน</b>
				</v-btn>
      </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { EventBus } from '../EventBus'
export default {
  data: () => ({
    open_password: false,
    loading: false,
    rules: {
      required: [v => !!v || 'Name is required']
    },
    username: null,
    password: null
  }),
  mounted() {
            
    if(sessionStorage.sessionExpired == 'true') this.alertError('Session หมดอายุ กรุณาเข้าสู่ระบบใหม่อีกครั้ง')
    this.$apiConfig.setToken(null)
    sessionStorage.clear()

  },
  methods: {
    alertError(html = '', timer = 4000) {
      this.$swal({
        icon: 'error',
        html,
        showCloseButton: true,
        showConfirmButton: false,
        timer: timer,
        customClass: {
          htmlContainer: 'alert-text'
        }
      })
    },
    async login () {
      if(!this.$refs.form.validate()) {
        EventBus.$emit('alert-warning', 'กรุณากรอก Username และ Password ให้ครบถ้วน')
        return
      }
      try {
        this.loading = true
        const username = this.username, password = this.password;
        const { data } = await this.axios.post('/user/api/v1/login', {username, password});
        if(data.status) {
          let saveSession = {
            training_organization_name: data.data.training_organization_name,
            province: data.data.province,
            username: data.data.username,
          }
          if(data.data.is_admin) saveSession.is_admin = data.data.is_admin ?? false
          sessionStorage.userProfile = JSON.stringify(saveSession)
          this.$apiConfig.setToken(data.data.access_token)
          this.$router.replace({name: 'select_regis'})
        }
      } catch (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          if (error.response.status == 401) {
            EventBus.$emit('alert-error', 'Username หรือ Password ไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง')
          }
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          // console.log('Error', error.message);
        }
        console.log(error.config);
      } finally {
        this.loading = false
      }
    }
  },
  
}
</script>

<style>
.login-page {
  height: 100%;
}

.login-row {
  width: 100%;
  margin: 0%;
}

.login-card-row {
  height: 100%;
}

.login-card {
  border-radius: 10px 45px !important;
}

.login-header-block {
  padding-right: 2% !important;
}

.login-header {
  font-family: 'Noto Sans Thai', sans-serif;
  font-weight: 700;
  font-size: 20px;
  word-break: break-word;
  color: white;
  background-color: #3495cf;
  border-top-right-radius: 23px;
  border-bottom-right-radius: 23px;
}

.login-title {
  font-family: 'Noto Sans Thai', sans-serif;
  font-size: 18px;
  color: black;
}

.login-text {
  font-family: 'Noto Sans Thai', sans-serif;
  font-size: 15px;
}

.login-btn {
  font-family: 'Noto Sans Thai', sans-serif;
  text-transform: capitalize;
  color: white !important;
  font-size: 18px !important;
}

.theme--light.login-btn.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: rgb(231 231 231) !important;
}


/*========================================*/

@media only screen and (max-width:600px) {

  /* css for mobile */
  .login-header-block {
    padding-right: 4% !important;
  }

  .login-header {
    font-size: 19px;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
  }

  .login-text.v-text-field--rounded>.v-input__control>.v-input__slot {
    padding-left: 7% !important;
    padding-right: 7% !important;
  }
}</style>