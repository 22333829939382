<template>
    <div>
        <v-overlay v-model="loading"> <!-- page loading -->
            <v-progress-circular indeterminate color="#FFF9C4" :size="80" :width="7"></v-progress-circular>
        </v-overlay>
        <v-row class="pass-exam-row pass-exam-header-row">
            <v-col cols="auto" md="auto" lg="auto" class="py-2">
                <v-btn depressed small fab color="rgb(154,179,209)" @click="$router.push({ name: 'select_regis' })">
                    <!-- back button -->
                    <v-icon color="rgb(60,86,147)">mdi-chevron-double-left</v-icon>
                </v-btn>
            </v-col>
            <v-col cols="9" md="auto" lg="auto" class="mt-1 px-4 py-2 pass-exam-header-block">
                <b class="pass-exam-header">รายชื่อเจ้าหน้าที่</b>
            </v-col>
        </v-row>
        <v-row no-gutters class="standard-row mt-4 mb-7" v-resize="onResize">
            <div style="padding: 0% 2.4% 0 2.7%; width: 100%;">
                <!-- v-model="selected" item-value="no" return-object show-select  :single-select="singleSelect" -->
                <v-data-table :headers="headers" range :items="staffs" fixed-header v-model="selected"
                    :single-select="singleSelect" item-key="_id" item-value="_id" show-select
                    class="elevation-0 data-table-setting" style="width: 100%"
                    :footer-props="{ 'items-per-page-options': [10, 20, 30, 40, 50], 'items-per-page-text': 'จำนวนแถวต่อหน้า' }"
                    mobile-breakpoint="601" 
                    :items-per-page.sync="pageOption.limit" 
                    :page.sync="pageOption.page"
                    :server-items-length="staffCount"
                    >
                    <template v-slot:top>
                        <v-toolbar flat>
                            <v-btn v-if="windowSize.x > 500" color="primary" @click="newItem()"
                                :disabled="!hasSelectedItems"> <svg xmlns="http://www.w3.org/2000/svg" width="20"
                                    height="20" color="#ffff" fill="currentColor" class="bi bi-file-earmark-pdf"
                                    viewBox="0 0 16 16">
                                    <path
                                        d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z" />
                                    <path
                                        d="M4.603 14.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.697 19.697 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.188-.012.396-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.066.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.712 5.712 0 0 1-.911-.95 11.651 11.651 0 0 0-1.997.406 11.307 11.307 0 0 1-1.02 1.51c-.292.35-.609.656-.927.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.266.266 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.71 12.71 0 0 1 1.01-.193 11.744 11.744 0 0 1-.51-.858 20.801 20.801 0 0 1-.5 1.05zm2.446.45c.15.163.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.876 3.876 0 0 0-.612-.053zM8.078 7.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z" />
                                </svg> ออกประกาศนียบัตร</v-btn>
                            <v-btn v-else color="primary" @click="newItem()" :disabled="!hasSelectedItems"> <svg
                                    xmlns="http://www.w3.org/2000/svg" width="20" height="20" color="#ffff"
                                    fill="currentColor" class="bi bi-file-earmark-pdf" viewBox="0 0 16 16">
                                    <path
                                        d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z" />
                                    <path
                                        d="M4.603 14.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.697 19.697 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.188-.012.396-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.066.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.712 5.712 0 0 1-.911-.95 11.651 11.651 0 0 0-1.997.406 11.307 11.307 0 0 1-1.02 1.51c-.292.35-.609.656-.927.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.266.266 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.71 12.71 0 0 1 1.01-.193 11.744 11.744 0 0 1-.51-.858 20.801 20.801 0 0 1-.5 1.05zm2.446.45c.15.163.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.876 3.876 0 0 0-.612-.053zM8.078 7.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z" />
                                </svg></v-btn>
                            <v-spacer></v-spacer>
                            <v-text-field v-model="search" append-icon="mdi-magnify" label="ค้นหา" single-line
                             hide-details @keyup.enter="onPressEnter" @click:append="onPressEnter"></v-text-field>
                        </v-toolbar>
                    </template>
                    <template v-slot:item.gen_cert="{ item }">
                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <v-btn icon v-on="on" @click="confirmDialog(item)">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" color="rgb(60,86,147)"
                                        fill="currentColor" class="bi bi-file-earmark-pdf" viewBox="0 0 16 16">
                                        <path
                                            d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z" />
                                        <path
                                            d="M4.603 14.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.697 19.697 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.188-.012.396-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.066.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.712 5.712 0 0 1-.911-.95 11.651 11.651 0 0 0-1.997.406 11.307 11.307 0 0 1-1.02 1.51c-.292.35-.609.656-.927.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.266.266 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.71 12.71 0 0 1 1.01-.193 11.744 11.744 0 0 1-.51-.858 20.801 20.801 0 0 1-.5 1.05zm2.446.45c.15.163.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.876 3.876 0 0 0-.612-.053zM8.078 7.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z" />
                                    </svg>
                                </v-btn>
                            </template>
                            <span class="font-theme">ออกประกาศนียบัตร</span>
                        </v-tooltip>

                        <v-tooltip top v-if="item.is_send_certificate !== true">
                            <template v-slot:activator="{ on }">
                                <v-btn icon v-on="on" :disabled="item.is_send_certificate !== true">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22"
                                        color="rgb(198, 201, 207)" fill="currentColor" class="bi bi-file-earmark-pdf"
                                        viewBox="0 0 16 16">
                                        <path
                                            d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                        <path
                                            d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                                    </svg>
                                </v-btn>
                            </template>
                            <span class="font-theme">ดาวน์โหลดประกาศนียบัตร</span>
                        </v-tooltip>
                        <v-tooltip top v-else>
                            <template v-slot:activator="{ on }">
                                <v-btn icon v-on="on" @click="confirmDialog2(item)">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" color="rgb(60,86,147)"
                                        fill="currentColor" class="bi bi-file-earmark-pdf" viewBox="0 0 16 16">
                                        <path
                                            d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                        <path
                                            d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                                    </svg>
                                </v-btn>
                            </template>
                            <span class="font-theme">ดาวน์โหลดประกาศนียบัตร</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
            </div>
        </v-row>
        <v-dialog v-model="dialog_gen_cert" width="auto" class="dialog">
            <template>
                <v-card style="font-family: 'Noto Sans Thai', sans-serif;">
                    <v-toolbar color="rgb(60,86,147)" align="center" dark>{{ dialog_text }}
                        <v-btn v-if="showButtons_genCert" icon dark @click="closeDialog()"
                            class="ml-auto">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-card-text style="font-family: 'Noto Sans Thai', sans-serif;">
                        <h3 class="pa-12">{{ dialog_text2 }}
                        </h3>
                        <!-- <h4 v-if="!showButtons_genCert">
                            {{ dialog_text3 }}
                        </h4> -->
                        <v-divider></v-divider>
                        <v-card-actions v-if="showButtons_genCert" align="center">
                            <v-col align="center">
                                <v-btn @click="download_genCert('old_certificate')" color="warning" variant="text"
                                    outlined>เกณฑ์เดิม</v-btn>
                            </v-col>
                            <v-col align="center">
                                <v-btn @click="download_genCert('new_certificate')" color="success" variant="text"
                                    outlined>เกณฑ์ใหม่</v-btn>
                            </v-col>
                            <v-col align="center">
                                <v-btn @click="download_genCert('All')" color="info" variant="text" outlined>ทั้ง 2
                                    ประเภท</v-btn>
                            </v-col>
                        </v-card-actions>
                        <v-card-actions v-else class="justify-end">
                            <v-btn color="#ff5252" variant="text" @click="closeDialog()" outlined>ยกเลิก</v-btn>
                            <v-btn color="rgb(60,86,147)" dark variant="text" @click="genCert()">ตกลง</v-btn>
                        </v-card-actions>
                    </v-card-text>
                </v-card>
            </template>
        </v-dialog>
    </div>
</template>

<script>
import { EventBus } from '../EventBus'
export default {
    data: () => ({
        loading: false,
        windowSize: { x: 0, y: 0 },
        search: '',
        dialog_gen_cert: false,
        selected: [],
        singleSelect: false,
        countSelected: 0,
        showButtons_genCert: false,
        new_certificate: '', // กำหนดค่าเริ่มต้นเป็น ''
        old_certificate: '', // กำหนดค่าเริ่มต้นเป็น ''
        /** @type {(import('vuetify/types').DataTableHeader)[]} */
        headers: [
            { text: "ลำดับ", value: "no", align: "center", sortable: false, },
            { text: "ระดับ", value: "level", align: "center", sortable: false, },
            { text: "เลขประกาศนียบัตร", value: "certificated_number", align: "center", sortable: false, },
            { text: "ชื่อ - นามสกุล", value: "full_name", align: "left", sortable: false, },
            { text: "หน่วยงานต้นสังกัด", value: "agency", align: "center", sortable: false, },
            { text: "จังหวัดต้นสังกัด", value: "province", align: "center", sortable: false, },
            { text: "อนุมัติ", value: "approve", align: "center", sortable: false, },
            { text: "", value: "gen_cert", align: "center", sortable: false }
        ],
        staffCount: 0,
        staffs: [],
        pageOption: {
            page: 1,
            limit: 20,
        },
        dialog_text: '',
        dialog_text2: '',
        dialog_text3: '',
        selectedStaffs: []
    }),
    watch: {
        // ตรวจสอบการเปลี่ยนแปลงของตัวแปร selected และอัปเดตค่า countSelected
        // selected(newVal) {
        //     this.countSelected = newVal.length; // สมมุติให้ selected เป็น string และต้องการนับจำนวนตัวอักษร
        // },
        pageOption: {
            deep: true,
            handler(newVal) {
                this.updateStaffList({
                    keyword: this.search,
                    ...newVal,
                })
            }
        }
    },
    mounted() {
        this.onResize()
        this.updateStaffList({
            keyword: this.search,
            ...this.pageOption,
        })
    },
    computed: {
        hasSelectedItems() {
            return this.selected.length > 0; // True if there are selected items, false otherwise
        },
    },
    methods: {
        async onPressEnter(e){
            e.target.blur()
            return await this.updateStaffList({
                keyword: this.search,
                ...this.pageOption,
            })
        },
        async updateStaffList({keyword, page, limit} = {}) {
            try {
                this.loading = true
                const axios = this.axios;
                const [ countResp, dataResp ] = await Promise.all([
                    axios.get('/certificated/api/v1/count_certificated', {
                        params: {
                            keyword: keyword || undefined,
                        }
                    }),
                    axios.get('/certificated/api/v1/search_certificated', {
                        params: {
                            keyword: keyword || undefined,
                            offset: (page - 1) * limit,
                            limit
                        }
                    }),
                ])
                this.staffCount = countResp.data.data
                this.staffs = dataResp.data.data.map((x, i) => ({
                    "no": (page - 1) * limit + i + 1,
                    ...x
                }))
                return dataResp.data;
            } catch (error) {
                // do some error handler
                console.log(error);
            } finally {
                this.loading = false
            }
        },
        onResize() {
            this.windowSize = { x: window.innerWidth, y: window.innerHeight }
        },
        newItem() {
            this.showButtons_genCert = false;
            this.selectedStaffs = []
            this.dialog_text = 'ออกประกาศนียบัตร'
            this.dialog_text2 = 'คุณยืนยันที่จะออกประกาศนียบัตรเจ้าหน้าที่จำนวน: ' + this.selected.length + ' คน หรือไม่'
            this.dialog_text3 = this.selected.map(el => {return el.full_name})
            // this.dialog_text3 = this.selected[0].full_name
            this.dialog_gen_cert = true
            this.selectedStaffs = this.selected
        },
        confirmDialog(item) {
            this.showButtons_genCert = false;
            this.selectedStaffs = []
            this.dialog_text = 'ออกประกาศนียบัตร'
            this.dialog_text2 = 'คุณยืนยันที่จะออกประกาศนียบัตรเจ้าหน้าที่: ' + item.full_name + ' หรือไม่'
            this.dialog_gen_cert = true
            this.selectedStaffs.push(item)
        },
        confirmDialog2(item) {
            this.showButtons_genCert = true; // เปิดการแสดงปุ่มเมื่อคลิกปุ่ม
            this.selectedStaffs = []
            this.dialog_text = 'ดาวน์โหลดประกาศนียบัตร'
            this.dialog_text2 = 'คุณต้องการดาวน์โหลดประกาศนียบัตรประเภทใด: '
            this.dialog_gen_cert = true
            this.selectedStaffs.push(item)
        },
        closeDialog() {
            this.dialog_gen_cert = false;
            this.showButtons_genCert = false; // ปิดการแสดงปุ่มเมื่อปิดไดอล็อก
        },
        async genCert(){
            try {
                this.loading = true
                var gen_cert_staff = this.selectedStaffs.map(el => {return el._id})
                const { data } = await this.axios.post('/certificated/api/v1/send_get_certificated', {
                    "certificated_id" : gen_cert_staff
                })
                if(data.status){
                    if(data.data.some(item => item.status == 'fail')){
                        EventBus.$emit('alert-error', 'ออกใบประกาศนียบัตรไม่สำเร็จ เนื่องจากข้อมูลในการออกประกาศนียบัตรไม่ครบถ้วน กรุณาลองใหม่อีกครั้งหรือกรุณาติดต่อเจ้าหน้าที่')
                    }else{
                        EventBus.$emit('alert-success', 'ออกใบประกาศนียบัตรสำเร็จ')
                    }
                }
            } catch (error) {
                console.log(error);
                EventBus.$emit('alert-error', 'ออกใบประกาศนียบัตรไม่สำเร็จ กรุณาลองใหม่อีกครั้ง')
            } finally {
                this.dialog_gen_cert = false
                await this.updateStaffList({
                    keyword: this.search,
                    ...this.pageOption,
                })
                this.loading = false
            }
        },
        async download_genCert(type) {
            try {
                this.loading = true;
                var gen_cert_staff = this.selectedStaffs.map(el => { return el._id });
                const { data } = await this.axios.get('/certificated/api/v1/get_certificate_data', {
                    params: {
                        "certificated_id": gen_cert_staff
                    }
                });
                if (data.status) {
                    const certificateData = data.data;
                    let url = [];
                    if (type === 'new_certificate') {
                        url = [certificateData.new_certificate];
                    } else if (type === 'old_certificate') {
                        url = [certificateData.old_certificate];
                    } else if (type === 'All') {
                        url = [certificateData.new_certificate , certificateData.old_certificate];
                    }
                    this.downloadFile(url);
                    EventBus.$emit('alert-success', 'ออกใบประกาศนียบัตรสำเร็จ');
                    console.log(data);
                }
            } catch (error) {
                console.log(error);
                EventBus.$emit('alert-error', 'ออกใบประกาศนียบัตรไม่สำเร็จ กรุณาลองใหม่อีกครั้ง');
            } finally {
                this.dialog_gen_cert = false;
                this.loading = false;
            }
        },
        downloadFile(url) {
            url.forEach((url2,index) => {
                let link = document.createElement('a');
                link.href = url2;
                link.download = url2.split('/').pop();
                setTimeout(() => {
                link.click();  
                }, index * 1000); // หน่วงเวลา 1 วินาที ระหว่างการดาวน์โหลดแต่ละไฟล์
            });
        },
    }
}
</script>

<style>
.dialog {
    font-family: 'Noto Sans Thai', sans-serif;
}

.data-table-setting {
    background: rgba(255, 255, 255, 0.44) !important;
    border-radius: 10px;
    font-family: 'Noto Sans Thai', sans-serif;
}

.data-table-setting thead th {
    background-color: #f0f2f5 !important;
}

.data-table-setting thead th:first-child {
    border-radius: 10px 0 0 0;
    font-weight: 700;
}

.data-table-setting thead th:last-child {
    border-radius: 0 10px 0 0;
}

.data-table-setting .v-data-table__wrapper table thead {
    background-color: white;
}

.data-table-setting .v-data-table__wrapper table thead tr th span {
    font-weight: 700 !important;
    font-size: 15px;
}

.data-table-setting .v-data-table__wrapper table tbody tr td {
    font-weight: 700 !important;
    font-size: 13px;
    border-bottom: none !important;
    padding-top: 5px;
}

.data-table-setting.v-data-table>.v-data-table__wrapper {
    height: calc(100vh - 283px);
    overflow: auto;
}
</style>