<template>
    <div>
        <v-overlay v-model="loading"> <!-- page loading -->
            <v-progress-circular indeterminate color="#FFF9C4" :size="80" :width="7"></v-progress-circular>
        </v-overlay>
        <v-row class="pass-exam-row pass-exam-header-row">
            <v-col cols="auto" md="auto" lg="auto" class="py-2">
                <v-btn depressed small fab color="rgb(154,179,209)" @click="$router.push({ name: 'success_training_form' })">
                    <!-- back button -->
                    <v-icon color="rgb(60,86,147)">mdi-chevron-double-left</v-icon>
                </v-btn>
            </v-col>
            <v-col cols="9" md="auto" lg="auto" class="mt-1 px-4 py-2 pass-exam-header-block">
                <b class="pass-exam-header">รายชื่อที่ลงทะเบียนรับรองผู้สำเร็จการอบรมหลักสูตรเปลี่ยนผ่าน/เทียบเท่า</b>
            </v-col>
        </v-row>
        <v-row no-gutters class="standard-row mt-4 mb-7" v-resize="onResize">
            <div style="padding: 0% 2.4% 0 2.7%; width: 100%;">
                <!-- v-model="selected" item-value="no" return-object show-select  :single-select="singleSelect" -->
                <v-data-table :headers="headers" range :items="staffs" fixed-header v-model="selected"
                    item-key="_id" item-value="_id" 
                    class="elevation-0 data-table-setting" style="width: 100%"
                    :footer-props="{ 'items-per-page-options': [10, 20, 30, 40, 50], 'items-per-page-text': 'จำนวนแถวต่อหน้า' }"
                    mobile-breakpoint="601" 
                    :items-per-page.sync="pageOption.limit" 
                    :page.sync="pageOption.page"
                    :server-items-length="staffCount"
                    >
                    <template v-slot:top>
                        <v-toolbar flat>
                            <!-- <v-btn v-if="windowSize.x > 500" color="primary" @click="newItem()"
                                :disabled="!hasSelectedItems"> <svg xmlns="http://www.w3.org/2000/svg" width="20"
                                    height="20" color="#ffff" fill="currentColor" class="bi bi-file-earmark-pdf"
                                    viewBox="0 0 16 16">
                                    <path
                                        d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z" />
                                    <path
                                        d="M4.603 14.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.697 19.697 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.188-.012.396-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.066.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.712 5.712 0 0 1-.911-.95 11.651 11.651 0 0 0-1.997.406 11.307 11.307 0 0 1-1.02 1.51c-.292.35-.609.656-.927.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.266.266 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.71 12.71 0 0 1 1.01-.193 11.744 11.744 0 0 1-.51-.858 20.801 20.801 0 0 1-.5 1.05zm2.446.45c.15.163.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.876 3.876 0 0 0-.612-.053zM8.078 7.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z" />
                                </svg> ออกประกาศนียบัตร</v-btn>
                            <v-btn v-else color="primary" @click="newItem()" :disabled="!hasSelectedItems"> <svg
                                    xmlns="http://www.w3.org/2000/svg" width="20" height="20" color="#ffff"
                                    fill="currentColor" class="bi bi-file-earmark-pdf" viewBox="0 0 16 16">
                                    <path
                                        d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z" />
                                    <path
                                        d="M4.603 14.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.697 19.697 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.188-.012.396-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.066.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.712 5.712 0 0 1-.911-.95 11.651 11.651 0 0 0-1.997.406 11.307 11.307 0 0 1-1.02 1.51c-.292.35-.609.656-.927.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.266.266 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.71 12.71 0 0 1 1.01-.193 11.744 11.744 0 0 1-.51-.858 20.801 20.801 0 0 1-.5 1.05zm2.446.45c.15.163.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.876 3.876 0 0 0-.612-.053zM8.078 7.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z" />
                                </svg></v-btn> -->
                            <v-spacer></v-spacer>
                            <v-text-field v-model="search" append-icon="mdi-magnify" label="ค้นหา" single-line
                             hide-details @keyup.enter="onPressEnter" @click:append="onPressEnter"></v-text-field>
                        </v-toolbar>
                    </template>
                    <template v-slot:[`item.action`]="{ item }">
                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <v-btn icon v-on="on" @click="openEditDialog(item)">
                                    <svg fill="#000000" width="20" height="20" xmlns="http://www.w3.org/2000/svg" class="jam jam-pencil" color="rgb(198, 201, 207)">
                                        <path d='M12.238 5.472L3.2 14.51l-.591 2.016 1.975-.571 9.068-9.068-1.414-1.415zM13.78 3.93l1.414 1.414 1.318-1.318a.5.5 0 0 0 0-.707l-.708-.707a.5.5 0 0 0-.707 0L13.781 3.93zm3.439-2.732l.707.707a2.5 2.5 0 0 1 0 3.535L5.634 17.733l-4.22 1.22a1 1 0 0 1-1.237-1.241l1.248-4.255 12.26-12.26a2.5 2.5 0 0 1 3.535 0z'/>
                                    </svg>
                                </v-btn>
                            </template>
                            <span class="font-theme">แก้ไขข้อมูล</span>
                        </v-tooltip>
                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <v-btn icon v-on="on" @click="openDeleteDialog(item)">
                                    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> 
                                            <path d="M7.69231 8.70833H5V8.16667H9.84615M7.69231 8.70833V19H16.3077V8.70833M7.69231 8.70833H16.3077M16.3077 8.70833H19V8.16667H14.1538M9.84615 8.16667V6H14.1538V8.16667M9.84615 8.16667H14.1538" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M10 11V17" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M12 11V17" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M14 11V17" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"></path> </g>
                                    </svg>
                                </v-btn>
                            </template>
                            <span class="font-theme">ลบข้อมูล</span>
                        </v-tooltip>
                        <!-- <v-tooltip top >
                            <template v-slot:activator="{ on }">
                                <v-btn icon v-on="on" @click="detailDialog(item)">
                                    <svg fill="#000000" width="20" height="20" xmlns="http://www.w3.org/2000/svg" class="jam jam-document">
                                        <path d='M3 0h10a3 3 0 0 1 3 3v14a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3zm0 2a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H3zm2 1h6a1 1 0 0 1 0 2H5a1 1 0 1 1 0-2zm0 12h2a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2zm0-4h6a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2zm0-4h6a1 1 0 0 1 0 2H5a1 1 0 1 1 0-2z'/>
                                    </svg>
                                </v-btn>
                            </template>
                            <span class="font-theme">รายละเอียดรายชื่อ</span>
                        </v-tooltip> -->
                    </template>
                </v-data-table>
            </div>
        </v-row>
        <v-dialog v-model="dialog_gen_cert" width="auto" class="dialog">
            <template>
                <v-card style="font-family: 'Noto Sans Thai', sans-serif;">
                    <v-toolbar color="rgb(60,86,147)" align="center" dark>{{ dialog_text }}
                        <v-btn v-if="showButtons_genCert" icon dark @click="closeDialog()"
                            class="ml-auto">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-card-text style="font-family: 'Noto Sans Thai', sans-serif;">
                        <h3 class="pa-12">{{ dialog_text2 }}
                        </h3>
                        <!-- <h4 v-if="!showButtons_genCert">
                            {{ dialog_text3 }}
                        </h4> -->
                        <v-divider></v-divider>
                        <v-card-actions v-if="showButtons_genCert" align="center">
                            <v-col align="center">
                                <v-btn @click="download_genCert('old_certificate')" color="warning" variant="text"
                                    outlined>เกณฑ์เดิม</v-btn>
                            </v-col>
                            <v-col align="center">
                                <v-btn @click="download_genCert('new_certificate')" color="success" variant="text"
                                    outlined>เกณฑ์ใหม่</v-btn>
                            </v-col>
                            <v-col align="center">
                                <v-btn @click="download_genCert('All')" color="info" variant="text" outlined>ทั้ง 2
                                    ประเภท</v-btn>
                            </v-col>
                        </v-card-actions>
                        <v-card-actions v-else class="justify-end">
                            <v-btn color="#ff5252" variant="text" @click="closeDialog()" outlined>ยกเลิก</v-btn>
                            <v-btn color="rgb(60,86,147)" dark variant="text" @click="genCert()">ตกลง</v-btn>
                        </v-card-actions>
                    </v-card-text>
                </v-card>
            </template>
        </v-dialog>
        <v-dialog
            v-model="editDialog"
            persistent
            max-width="850px"
            >
            <v-card style="font-family: 'Noto Sans Thai', sans-serif;">
                <v-card-title>
                    <span style="padding-bottom: 15px;">รายละเอียดเจ้าหน้าที่</span>
                </v-card-title>
                <v-card-text>
                <v-container >
                    <v-row>
                    <v-col
                        cols="12"
                        style="padding-bottom: 10px;"
                        align="center"
                        >
                        <v-badge bottom avatar icon="mdi-pencil" color="rgb(60,86,147)" :loading="isSelecting"  @click.native="handleFileImport">
                            <v-avatar
                                color="grey"
                                size="180"
                                rounded="lg"
                            >
                                <v-img v-if="editedItem.uploaded_image || editedItem.image_path" 
                                    :src="editedItem.uploaded_image? editedItem.uploaded_image:editedItem.image_path"
                                    :lazy-src="`https://intranet.inet.co.th/assets/upload/staff/noname.jpg`"
                                    >
                                </v-img>
                                <v-img v-else class="background-image"></v-img>
                            </v-avatar>
                        </v-badge>
                    </v-col>
                    <input 
                        ref="uploader" 
                        class="d-none" 
                        type="file" 
                        accept="image/*"
                        @change="onFileChanged"
                    >
                    <v-col 
                        cols="12"
                        sm="6"
                        md="6"
                        style="padding-bottom: 0px;"
                    >
                        <v-text-field
                        prepend-icon="mdi-account-outline"
                        label="ชื่อ-นามสกุล"
                        v-model="editedItem.name"
                        ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        style="padding-bottom: 0px;"
                    >
                        <v-text-field
                        prepend-icon="mdi-card-account-details-outline"
                        label="เลขบัตรประชาชน"
                        v-model="editedItem.id_card_num"
                        ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        style="padding-bottom: 0px;"
                    >
                        <v-text-field
                        prepend-icon="mdi-phone-outline"
                        label="เบอร์โทรติดต่อ"
                        v-model="editedItem.tel_no"
                        ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        style="padding-bottom: 0px;"
                    >
                        <v-text-field
                        prepend-icon="mdi-chat-outline"
                        label="Line ID"
                        v-model="editedItem.line_id"
                        ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="3"
                        md="3"
                        style="padding-bottom: 0px;"
                    >
                        <v-text-field
                        prepend-icon="mdi-counter"
                        label="รุ่นที่อบรม"
                        v-model="editedItem.generation"
                        ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="3"
                        md="3"
                        style="padding-bottom: 0px;"
                    >
                        <v-text-field
                        prepend-icon="mdi-calendar-account-outline"
                        label="ปีที่อบรม"
                        v-model="editedItem.year_generation"
                        ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="3"
                        md="3"
                        style="padding-bottom: 0px;"
                    >
                    <v-menu
                        ref="menu"
                        v-model="start_date_menu"
                        :close-on-content-click="false"
                        :return-value.sync="start_date"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            :value="editedItem.start_date | shortdate"
                            label="วันที่เริ่มอบรม"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                        </template>
                        <v-date-picker
                        v-model="editedItem.start_date"
                        no-title
                        scrollable
                        @change="start_date_menu = false;checkEndDate()"
                        locale="th"
                        >
                        </v-date-picker>
                    </v-menu>
                    </v-col>
                    <v-col
                    cols="12"
                    sm="3"
                    md="3"
                    style="padding-bottom: 0px;"
                    >
                    <v-menu
                        ref="menu"
                        v-model="end_date_menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            :value="editedItem.end_date | shortdate"
                            label="วันที่สิ้นสุด"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                        </template>
                        <v-date-picker
                        v-model="editedItem.end_date"
                        no-title
                        scrollable
                        :min="editedItem.start_date"
                        @change="end_date_menu = false"
                        locale="th"
                        >
                        </v-date-picker>
                    </v-menu>
                    </v-col>
                    <v-col
                        cols="12"
                        style="padding-bottom: 0px;"
                    >
                        <v-combobox
                            prepend-icon="mdi-certificate-outline"
                            v-model="editedItem.course_selected"
                            :items="course_list"
                            label="หลักสูตร"
                        ></v-combobox>
                    </v-col>
                    <v-col 
                        cols="12" 
                        sm="6"
                        md="3"
                        style="padding-bottom: 0px;">
                        <v-combobox
                            prepend-icon="mdi-home-city-outline"
                            v-model="editedItem.province"
                            :items="province_list"
                            label="จังหวัดต้นสังกัด"
                            @change="editedItem.medical_operation_name='';changeProvince($event)"
                        ></v-combobox>
                    </v-col>
                    <v-col cols="12" sm="6"
                        md="9" style="padding-bottom: 0px;">
                        <v-combobox
                            prepend-icon="mdi-hospital-building"
                            v-model="editedItem.medical_operation_name"
                            :items="editedItem.province in medical_with_province_list ? medical_with_province_list[editedItem.province] : []"
                            label="หน่วยงานต้นสังกัด"
                        ></v-combobox>
                    </v-col>
                    <v-col
                        cols="12"
                        style="padding-bottom: 0px;"
                    >
                        <v-combobox
                        prepend-icon="mdi-form-select"
                            v-model="editedItem.type_certificate"
                            :items="type_cert"
                            label="ประเภทประกาศนียบัตร"
                        ></v-combobox>
                    </v-col>
                    </v-row>
                </v-container>
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="editDialog = false"

                >
                    ปิดหน้าต่าง
                </v-btn>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="EditInfoStaff"
                >
                    บันทึกข้อมูล
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="deleteDialog" width="40%" :fullscreen="$vuetify.breakpoint.xsOnly" :scrollable="$vuetify.breakpoint.xsOnly" class="dialog-delete-title">
            <v-card style="font-family: 'Noto Sans Thai', sans-serif;" height="20%" class="card-dialog-delete">
                <v-card-title style="background-color: rgb(154,179,209);font-size: 20px;font-weight: 600;" >
                    <span style="color: rgb(60,86,147);">ยืนยันการลบข้อมูล</span>
                    <v-spacer></v-spacer>
                    <v-btn fab elevation="0" x-small color="rgb(154,179,209)" depressed @click="deleteDialog = false">
                        <v-icon color="rgb(60,86,147)">
                            mdi-close
                        </v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text style="padding-top: 10%;font-size: 18px;font-weight: 700;text-align: center;padding-bottom: 10%;">
                    <p>ยืนยันลบข้อมูลการลงทะเบียนของเจ้าหน้าที่: </p><br>
                    <p>{{ delete_item.name }}</p>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                <!-- <v-spacer></v-spacer> -->
                <v-col cols="12" sm="12" md="12" lg="12" align-self="end">
                    <v-row justify="end">
                        <v-btn
                            outlined
                            elevation="0"
                            color="rgb(60,86,147)"
                            width="auto"
                            @click="deleteDialog = false"
                            style="font-weight: 500;"
                            class="btn-close-dialog-delete mr-lg-2 mr-md-1 mr-sm-1 mb-sm-1"
                        >
                            ปิดหน้าต่าง
                        </v-btn>
                        <v-btn
                            tonal
                            elevation="0"
                            class="white--text"
                            color="red darken-1"
                            width="auto"
                            @click="confirmDelete"
                            style="font-weight: 500;"
                            >
                            ยืนยันลบข้อมูล
                        </v-btn>
                    </v-row>
                </v-col>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { EventBus } from '../EventBus'
export default {
    data: () => ({
        loading: false,
        windowSize: { x: 0, y: 0 },
        search: '',
        dialog_gen_cert: false,
        selected: [],
        singleSelect: false,
        countSelected: 0,
        showButtons_genCert: false,
        new_certificate: '', // กำหนดค่าเริ่มต้นเป็น ''
        old_certificate: '', // กำหนดค่าเริ่มต้นเป็น ''
        /** @type {(import('vuetify/types').DataTableHeader)[]} */
        headers: [
            { text: "ลำดับ", value: "no", align: "center", sortable: false, },
            // { text: "ระดับ", value: "level", align: "center", sortable: false, },
            // { text: "เลขประกาศนียบัตร", value: "certificated_number", align: "center", sortable: false, },
            { text: "ชื่อ - นามสกุล", value: "name", align: "left", sortable: false, },
            { text: "หน่วยงานต้นสังกัด", value: "medical_operation_name", align: "left", sortable: false, },
            { text: "จังหวัดต้นสังกัด", value: "province", align: "left", sortable: false, },
            { text: "ประเภท", value: "type_certificate", align: "left", sortable: false, },
            { text: "วันที่ลงทะเบียนข้อมูล", value: "createdAt", align: "left", sortable: false, },
            { text: "", value: "action", align: "center", sortable: false }
        ],
        staffCount: 0,
        staffs: [],
        pageOption: {
            page: 1,
            limit: 20,
        },
        dialog_text: '',
        dialog_text2: '',
        dialog_text3: '',
        selectedStaffs: [],
        editDialog: false,
        editedItem: {},
        course_list: [],
        province_list: [],
        medical_with_province_list: [],
        start_date_menu: false,
        end_date_menu: false,
        end_date: new Date().toISOString().substr(0, 10),
        start_date: new Date().toISOString().substr(0, 10),
        isSelecting: false,
        selectedFile: null,
        is_change_keyword: false,
        type_cert: ['กลุ่มอายุประกาศนียบัตร หมดก่อน 1 มี.ค. 63','กลุ่มอายุประกาศนียบัตร ตั้งแต่ 1 มี.ค. 63 เป็นต้นไป','ผู้ที่ขอเทียบเท่า'],
        new_uploaded_image: {},
        deleteDialog: false,
        delete_item: ""
    }),
    watch: {
        // ตรวจสอบการเปลี่ยนแปลงของตัวแปร selected และอัปเดตค่า countSelected
        // selected(newVal) {
        //     this.countSelected = newVal.length; // สมมุติให้ selected เป็น string และต้องการนับจำนวนตัวอักษร
        // },
        pageOption: {
            deep: true,
            handler(newVal) {
                this.getStaffInfo({
                    keyword: this.search,
                    ...newVal,
                })
            }
        }
    },
    mounted() {
        this.onResize()
        this.getStaffInfo({
            keyword: this.search,
            ...this.pageOption,
        })
        // this.updateStaffList({
        //     keyword: this.search,
        //     ...this.pageOption,
        // })
        this.init()
    },
    computed: {
        hasSelectedItems() {
            return this.selected.length > 0; // True if there are selected items, false otherwise
        },
    },
    methods: {
        async init() {
            try {
                // const datenow = new Date()
                // this.year_generation_list = Array.from({length: datenow.getFullYear() - 2016}, (_, i) => (datenow.getFullYear() + 543 - i).toString());
                this.loading = true
                const configCourse = { baseURL: process.env.VUE_APP_API_URL, url: '/manage/api/v1/getcourse', params: { course_num: 2 } }
                const configProvice = { baseURL: process.env.VUE_APP_API_URL, url: '/manage/api/v1/getprovince' }
                const [courseData, provinceData] = await Promise.all([this.axios(configCourse), this.axios(configProvice)])
                if(courseData.data.status && provinceData.data.status) {
                    this.course_list = courseData.data.data.map(item => item.course_name)
                    this.province_list =  provinceData.data.data
                    this.medical_with_province_list = this.province_list.reduce((obj, key) => Object.assign(obj, {[key]: []}), {})
                }
            } catch (error) {
                EventBus.$emit('alert-error', 'เกิดปัญหาระหว่างดึงข้อมูล กรุณาลองใหม่ภายหลัง')
                console.error(error);
            } finally {
                this.loading = false
            }
        },
        async changeProvince(province_selected) {
            if(Object.prototype.hasOwnProperty.call(this.medical_with_province_list, province_selected)&& this.medical_with_province_list[province_selected].length <= 0) {
            try {
                const config = { baseURL: process.env.VUE_APP_API_URL, url: '/manage/api/v1/getmo', params: { province:province_selected } }
                const { data } = await this.axios(config)
                if(data.status) this.medical_with_province_list[province_selected] = data.data.map(item => item.medical_operation_name)
            } catch (error) {
                console.error(error);
                this.medical_with_province_list[province_selected] = []
            }
            }
        },
        checkEndDate () {
            const start_date = new Date(this.start_date),
            end_date = new Date(this.end_date)
            if(end_date < start_date) this.end_date = this.start_date
        },
        async onPressEnter(e){
            e.target.blur()
            // return await this.getStaffInfo({
            //     ...this.pageOption,
            //     keyword: this.search,
            //     page: 1
            // })
            return this.pageOption = {
                ...this.pageOption,
                keyword: this.search,
                page: 1
            }
        },
        async updateStaffList({keyword, page, limit} = {}) {
            try {
                this.loading = true
                const axios = this.axios;
                const [ countResp, dataResp ] = await Promise.all([
                    axios.get('/certificated/api/v1/count_certificated', {
                        params: {
                            keyword: keyword || undefined,
                        }
                    }),
                    axios.get('/certificated/api/v1/search_certificated', {
                        params: {
                            keyword: keyword || undefined,
                            offset: (page - 1) * limit,
                            limit
                        }
                    }),
                ])
                this.staffCount = countResp.data.data
                this.staffs = dataResp.data.data.map((x, i) => ({
                    "no": (page - 1) * limit + i + 1,
                    ...x
                }))
                return dataResp.data;
            } catch (error) {
                // do some error handler
                console.log(error);
            } finally {
                this.loading = false
            }
        },
        async getStaffInfo({keyword, page, limit} = {}) {
            try {
                this.loading = true
                const axios = this.axios;
                const [ dataResp,countResp ] = await Promise.all([
                    axios.get('/register/api/v1/get_register_system', {
                        params: {
                            system: "2",
                            keyword: keyword || undefined,
                            offset: (page - 1) * limit,
                            limit
                        }
                    }),
                    axios.get('/register/api/v1/count_register_system', {
                        params: {
                            system: "2",
                            keyword: keyword || undefined
                        }
                    }),
                ])
                this.staffCount = countResp.data.data
                this.staffs = dataResp.data.data.map((x, i) => ({
                    "no": (page - 1) * limit + i + 1,
                    ...x,
                    "createdAt": this.$options.filters.shortmonthyearandtime(x.createdAt)
                }))
                return dataResp.data;
            } catch (error) {
                // do some error handler
                console.log(error);
            } finally {
                this.loading = false
            }
        },
        onResize() {
            this.windowSize = { x: window.innerWidth, y: window.innerHeight }
        },
        newItem() {
            this.showButtons_genCert = false;
            this.selectedStaffs = []
            this.dialog_text = 'ออกประกาศนียบัตร'
            this.dialog_text2 = 'คุณยืนยันที่จะออกประกาศนียบัตรเจ้าหน้าที่จำนวน: ' + this.selected.length + ' คน หรือไม่'
            this.dialog_text3 = this.selected.map(el => {return el.full_name})
            // this.dialog_text3 = this.selected[0].full_name
            this.dialog_gen_cert = true
            this.selectedStaffs = this.selected
        },
        confirmDialog(item) {
            this.showButtons_genCert = false;
            this.selectedStaffs = []
            this.dialog_text = 'ออกประกาศนียบัตร'
            this.dialog_text2 = 'คุณยืนยันที่จะออกประกาศนียบัตรเจ้าหน้าที่: ' + item.full_name + ' หรือไม่'
            this.dialog_gen_cert = true
            this.selectedStaffs.push(item)
        },
        openEditDialog(item){
            this.editedItem = Object.assign({}, item)
            this.changeProvince(this.editedItem.province)
            this.editDialog = true
        },
        confirmDialog2(item) {
            this.showButtons_genCert = true; // เปิดการแสดงปุ่มเมื่อคลิกปุ่ม
            this.selectedStaffs = []
            this.dialog_text = 'ดาวน์โหลดประกาศนียบัตร'
            this.dialog_text2 = 'คุณต้องการดาวน์โหลดประกาศนียบัตรประเภทใด: '
            this.dialog_gen_cert = true
            this.selectedStaffs.push(item)
        },
        closeDialog() {
            this.dialog_gen_cert = false;
            this.showButtons_genCert = false; // ปิดการแสดงปุ่มเมื่อปิดไดอล็อก
        },
        async genCert(){
            try {
                this.loading = true
                var gen_cert_staff = this.selectedStaffs.map(el => {return el._id})
                const { data } = await this.axios.post('/certificated/api/v1/send_get_certificated', {
                    "certificated_id" : gen_cert_staff
                })
                if(data.status){
                    if(data.data.some(item => item.status == 'fail')){
                        EventBus.$emit('alert-error', 'ออกใบประกาศนียบัตรไม่สำเร็จ เนื่องจากข้อมูลในการออกประกาศนียบัตรไม่ครบถ้วน กรุณาลองใหม่อีกครั้งหรือกรุณาติดต่อเจ้าหน้าที่')
                    }else{
                        EventBus.$emit('alert-success', 'ออกใบประกาศนียบัตรสำเร็จ')
                    }
                }
            } catch (error) {
                console.log(error);
                EventBus.$emit('alert-error', 'ออกใบประกาศนียบัตรไม่สำเร็จ กรุณาลองใหม่อีกครั้ง')
            } finally {
                this.dialog_gen_cert = false
                await this.updateStaffList({
                    keyword: this.search,
                    ...this.pageOption,
                })
                this.loading = false
            }
        },
        async download_genCert(type) {
            try {
                this.loading = true;
                var gen_cert_staff = this.selectedStaffs.map(el => { return el._id });
                const { data } = await this.axios.get('/certificated/api/v1/get_certificate_data', {
                    params: {
                        "certificated_id": gen_cert_staff
                    }
                });
                if (data.status) {
                    const certificateData = data.data;
                    let url = [];
                    if (type === 'new_certificate') {
                        url = [certificateData.new_certificate];
                    } else if (type === 'old_certificate') {
                        url = [certificateData.old_certificate];
                    } else if (type === 'All') {
                        url = [certificateData.new_certificate , certificateData.old_certificate];
                    }
                    this.downloadFile(url);
                    EventBus.$emit('alert-success', 'ออกใบประกาศนียบัตรสำเร็จ');
                    console.log(data);
                }
            } catch (error) {
                console.log(error);
                EventBus.$emit('alert-error', 'ออกใบประกาศนียบัตรไม่สำเร็จ กรุณาลองใหม่อีกครั้ง');
            } finally {
                this.dialog_gen_cert = false;
                this.loading = false;
            }
        },
        downloadFile(url) {
            url.forEach((url2,index) => {
                let link = document.createElement('a');
                link.href = url2;
                link.download = url2.split('/').pop();
                setTimeout(() => {
                link.click();  
                }, index * 1000); // หน่วงเวลา 1 วินาที ระหว่างการดาวน์โหลดแต่ละไฟล์
            });
        },
        async EditInfoStaff(){
            try {
                this.loading = true
                const { uploaded_image, createdAt, updatedAt, ...new_edited_item }  = this.editedItem
                const rest = uploaded_image + createdAt + updatedAt
                // console.log(uploaded_image);
                console.log(rest);
                let formData = new FormData()
                formData.append('file', this.new_uploaded_image)
                formData.append('edit_data', JSON.stringify(new_edited_item))
                const { data } = await this.axios.put('/register/api/v1/edit_info_s2', formData,{   
                    headers: {
                        "Content-Type": "multipart/form-data"
                    },
                });
                if(data.status){
                    this.editDialog = false
                    EventBus.$emit('alert-success', 'แก้ไขข้อมูลสำเร็จ');
                }
            } catch (error) {
                console.log(error);
                EventBus.$emit('alert-error', 'แก้ไขข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง');
            } finally {
                this.getStaffInfo({
                    keyword: this.search,
                    ...this.pageOption,
                })
                this.loading = false
            }  
        },
        handleFileImport() {
            this.isSelecting = true;
            // After obtaining the focus when closing the FilePicker, return the button state to normal
            window.addEventListener('change', () => {
                this.isSelecting = false
            }, { once: true });
            // Trigger click on the FileInput
            this.$refs.uploader.click();
        },
        onFileChanged(e) {
            // this.editedItem.uploaded_image = e.target.files[0];
            this.editedItem.uploaded_image = URL.createObjectURL(e.target.files[0])
            this.new_uploaded_image = e.target.files[0]
            // TO DO
            // Do whatever you need with the file, liek reading it with FileReader
        },
        openDeleteDialog(item){
            this.deleteDialog = true
            this.delete_item = item
        },
        async confirmDelete(){
            try {
                this.loading = true
                var url = `/register/api/v1/remove_register_s2/${this.delete_item._id}`
                const { data } = await this.axios.put(url)
                if(data.status){
                    this.deleteDialog = false
                    EventBus.$emit('alert-success', 'ลบข้อมูลสำเร็จ');
                }
            } catch (error) {
                console.log(error);
                EventBus.$emit('alert-error', 'ลบข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง');
            } finally {
                this.pageOption = {
                    ...this.pageOption,
                    keyword: this.search,
                    page: 1
                }
                this.loading = false
            }
        },
    }
}
</script>

<style>
.dialog {
    font-family: 'Noto Sans Thai', sans-serif;
}

.data-table-setting {
    background: rgba(255, 255, 255, 0.44) !important;
    border-radius: 10px;
    font-family: 'Noto Sans Thai', sans-serif;
}

.data-table-setting thead th {
    background-color: #f0f2f5 !important;
}

.data-table-setting thead th:first-child {
    border-radius: 10px 0 0 0;
    font-weight: 700;
}

.data-table-setting thead th:last-child {
    border-radius: 0 10px 0 0;
}

.data-table-setting .v-data-table__wrapper table thead {
    background-color: white;
}

.data-table-setting .v-data-table__wrapper table thead tr th span {
    font-weight: 700 !important;
    font-size: 15px;
}

.data-table-setting .v-data-table__wrapper table tbody tr td {
    font-weight: 700 !important;
    font-size: 13px;
    border-bottom: none !important;
    padding-top: 5px;
}

.data-table-setting.v-data-table>.v-data-table__wrapper {
    height: calc(100vh - 283px);
    overflow: auto;
}

.background-image {
  background-image: url('https://intranet.inet.co.th/assets/upload/staff/noname.jpg');
  background-size: 180px;
  background-position: top;
  /* You can also set other background properties like background-size, background-repeat, etc. */
}

/*=================================================================*/
@media only screen and (max-width:600px) {

/* css for mobile */
.dialog-delete-title {
    padding-top: 10%;
}

.btn-close-dialog-delete{
    margin-right: 3px;
}
}
</style>