<template>
	<v-container fluid class="pa-0 select-regis-page" v-resize="onResize">
		<v-row justify="center" class="select-regis-row" :class="windowSize.x < 960? 'select-regis-main-row-sm': 'select-regis-main-row'">
			<v-col cols="11" md="5" lg="5" align-self="center" class="select-regis-test-block">
				<v-btn block depressed x-large dark color="rgb(154,179,209)" to="/registration/get_cert_form"
					class="select-regis-test-btn">
					<v-icon color="#fff9c4" class="select-regis-test-icon">mdi-order-bool-descending-variant</v-icon>
					<b class="ml-2">ระบบลงทะเบียนผู้ผ่านการฝึกอบรม และผ่านการประเมินและสอบ</b>
				</v-btn>
			</v-col>
			<v-col cols="11" md="5" lg="5" align-self="center" class="slect-regis-success-block">
				<v-btn block depressed x-large dark color="#B39DDB" to="/registration/guarantee_form"
					class="select-regis-test-btn">
					<v-icon color="#fff9c4" class="select-regis-test-icon">mdi-certificate</v-icon>
					<b class="ml-2">ระบบลงทะเบียนผู้สำเร็จการอบรมหลักสูตรเปลี่ยนผ่าน/เทียบเท่า</b>
				</v-btn>
			</v-col>	
		</v-row>
		<v-row justify="center" class="select-regis-row select-regis-main-row-2" >
			<v-col cols="11" md="5" lg="5" align-self="center" class="slect-regis-success-block" v-if="is_admin">
				<v-btn block depressed x-large dark color="#dbb39d" class="select-regis-test-btn" to="/generate_certificated" >
				<v-icon color="#fff9c4" class="select-regis-test-icon">mdi-card-account-details</v-icon>
					<b class="ml-2">ระบบออกประกาศนียบัตรผู้ปฏิบัติการตามรอบ</b>
				</v-btn>
			</v-col>
		<!-- </v-row>
		<v-row justify="center" class="select-regis-row select-regis-main-row-2"> -->
			<v-col cols="11" md="5" lg="5" align-self="center" class="slect-regis-success-block">
				<v-btn block depressed x-large dark color="#91CBA2" class="select-regis-test-btn" @click="openSelectSystem()">
					<v-icon color="#fff9c4" class="select-regis-test-icon">mdi-tab-plus</v-icon>
						<b class="ml-2">ข้อมูลบันทึกการลงทะเบียนเจ้าหน้าที่ขององค์กรย้อนหลัง</b>
				</v-btn>
			</v-col>
		</v-row>
		<v-row justify="center" class="select-regis-row select-regis-main-row-2" v-if="is_admin">
			<v-col cols="11" md="5" lg="5" align-self="center" class="slect-regis-success-block">
				<v-btn block depressed x-large dark color="#de9bbd" class="select-regis-test-btn" to="/add_certificate_form" >
				<v-icon color="#fff9c4" class="select-regis-test-icon">mdi-book-plus-outline</v-icon>
					<b class="ml-2">บันทึกข้อมูลประกาศนียบัตรของผู้ปฏิบัติการ</b>
				</v-btn>
			</v-col>
		</v-row>
		<!-- <v-row justify="center" justify-md="end" class="select-regis-row regis-system-log-row">
			<v-col cols="11" md="5" lg="4" align-self="center" class="slect-regis-success-block">
				<v-btn block depressed x-large dark color="rgb(60, 86, 147)" rounded class="regis-log-text-btn" @click="openSelectSystem()">
					<b class="ml-2">ข้อมูลบันทึกการลงทะเบียนเจ้าหน้าที่ขององค์กร</b>
				</v-btn>
			</v-col>
		</v-row> -->
		<SelectSystemModal />
	</v-container>
</template>

<script>
import { EventBus } from '../EventBus';
import SelectSystemModal from '../components/SelectSystemModal.vue'
export default {
	components: {
		SelectSystemModal
	},
	data: () => ({
		is_admin: false,
        windowSize: { x: 0, y: 0 },
	}),
	mounted(){
		this.onResize()
		const userProfile = JSON.parse(sessionStorage.userProfile)
		this.is_admin = userProfile.is_admin
    },
	methods: {
		openSelectSystem() {
			EventBus.$emit('openSelectSystemModal')
		},
		onResize() {
			this.windowSize = { x: window.innerWidth }
		},
	}
}
</script>

<style>
.select-regis-page {
	height: calc(100vh - 48px);
}

.select-regis-row {
	width: 100%;
	margin: 0%;
}

.select-regis-main-row {
	height: auto;
	padding-top: 270px;
}

.select-regis-main-row-sm {
	height: auto;
	padding-top: 50px;
}

.select-regis-test-btn {
	font-family: 'Noto Sans Thai', sans-serif;
	max-width: inherit;
	white-space: pre-wrap;
	height: auto !important;
	font-size: 21px !important;
	border-radius: 18px;
}

.select-regis-test-btn .v-btn__content,
.regis-log-text-btn .v-btn__content {
	width: 100%;
}

.select-regis-test-icon {
	font-size: 100px !important;
}

.regis-system-log-row {
	position: absolute;
	bottom: 0;
}

.regis-log-text-btn {
	max-width: inherit;
	white-space: pre-wrap;
	font-size: 18px;
	font-family: 'Noto Sans Thai', sans-serif;
}

/*========================================*/

@media only screen and (max-width: 960px), screen and (max-height: 720px) {
	.regis-system-log-row {
		position: unset !important;
	}
}

/*========================================*/

@media only screen and (max-width:600px) {

	/* css for mobile */
	.select-regis-test-block {
		margin-top: auto;
	}

	.slect-regis-success-block {
		margin-bottom: auto;
	}

	.select-regis-test-btn {
		font-size: 18px !important;
		padding-bottom: 12px !important;
		padding-top: 12px !important;
	}

	.select-regis-test-icon {
		font-size: 81px !important;
	}
}</style>