<template>
    <v-dialog
        v-model="dialog"
        max-width="700"
        :persistent="$vuetify.breakpoint.smAndUp"
        content-class="elevation-0"
    >
        <v-card fluid class="container">
            <v-card-title class="py-3 px-0">
                <v-row no-gutters justify="center">
                    <b class="text-center text-header">เลือกระบบที่ต้องการดูข้อมูล</b>
                    <v-btn
                        v-if="$vuetify.breakpoint.smAndUp"
                        depressed
                        icon
                        class="close-button"
                        @click="dialog = false"
                    >
                        <v-icon size="32">
                            mdi-close
                        </v-icon>
                    </v-btn>
                </v-row>
            </v-card-title>
            <v-card-actions class="px-0">
                <v-row no-gutters justify="center" class="button-container">
                    <v-btn
                        block
                        depressed
                        x-large
                        dark
                        color="rgb(154,179,209)"
                        class="select-button"
                        @click="saveSystem('1')"
                    >
                        <v-icon color="#fff9c4" size="32">
                            mdi-order-bool-descending-variant
                        </v-icon>
                        <b class="ml-2">
                            ระบบลงทะเบียนผู้ผ่านการฝึกอบรม และผ่านการประเมินและสอบ
                        </b>
                    </v-btn>
                    <v-btn
                        block
                        depressed
                        x-large
                        dark
                        color="#B39DDB"
                        class="select-button"
                        @click="saveSystem('2')"
                    >
                        <v-icon color="#fff9c4" size="32">
                            mdi-certificate
                        </v-icon>
                        <b class="ml-2">
                            ระบบลงทะเบียนผู้สำเร็จการอบรมหลักสูตรเปลี่ยนผ่าน/เทียบเท่า
                        </b>
                    </v-btn>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { EventBus } from '../EventBus';
    export default {
        data: () => ({
            dialog: false
        }),
        mounted() {
            EventBus.$on('openSelectSystemModal', () => this.dialog = true);
        },
        beforeDestroy() {
            EventBus.$off('openSelectSystemModal');
        },
        methods: {
            saveSystem(step = 0) {
                sessionStorage.system = step;
                this.dialog = false;
                this.$router.push({ name: 'save_log_register_list'});
            }
        }
    }
</script>

<style scoped>
    .row {
        width: 100%;
        margin: 0px;
    }
    
    .container {
        padding: 16px;
        border-radius: 18px;
        font-family: 'Noto Sans Thai', sans-serif;
    }

    .text-header {
        font-size: 28px;
    }

    .close-button {
        position: absolute;
        right: 12px;
        top: 12px;
    }

    .button-container {
        gap: 16px;
    }

    .select-button {
        font-size: 16px;
        padding: 8px 16px !important;
        border-radius: 18px;
        height: auto !important;
        max-width: 100%;
        white-space: normal;
    }

    .select-button >>> .v-btn__content {
        flex: 1 0 fit-content;
    }

    @media only screen and (max-width: 37.5rem) {
        .text-header {
            font-size: 22px;
        }
    }

    @media only screen and (max-width: 25rem) {
        .text-header {
            font-size: 18px;
        }

        .select-button {
            font-size: 12px;
        }
    }
    
</style>