<template>
  <div>
    <v-overlay v-model="loading">
      <!-- page loading -->
      <v-progress-circular
        indeterminate
        color="#FFF9C4"
        :size="80"
        :width="7"
      ></v-progress-circular>
    </v-overlay>
    <v-form ref="form_1" lazy-validation>
      <v-row class="pass-exam-row pass-exam-header-row">
        <v-col cols="auto" md="auto" lg="auto" class="py-2">
          <v-btn
            depressed
            small
            fab
            color="#de9bbd"
            @click="$router.push({ name: 'select_regis' })"
          >
            <!-- back button -->
            <v-icon color="rgb(60,86,147)">mdi-chevron-double-left</v-icon>
          </v-btn>
        </v-col>
        <v-col
          cols="9"
          md="auto"
          lg="auto"
          class="mt-1 px-4 py-2 add-cert-header-blck"
        >
          <b class="pass-exam-header"
            >บันทึกข้อมูลประกาศนียบัตรของผู้ปฏิบัติการ</b
          >
        </v-col>
      </v-row>
      <v-row class="mt-6 pass-exam-row">
        <u class="pass-exam-name-header"
          >รายชื่อผู้ปฏิบัติการที่ได้รับประกาศนียบัตร</u
        >
      </v-row>
      <v-row class="mt-3 pass-exam-row pass-exam-namelist-block">
        <v-col
          v-for="(item, index) in list_trained"
          cols="12"
          md="3"
          lg="3"
          class="pl-0 pt-0 pass-exam-person-block"
          :key="`list_trained_1_${index}`"
          :id="`list_trained_1_${index}`"
        >
          <!-- each person -->
          <v-card color="#eaf4ff">
            <v-card-title class="px-3 pt-1 pb-0 pass-exam-people-header">
              <v-row align="center" class="pass-exam-row">
                <b class="pass-exam-num-people">ลำดับที่ {{ index + 1 }}</b>
                <v-spacer></v-spacer>
                <v-btn icon x-small color="white" @click="removeTrained(index)">
                  <!-- delete person button -->
                  <v-icon>mdi-window-close</v-icon>
                </v-btn>
              </v-row>
            </v-card-title>
            <v-card-text class="px-3 pb-4 pt-3">
              <v-row
                v-if="item.error_text"
                class="pass-exam-row"
                style="color: red; font-family: 'Noto Sans Thai', sans-serif"
              >
                {{ item.error_text }}
              </v-row>
              <v-row
                v-if="item.success_text"
                class="pass-exam-row"
                style="color: green; font-family: 'Noto Sans Thai', sans-serif"
              >
                {{ item.success_text }}
              </v-row>
              <v-row class="mt-0 pass-exam-row">
                <v-col
                  cols="auto"
                  md="auto"
                  lg="auto"
                  align-self="center"
                  class="pl-0 pt-0 pb-2 pr-2 pass-exam-person-title"
                >
                  เลขที่บัตรประชาชน :
                </v-col>
                <v-col cols="12" md="12" lg="auto" class="px-0 pt-0 pb-2">
                  <v-text-field
                    rounded
                    dense
                    outlined
                    hide-details
                    background-color="white"
                    class="pass-exam-person-input"
                    v-model="item.id_card_num"
                    :onkeypress="`return ((event.charCode >= 48 && event.charCode <= 57) && &quot;${item.id_card_num}&quot;.length<13)`"
                    :rules="[
                      ...rules.required,
                      ...rules.number,
                      ...rules.textLength13,
                    ]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="mt-0 pass-exam-row">
                <v-col
                  cols="auto"
                  md="auto"
                  lg="auto"
                  align-self="center"
                  class="pl-0 pt-0 pb-2 pr-2 pass-exam-person-title"
                >
                  คำนำหน้า :
                </v-col>
                <v-col cols="12" md="" lg="" class="px-0 pt-0 pb-2">
                  <v-combobox
                    dense
                    outlined
                    hide-details
                    auto-select-first
                    rounded
                    background-color="white"
                    append-icon="mdi-chevron-down"
                    class="pass-exam-person-input pass-exam-person-dropdown"
                    :rules="rules.required"
                    v-model="item.prefix"
                    :items="list_title_name"
                  ></v-combobox>
                </v-col>
                <v-col class="pl-1 pt-0" cols="12">
                  <v-text-field
                    v-if="item.prefix === 'อื่นๆ'"
                    placeholder="โปรดระบุ"
                    rounded
                    color="#2a9d8f"
                    dense
                    outlined
                    hide-details
                    class="pass-exam-person-input"
                    v-model="item.addition_prefix"
                    :rules="[...rules.required, ...rules.noDigits]"
                  >
                  </v-text-field>
                </v-col>
                <!-- <v-col cols="12" md="6" lg="6" class="px-0 pt-0 pb-2">
                  <v-text-field
                    dense
                    outlined
                    hide-details
                    background-color="white"
                    class="pass-exam-person-input"
                    v-model="item.prefix"
                    :rules="rules.required"
                  ></v-text-field>
                </v-col> -->
              </v-row>
              <v-row class="mt-0 pass-exam-row">
                <v-col
                  cols="auto"
                  md="auto"
                  lg="auto"
                  align-self="center"
                  class="pl-0 pt-0 pb-2 pr-2 pass-exam-person-title"
                >
                  ชื่อ - สกุล :
                </v-col>
                <v-col cols="12" md="" lg="" class="px-0 pt-0 pb-2">
                  <v-text-field
                    rounded
                    dense
                    outlined
                    hide-details
                    background-color="white"
                    class="pass-exam-person-input"
                    v-model="item.name"
                    :rules="rules.required"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="mt-0 pass-exam-row">
                <v-col
                  cols="auto"
                  md="auto"
                  lg="auto"
                  align-self="center"
                  class="pl-0 pt-0 pb-2 pr-2 pass-exam-person-title"
                >
                  ระดับประกาศนียบัตร :
                </v-col>
                <v-col class="px-0 pt-0" cols="12">
                  <v-combobox
                    dense
                    outlined
                    hide-details
                    auto-select-first
                    rounded
                    background-color="white"
                    append-icon="mdi-chevron-down"
                    class="pass-exam-person-input pass-exam-person-dropdown"
                    :rules="rules.required"
                    v-model="item.level_certificated"
                    :items="level_certificated_list"
                  ></v-combobox>
                </v-col>
              </v-row>
              <v-row class="mt-0 pass-exam-row">
                <v-col
                  cols="auto"
                  md="auto"
                  lg="auto"
                  align-self="center"
                  class="pl-0 pt-0 pb-2 pr-2 pass-exam-person-title"
                >
                  เลขที่ประกาศนียบัตร :
                </v-col>
                <v-col class="px-0 pt-0" cols="12">
                  <v-text-field
                    rounded
                    dense
                    outlined
                    hide-details
                    background-color="white"
                    class="pass-exam-person-input"
                    v-model="item.number_certificated"
                    :rules="rules.required"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="mt-0 pass-exam-row">
                <v-col
                  cols="auto"
                  md="auto"
                  lg="auto"
                  align-self="center"
                  class="pl-0 pt-0 pb-2 pr-2 pass-exam-person-title"
                >
                  วันเริ่มประกาศนียบัตร :
                </v-col>
                <v-col cols="12" md="auto" lg="auto" class="px-0 pt-0 pb-2">
                  <v-menu
                    :close-on-content-click="false"
                    offset-y
                    v-model="start_date_menu[index]"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        rounded
                        dense
                        outlined
                        hide-details
                        background-color="white"
                        class="pass-exam-person-input pd-pass-exam-birthday"
                        v-on="on"
                        v-bind="attrs"
                        :value="item.start_date | shortdate"
                        readonly
                        :rules="rules.required"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      locale="th"
                      color="rgb(60,86,147)"
                      v-model="item.start_date"
                      class="pass-exam-calendar"
                      @input="start_date_menu[index] = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row class="mt-0 pass-exam-row">
                <v-col
                  cols="auto"
                  md="auto"
                  lg="auto"
                  align-self="center"
                  class="pl-0 pt-0 pb-2 pr-2 pass-exam-person-title"
                >
                  วันสิ้นสุดประกาศนียบัตร :
                </v-col>
                <v-col cols="12" md="auto" lg="auto" class="px-0 pt-0 pb-2">
                  <v-menu
                    :close-on-content-click="false"
                    offset-y
                    v-model="end_date_menu[index]"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        rounded
                        dense
                        outlined
                        hide-details
                        background-color="white"
                        class="pass-exam-person-input pd-pass-exam-birthday"
                        v-on="on"
                        v-bind="attrs"
                        :value="item.end_date | shortdate"
                        readonly
                        :rules="rules.required"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      locale="th"
                      color="rgb(60,86,147)"
                      v-model="item.end_date"
                      class="pass-exam-calendar"
                      @input="end_date_menu[index] = false"
                      :min="item.start_date"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="3" lg="3" class="pl-0 pt-0 pass-exam-person-block">
          <v-btn
            block
            outlined
            x-large
            color="#d1d1d1"
            class="pass-exam-add-btn"
            @click="addTrained"
          >
            <!-- add person button -->
            <v-avatar size="100" class="pass-exam-add-icon">+</v-avatar>
          </v-btn>
        </v-col>
      </v-row>
      <v-row justify="center" class="pass-exam-row">
        <v-btn
          depressed
          large
          color="rgb(60,86,147)"
          class="px-12 pass-exam-submit-btn"
          @click="validateFn"
        >
          <b>Submit</b>
        </v-btn>
      </v-row>
    </v-form>
    <br />
  </div>
</template>
  
  <script>
import { EventBus } from "../EventBus";
export default {
  data: () => ({
    loading: false,
    course_list: [],
    course_selected: null,
    generation: null,
    year_generation: null,
    start_date_menu: [],
    start_date_menu_export: false,
    start_date_menu_export_Org: false,
    start_date: new Date().toISOString().substr(0, 10),
    start_date_export: new Date().toISOString().substr(0, 10),
    start_date_export_Org: new Date().toISOString().substr(0, 10),
    end_date_menu: [],
    end_date_menu_export: false,
    end_date_menu_export_Org: false,
    end_date: new Date().toISOString().substr(0, 10),
    end_date_export: new Date().toISOString().substr(0, 10),
    end_date_export_Org: new Date().toISOString().substr(0, 10),
    rules: {
      required: [(v) => !!v || "Name is required"],
      number: [
        (v) => (!isNaN(v) && /^[0-9]*$/.test(v)) || "ต้องเป็นตัวเลขเท่านั้น",
      ],
      numberUpperZero: [(v) => parseInt(v) > 0 || "ต้องมีค่ามากกว่า 0"],
      numberInteger: [(v) => Number(v) % 1 == 0 || "ต้องเป็นจำนวนเต็มเท่านั้น"],
      textLength13: [(v) => (v && v.length == 13) || "ต้องมี 13 หลัก"],
      noDigits: [(v) => /^[^0-9]*$/.test(v)],
    },
    list_trained: [
      {
        prefix: "",
        name: "",
        id_card_num: "",
        number_certificated:"",
        level_certificated:"",
        start_date: "",
        end_date: "",
      },
    ],
    province_list: [],
    medical_with_province_list: {},
    education_level_list: [
      "มัธยมศึกษาตอนต้น",
      "มัธยมศึกษาตอนปลาย",
      "ปวช.",
      "ปวส.",
      "ปริญญาตรี",
      "ปริญญาโท",
      "ปริญญาเอก",
    ],
    level_certificated_list: [
      "เจ้าพนักงานฉุกเฉินการแพทย์",
      "พนักงานฉุกเฉินการแพทย์",
      "อาสาสมัครฉุกเฉินการแพทย์",
    ],
    year_generation_list: [],
    generation_list: Array.from({ length: 10 }, (_, i) => i + 1),
    maxStartdate: new Date(new Date()).toISOString().substr(0, 10),
    minStartdate: new Date(
      new Date().setFullYear(new Date().getFullYear() - 100)
    )
      .toISOString()
      .substr(0, 10),
    maxEnddate: new Date(new Date().setFullYear(new Date().getFullYear() - 1))
      .toISOString()
      .substr(0, 10),
    minEnddate: "",
    birthMenu: [],
    is_admin: false,
    dialog_export: false,
    dialog_text: "",
    export_data_province: "",
    export_operation_name: "",
    validDialog: false,
    excel_dialog: false,
    windowSize: { x: 0 },
    list_title_name: [],
  }),
  mounted() {
    const userProfile = JSON.parse(sessionStorage.userProfile);
    this.is_admin = userProfile.is_admin;
    // this.init();
    this.onResize();
    this.getLisTitleName();
  },
  methods: {
    onResize() {
      this.windowSize = { x: window.innerWidth };
    },
    async init() {
      try {
        const datenow = new Date();
        this.year_generation_list = Array.from(
          { length: datenow.getFullYear() - 2016 },
          (_, i) => (datenow.getFullYear() + 543 - i).toString()
        );
        this.loading = true;
        const configCourse = {
          baseURL: process.env.VUE_APP_API_URL,
          url: "/manage/api/v1/getcourse",
          params: { course_num: 1 },
        };
        const configProvice = {
          baseURL: process.env.VUE_APP_API_URL,
          url: "/manage/api/v1/getprovince",
        };
        const [courseData, provinceData] = await Promise.all([
          this.axios(configCourse),
          this.axios(configProvice),
        ]);
        if (courseData.data.status && provinceData.data.status) {
          this.course_list = courseData.data.data.map(
            (item) => item.course_name
          );
          this.province_list = provinceData.data.data;
          this.medical_with_province_list = this.province_list.reduce(
            (obj, key) => Object.assign(obj, { [key]: [] }),
            {}
          );
        }
      } catch (error) {
        EventBus.$emit(
          "alert-error",
          "เกิดปัญหาระหว่างดึงข้อมูล กรุณาลองใหม่ภายหลัง"
        );
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    async changeProvince(province_selected) {
      if (
        Object.prototype.hasOwnProperty.call(
          this.medical_with_province_list,
          province_selected
        ) &&
        this.medical_with_province_list[province_selected].length <= 0
      ) {
        try {
          const config = {
            baseURL: process.env.VUE_APP_API_URL,
            url: "/manage/api/v1/getmo",
            params: { province: province_selected },
          };
          const { data } = await this.axios(config);
          if (data.status)
            this.medical_with_province_list[province_selected] = data.data.map(
              (item) => item.medical_operation_name
            );
        } catch (error) {
          console.error(error);
          this.medical_with_province_list[province_selected] = [];
        }
      }
    },
    addTrained() {
      this.list_trained.push({
        name: "",
        id_card_num: "",
        prifix: "",
        number_certificated:"",
        level_certificated:"",
        start_date: "",
        end_date: "",
      });
    },
    removeTrained(index) {
      this.list_trained.splice(index, 1);
    },
    confirmSubmit() {
      this.$swal({
        title: "คุณต้องการบันทึกหรือไม่ ?",
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: "บันทึก",
        cancelButtonText: "ปิด",
        width: "363px",
        customClass: {
          title: "sweet-confirm-text",
          popup: "sweet-confirm-card",
          confirmButton: "sweet-confirm-btn",
          cancelButton: "sweet-confirm-cancel-btn",
        },
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.sendData();
        } else if (result.isDenied) {
          // Swal.fire('Changes are not saved', '', 'info')
        }
      });
    },
    async getLisTitleName() {
      try {
        const baseURL = {
          baseURL: process.env.VUE_APP_API_URL,
          url: "public/api/v1/get_titile_name",
        };
        const [lisTitleName] = await Promise.all([this.axios(baseURL)]);
        this.list_title_name = lisTitleName.data.data;
      } catch (error) {
        EventBus.$emit(
          "alert-error",
          "เกิดปัญหาระหว่างดึงข้อมูล กรุณาลองใหม่ภายหลัง"
        );
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    validateFn() {
      setTimeout(() => {
        if (this.list_trained.length <= 0) {
          return EventBus.$emit(
            "alert-warning",
            "จำเป็นต้องมีรายชื่ออย่างน้อย 1 คน"
          );
        }
        if (!this.$refs.form_1.validate()) {
          let valid = true;
          let contentError = null;
          let message = "กรุณากรอกข้อมูลให้ถูกต้องและครบถ้วน";
          if (valid && !this.start_date) {
            valid = false;
            contentError = "start_date";
          }
          if (valid && !this.end_date) {
            valid = false;
            contentError = "end_date";
          }
          if (valid) {
            valid = this.list_trained.every((item, index) => {
              if (
                !item.prefix ||
                !item.name ||
                !item.id_card_num ||
                !/^\d+$/.test(item.id_card_num) ||
                item.id_card_num.length != 13 ||
                !item.start_date ||
                !item.end_date ||
                !item.number_certificated ||
                !item.level_certificated
              ) {
                contentError = `list_trained_1_${index}`;
                if (
                  !item.prefix ||
                  !item.name ||
                  !item.id_card_num ||
                  !item.start_date ||
                  !item.end_date ||
                  !item.number_certificated ||
                  !item.level_certificated
                )
                  message += ` (ลำดับที่ ${index + 1})`;
                else if (!/^\d+$/.test(item.id_card_num))
                  message = `กรุณากรอกเลขบัตรประชาชนเป็นตัวเลขเท่านั้น (ลำดับที่ ${
                    index + 1
                  })`;
                else if (item.id_card_num.length != 13)
                  message = `กรุณากรอกเลขบัตรประชาชน 13 หลักเท่านั้น (ลำดับที่ ${
                    index + 1
                  })`;
                else message += ` (ลำดับที่ ${index + 1})`;
                return false;
              } else return true;
            });
          }

          if (!valid) {
            const element = document.getElementById(contentError);
            const yOffset = -70;
            const y =
              element.getBoundingClientRect().top +
              window.pageYOffset +
              yOffset;
            window.scrollTo({ top: y });
            EventBus.$emit("alert-warning", message);
          }
        } else {
          this.confirmSubmit();
        }
      }, 100);
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
      });
    },
    async sendData() {
      try {
        this.loading = true;
        var list_staff = [];

        this.list_trained.forEach((item) => {
          var start_date_formatted = this.$options.filters.shortdate(
            item.start_date
          );
          // using filter shortdate from dateFilters.js
          var end_date_formatted = this.$options.filters.shortdate(
            item.end_date
          );
          // using filter shortdate from dateFilters.js
          let obj = {};
          if (item.prefix == "อื่นๆ") {
            obj.title_name = item.addition_prefix;
          } else {
            obj.title_name = item.prefix;
          }
          obj.first_last_name = item.name;
          obj.certificated_date = start_date_formatted;
          obj.expire_date = end_date_formatted;
          obj.id_card_num = item.id_card_num;
          obj.level = item.level_certificated;
          obj.certificated_number = item.number_certificated;
          list_staff.push(obj);
          console.log(obj);
        });
        const config = {
          baseURL: process.env.VUE_APP_API_URL,
          url: "/received/api/v1/add_received",
          method: "POST",
          data: {
            list: list_staff,
          },
        };
        const { data } = await this.axios(config);
        if (data.status) {
          EventBus.$emit("alert-success", "บันทึกสำเร็จ");
          this.list_trained = [];
          this.course_selected = "";
          this.generation = "";
          this.year_generation = "";
          this.start_date = new Date().toISOString().substr(0, 10);
          this.end_date = new Date().toISOString().substr(0, 10);
          this.medical_with_province_list = {};
          this.addTrained();
          this.$nextTick(() => {
            this.$refs.form_1.resetValidation();
          });
        } else {
          EventBus.$emit(
            "alert-error",
            "เกิดข้อผิดพลาดในการบันทึก" + "\n" + data,
            0
          );
        }
      } catch (error) {
        EventBus.$emit(
          "alert-error",
          "เกิดข้อผิดพลาดในการบันทึก" + "\n" + JSON.stringify(error),
          0
        );
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    checkEndDate() {
      const start_date = new Date(this.start_date),
        end_date = new Date(this.end_date);
      if (end_date < start_date) this.end_date = this.start_date;
    },
    confirmDialog() {
      this.dialog_text = "กรุณาเลือกจังหวัดและชื่อสังกัดของหน่วยปฏิบัติการ";
      this.dialog_export = true;
    },
    validateDialog() {
      if (this.export_data_province && this.export_operation_name) {
        this.validDialog = true;
      } else {
        this.validDialog = false;
      }
    },
    async exportDataOrg() {
      try {
        this.loading = true;
        const config = {
          baseURL: process.env.VUE_APP_API_URL,
          url: "/register/api/v1/export_org_s1",
          params: {
            province: this.export_data_province,
            medical_operation_name: this.export_operation_name,
            start_date: this.start_date_export_Org,
            end_date: this.end_date_export_Org,
          },
          responseType: "blob",
        };
        const { data } = await this.axios(config);

        const months_th = [
          "มกราคม",
          "กุมภาพันธ์",
          "มีนาคม",
          "เมษายน",
          "พฤษภาคม",
          "มิถุนายน",
          "กรกฎาคม",
          "สิงหาคม",
          "กันยายน",
          "ตุลาคม",
          "พฤศจิกายน",
          "ธันวาคม",
        ];
        const date = new Date();
        const map = {
          MMMM: months_th[date.getMonth()],
          DD: date.getDate(),
          YY: date.getFullYear().toString().slice(-2),
          YYYY: date.getFullYear() + 543,
          h: date.getHours(),
          mm: date.getMinutes(),
          ss: date.getSeconds(),
        };
        const href = URL.createObjectURL(data);

        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute(
          "download",
          "export DD MMMM YYYY h_mm_ss.xlsx".replace(
            /MMMM|DD|YYYY|YY|h|mm|ss/gi,
            (matched) => map[matched]
          )
        ); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      } catch (error) {
        if (error.response) {
          const objData = JSON.parse(await error.response.data.text());
          EventBus.$emit("alert-error", objData.message);
        } else {
          EventBus.$emit(
            "alert-error",
            "ไม่สามารถ Export ข้อมูลได้ กรุณาติดต่อเจ้าหน้าที่"
          );
        }
      } finally {
        this.loading = false;
      }
    },
    async exportData() {
      try {
        this.loading = true;
        const config = {
          baseURL: process.env.VUE_APP_API_URL,
          url: "/register/api/v1/export_register_s1",
          responseType: "blob",
          params: {
            start_date: this.start_date_export,
            end_date: this.end_date_export,
          },
        };
        const { data } = await this.axios(config);

        const months_th = [
          "มกราคม",
          "กุมภาพันธ์",
          "มีนาคม",
          "เมษายน",
          "พฤษภาคม",
          "มิถุนายน",
          "กรกฎาคม",
          "สิงหาคม",
          "กันยายน",
          "ตุลาคม",
          "พฤศจิกายน",
          "ธันวาคม",
        ];
        const date = new Date();
        const map = {
          MMMM: months_th[date.getMonth()],
          DD: date.getDate(),
          YY: date.getFullYear().toString().slice(-2),
          YYYY: date.getFullYear() + 543,
          h: date.getHours(),
          mm: date.getMinutes(),
          ss: date.getSeconds(),
        };
        const href = URL.createObjectURL(data);

        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute(
          "download",
          "export DD MMMM YYYY h_mm_ss.xlsx".replace(
            /MMMM|DD|YYYY|YY|h|mm|ss/gi,
            (matched) => map[matched]
          )
        ); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      } catch (error) {
        if (error.response) {
          const objData = JSON.parse(await error.response.data.text());
          EventBus.$emit("alert-error", objData.message);
        } else {
          EventBus.$emit(
            "alert-error",
            "ไม่สามารถ Export ข้อมูลได้ กรุณาติดต่อเจ้าหน้าที่"
          );
        }
      } finally {
        this.loading = false;
      }
    },
    calcAge(birth_date, index) {
      let ageDifMs = Date.now() - new Date(birth_date).getTime();
      let ageDate = new Date(ageDifMs); // miliseconds from epoch
      this.list_trained[index].age = Math.abs(ageDate.getUTCFullYear() - 1970);
    },
    goToDownloadExportData() {
      this.excel_dialog = true;
    },
    goToEditStaff() {
      this.$router.push({ name: "pass_exam_list" });
    },
  },
};
</script>
  
  <style>
.pd-pass-exam-icon-close.v-text-field--enclosed.v-input--dense:not(
    .v-text-field--solo
  ).v-text-field--outlined
  .v-input__append-inner {
  margin-top: 5px !important;
}
.pd-pass-exam-birthday.v-text-field.v-text-field--enclosed:not(
    .v-text-field--rounded
  )
  > .v-input__control
  > .v-input__slot {
  padding-left: 4px !important;
}
.pd-icon-input-pass-exam.v-text-field.v-text-field--enclosed:not(
    .v-text-field--rounded
  )
  > .v-input__control
  > .v-input__slot {
  padding-left: 0px !important;
}
.icon-input-pass-exam.v-text-field--enclosed.v-input--dense:not(
    .v-text-field--solo
  ).v-text-field--outlined
  .v-input__prepend-inner {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}
.pass-exam-row {
  width: 100%;
  margin: 0%;
}

.input-card-search {
  border-radius: 20px !important;
  background-color: white;
}

.pass-exam-header-row {
  margin-top: 12px;
}

.add-cert-header-blck {
  background: #de9bbd;
  border-radius: 32px;
  height: fit-content;
}

.pass-exam-header {
  font-family: "Noto Sans Thai", sans-serif;
  color: rgb(60, 86, 147);
  font-size: 22px;
}

.pass-exam-export-btn {
  font-family: "Noto Sans Thai", sans-serif;
  text-transform: capitalize;
  font-size: 16px !important;
  color: #3495cf !important;
}

.pass-exam-head-title {
  font-family: "Noto Sans Thai", sans-serif;
  font-size: 16px;
  padding-left: 4% !important;
}

.pass-exam-input {
  font-family: "Noto Sans Thai", sans-serif;
  font-size: 15px;
}

.pass-exam-dropdown-box.v-text-field--rounded
  > .v-input__control
  > .v-input__slot {
  padding-left: 2% !important;
  padding-right: 1% !important;
}

.pass-exam-inputbox.v-text-field--rounded > .v-input__control > .v-input__slot {
  padding-left: 10px !important;
  padding-right: 3% !important;
}

.pass-exam-year-title {
  font-family: "Noto Sans Thai", sans-serif;
  font-size: 16px;
  padding-left: 2% !important;
}

.pass-exam-start-title {
  font-family: "Noto Sans Thai", sans-serif;
  font-size: 16px;
  padding-left: 4% !important;
}

.pass-exam-start-input
  > .v-input__control
  > .v-input__slot
  > .v-text-field__slot
  > label {
  left: 20px !important;
}

.pass-exam-calendar {
  font-family: "Noto Sans Thai", sans-serif;
}

.pass-exam-end-title {
  font-family: "Noto Sans Thai", sans-serif;
  font-size: 16px;
}

.pass-exam-name-header {
  font-family: "Noto Sans Thai", sans-serif;
  font-size: 16px;
  padding-left: 4%;
  font-weight: bold;
  color: rgb(60, 86, 147);
}

.pass-exam-namelist-block {
  padding-left: 4%;
}

.pass-exam-person-block {
  padding-right: 2% !important;
  padding-bottom: 2% !important;
}

.pass-exam-people-header {
  background: rgb(60, 86, 147);
}

.pass-exam-num-people {
  font-family: "Noto Sans Thai", sans-serif;
  font-size: 16px;
  color: white;
}

.pass-exam-person-title {
  font-family: "Noto Sans Thai", sans-serif;
  font-size: 15px;
  color: black;
}

.pass-exam-person-input {
  font-family: "Noto Sans Thai", sans-serif;
  font-size: 14px;
}

.pass-exam-person-input > .v-input__control > .v-input__slot {
  min-height: 31px !important;
}

.pass-exam-person-dropdown.v-text-field--enclosed.v-input--dense:not(
    .v-text-field--solo
  ).v-text-field--outlined
  .v-input__append-inner {
  margin-top: 4px !important;
}

.pass-exam-person-dropdown.v-text-field.v-text-field--enclosed:not(
    .v-text-field--rounded
  )
  > .v-input__control
  > .v-input__slot {
  padding-left: 3% !important;
  padding-right: 1% !important;
}

.pass-exam-person-province .v-input__icon {
  min-width: 15px !important;
  width: 15px !important;
}

.pass-exam-add-btn {
  border: 4px dashed;
  height: 468px !important;
}

.pass-exam-add-btn:hover {
  background-color: #efefef !important;
}

.pass-exam-add-icon {
  font-size: 76px;
  font-weight: 200;
  border: 2px solid #d1d1d1 !important;
}

.pass-exam-submit-btn {
  font-family: "Noto Sans Thai", sans-serif;
  text-transform: capitalize;
  color: #ffe57f !important;
  font-size: 18px !important;
}

.format-pre pre {
  padding: 10px 0 0 2%;
  font-family: "Noto Sans Thai", sans-serif;
  font-size: 17px;
  justify-items: start;
  display: flex;
  text-align: start;
}

.format-pre p {
  padding: 10px 0 0 2%;
  font-family: "Noto Sans Thai", sans-serif;
  font-size: 15px;
  justify-items: start;
  display: flex;
  text-align: start;
  color: red;
}

.format-title {
  font-family: "Noto Sans Thai", sans-serif;
  color: black !important;
  font-size: 21px;
  padding-bottom: 0;
  padding-top: 30px;
}

.text-divider {
  display: flex;
  align-items: center;
  --text-divider-gap: 1rem;
}

.text-divider::before,
.text-divider::after {
  content: "";
  height: 1px;
  background-color: silver;
  flex-grow: 1;
}
.pass-exam-person-input>.v-input__control>.v-input__slot {
  padding-left: 6% !important;
  padding-right: 1% !important;
}
.text-divider::before {
  margin-right: var(--text-divider-gap);
}

.text-divider::after {
  margin-left: var(--text-divider-gap);
}
/*========================================*/

@media only screen and (max-width: 600px) {
  /* css for mobile */
  .pass-exam-header-row {
    margin-top: 6px;
  }

  .add-cert-header-blck {
    border-radius: 25px;
  }

  .pass-exam-header {
    font-size: 17px;
  }

  .pass-exam-year-title {
    padding-left: 4% !important;
  }

  .pass-exam-person-block {
    padding-right: 5% !important;
    padding-bottom: 6% !important;
  }

  .pass-exam-add-btn {
    height: 161px !important;
  }
}
</style>