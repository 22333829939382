<template>
    <div>
      <v-overlay v-model="loading"> <!-- page loading -->
        <v-progress-circular indeterminate color="#FFF9C4" :size="80" :width="7"></v-progress-circular>
      </v-overlay>
      <div style="padding:50px;">
        <v-card  class="mx-auto" max-width="70%" color="#FFFFFF" align="center" style="padding:30px 30px 30px; border: 1px solid #c4c5c5 !important;">
          <v-app>
            <div v-for="(course, index) in dataCourse" :key="index" class="mb-3">
              <v-hover v-slot="{ hover }">
                <v-card
                  class="border-alert "
                  color="#C5E1A5"
                  elevation="2"
                  style="padding: 40px 20px 40px;"
                  @click="goToCourseDetail(course._id)"
                  :class="{ 'hovered-card': hover }">
                 <span class="font-weight-bold" 
                    >
                    {{ course.course_name }}
                 </span>
                </v-card>
              </v-hover>
            </div>
          </v-app>
        </v-card>
      </div>
    </div>
  </template>

<script>
export default {
    data: () => ({
        loading: false,
        windowSize: { x: 0, y: 0 },
        dataCourse: []
    }),
    mounted() {
    this.getApplyCourse();
  },
    methods: {
        async getApplyCourse() {
            try {
                this.loading = true
                const axios = this.axios;
                const [ countDataCourse ] = await Promise.all([
                    axios.get('/manage/api/v1/get_apply_course', {
                    }),
                ])
                this.dataCourse = countDataCourse.data.data
                console.log(countDataCourse.data);
                return countDataCourse.data;
            } catch (error) {
                // do some error handler
                console.log(error);
            } finally {
                this.loading = false
            }
        },
        goToCourseDetail(courseId) {
            this.$router.push({ name: 'courses_detail', params: { courseId } });
        },
    }
}
</script>

<style>
.mx-auto {
    font-family: 'Noto Sans Thai', sans-serif;
}

.border-alert {
    border-left: 5px solid #D32F2F !important; 
    font-family: 'Noto Sans Thai', sans-serif;
}
.hovered-card {
 
  transform: scale(1.03); 
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;  
  cursor: pointer;
} 
</style>