<template>
  <v-dialog
    v-model="state"
    max-width="400"
    persistent
    content-class="elevation-0"
  >
    <v-card
      fluid
      class="card-container"
    >
      <v-card-title class="tab-header">
        <v-row
          no-gutters
          align="center"
        >
          <v-col class="pa-0">
            <v-icon
              color="#FFF9C4"
              size="32"
            >
              mdi-information-variant-circle-outline
            </v-icon>
            <b class="ml-1 text-center text-header"> อัปโหลดไฟล์ </b>
          </v-col>
          <v-col
            cols="auto"
            sm="auto"
            md="auto"
            class="pa-0"
          >
            <v-btn
              depressed
              icon
              color="#FFF9C4"
              @click="closeModal()"
            >
              <v-icon size="32"> mdi-close </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-actions>
        <v-form
          ref="validForm"
          lazy-validation
        >
          <v-row class="mt-7">
            <v-row>
              <v-file-input
                outlined
                height="36"
                prepend-icon=""
                append-icon="mdi-upload"
                rounded
                color="#3c5693"
                accept=".xlsx, .xls"
                placeholder="กรุณาเลือกไฟล์ Excel เท่านั้น"
                truncate-length="30"
                class="upload-input-file"
                v-model="file"
                clearable
                :rules="[(v) => !!v || 'กรุณาอัปโหลดไฟล์!']"
                required
              ></v-file-input>
            </v-row>
            <v-row
              class="mt-2"
              align="center"
              justify="space-between"
            >
              <v-col
                cols="auto"
                class="mt-n4"
              >
                ประกาศนียบัตรชุดที่ :
              </v-col>
              <v-col class="pl-10">
                <v-text-field
                  outlined
                  rounded
                  height="36"
                  color="#3c5693"
                  class="input-text"
                  v-model="certificateRound"
                  :rules="[(v) => !!v || 'กรุณากรอกข้อมูล!']"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-row>
        </v-form>
      </v-card-actions>
      <v-card-actions class="pt-7 pb-6">
        <v-row justify="center">
          <v-btn
            height="40"
            depressed
            rounded
            @click="uploadFile()"
            color="rgb(60,86,147)"
            class="confirm-btn"
            :loading="loading"
          >
            ยืนยัน
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    state: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data: () => ({
    loading: false,
    file: undefined,
    certificateRound: undefined,
  }),
  methods: {
    closeModal(data = {}) {
      this.$refs.validForm.reset();
      this.$emit("updateUploadGenCertificated", data);
    },
    async uploadFile() {
      if (!this.$refs.validForm.validate()) {
        return;
      }
      this.loading = true;
      let formData = new FormData();
      formData.append("file", this.file);
      formData.append("round", this.certificateRound);
      try {
        const { data } = await this.axios.post(
          `${process.env.VUE_APP_API_URL}/manage/api/v1/import_data`,
          formData
        );
        if (data["status"]) {
          this.closeModal({
            name: this.file["name"],
            round_id: this.certificateRound,
          });
        } else {
          throw new Error(data["message"]);
        }
      } catch (error) {
        const errorMsg = error?.message ?? error;
        console.log(errorMsg);
        this.alertError(
          "อัปโหลดไฟล์เข้าสู่ระบบไม่สำเร็จ!<br />กรุณาลองใหม่อีกครั้ง"
        );
      } finally {
        this.loading = false;
      }
    },
    alertError(html = "", timer = 4000) {
      this.$swal({
        icon: "error",
        html,
        showCloseButton: true,
        showConfirmButton: false,
        timer: timer,
        customClass: {
          htmlContainer: "alert-text",
        },
      });
    },
  },
};
</script>

<style scoped>
* {
  color: #000;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Noto Sans Thai", sans-serif !important;
}

.row {
  padding: 0;
  margin: 0;
  width: 100% !important;
  box-sizing: border-box !important;
}

.card-container {
  padding: 0;
  border-radius: 18px;
}

.tab-header {
  padding: 8px 12px !important;
  color: #fff9c4;
  background-color: rgb(60, 86, 147);
}

.text-header {
  color: #fff9c4;
}

.upload-input-file >>> .v-input__control > .v-input__slot {
  min-height: auto;
  padding: 0 16px;
  margin-bottom: 4px;
}

.upload-input-file >>> .v-input__control > .v-text-field__details {
  margin-bottom: 0px;
}

.upload-input-file
  >>> .v-input__control
  > .v-input__slot
  > .v-input__append-inner {
  margin: 0;
  padding: 0;
  height: 100%;
  display: flex;
  align-items: center;
}

.input-text >>> .v-input__control > .v-input__slot {
  min-height: auto;
  padding: 0 16px;
  margin-bottom: 4px;
}

.input-text >>> .v-input__control > .v-text-field__details {
  margin-bottom: 0px;
}

.confirm-btn {
  color: #fff;
  font-size: 20px;
  padding: 0 36px !important;
  font-weight: 700;
  letter-spacing: 0.5px;
}

@media only screen and (max-width: 37.5em) {
  .card-text-container {
    padding: 12px !important;
  }
}

@media only screen and (max-width: 25em) {
  .text-header,
  .confirm-btn {
    font-size: 18px;
  }
}
</style>
