<template>
  <div class="pt-3">
    <v-overlay v-model="loading">
      <v-progress-circular indeterminate color="#FFF9C4" :size="80" :width="7"></v-progress-circular>
    </v-overlay>
    <v-row class="pass-exam-row search-exam-header-row">
      <v-col cols="auto" md="auto" lg="auto" class="py-2">
        <v-btn depressed small fab color="#2a9d8f" @click="$router.push({ name: 'login' })">
          <!-- back button -->
          <v-icon color="#CFDB31">mdi-chevron-double-left</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="9" md="auto" lg="auto" class="mt-1 px-4 py-2 search-exam-header-block">
        <b class="search-exam-header">ค้นหาข้อมูลประกาศนียบัตรผู้ปฏิบัติการจากระบบลงทะเบียน</b>
      </v-col>
    </v-row>
    <v-row no-gutters class="standard-row mt-4 mb-7" v-resize="onResize">
      <div style="padding: 0% 2.4% 0 2.7%; width: 100%">
        <v-card class="mx-auto card-search" max-width="90%">
          <template>
            <v-form ref="form_2">
              <v-container style="padding: 50px">
                <v-row class="standard-row">
                  <v-col cols="12" md="4" align="center">
                    <p>เลขบัตรประชาชน</p>
                  </v-col>
                  <v-col cols="12" md="4" align="center">
                    <v-text-field color="#2a9d8f" dense outlined hide-details clearable class="input-card-search"
                      v-model="id_card_num_birth_date" @click:clear="id_card_num_birth_date = null;"
                      :onkeypress="`return ((event.charCode >= 48 && event.charCode <= 57) && &quot;${id_card_num_birth_date}&quot;.length<13)`"
                      :rules="[
                      ...rules.required,
                      ...rules.number,
                      ...rules.textLength13,]">
                    </v-text-field>
                  </v-col>
                </v-row>
                <!-- <v-row class="standard-row">
                  <v-col cols="12" md="4" align="center">
                    <p>คำนำหน้า</p>
                  </v-col>
                  <v-col cols="12" md="4" align="center">
                    <v-combobox color="#2a9d8f" dense outlined rounded clearable hide-details
                      append-icon="mdi-chevron-down" :items="list_title_name"
                      class="input-box-card-search input-card-search" v-model="selected_title_name"></v-combobox>
                  </v-col>
                  <v-col cols="12" md="4" align="center">
                    <v-text-field v-if="selected_title_name === 'อื่นๆ'" color="#2a9d8f" dense outlined hide-details
                      clearable class="input-card-search" v-model="title_name" @click:clear="title_name = null;" :rules="[
                      ...rules.required,
                      ...rules.noDigits,
                    ]">
                    </v-text-field>
                  </v-col>
                </v-row> -->
                <v-row class="standard-row">
                  <v-col cols="12" md="4" align="center">
                    <p>ชื่อ</p>
                  </v-col>
                  <v-col cols="12" md="4" align="center">
                    <v-text-field color="#2a9d8f" dense outlined hide-details clearable class="input-card-search"
                      v-model="first_name" @click:clear="first_name = null;" :rules="[
                      ...rules.noDigits,
                      ...rules.noSpecialChars,
                    ]"></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="standard-row">
                  <v-col cols="12" md="4" align="center">
                    <p>นามสกุล</p>
                  </v-col>
                  <v-col cols="12" md="4" align="center">
                    <v-text-field color="#2a9d8f" dense outlined hide-details clearable class="input-card-search"
                      v-model="last_name" @click:clear="last_name = null;" :rules="[
                      ...rules.noDigits,
                      ...rules.noSpecialChars,
                    ]"></v-text-field>
                  </v-col>
                </v-row>
                <!-- <v-row class="standard-row">
                  <v-col cols="12" md="4" align="center">
                    <p>วันเริ่มประกาศนียบัตร</p>
                  </v-col>
                  <v-col cols="12" md="4" align="center">
                    <v-menu :close-on-content-click="false" offset-y v-model="start_date_menu">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field color="#2a9d8f" dense outlined rounded hide-details readonly clearable v-on="on"
                          v-bind="attrs" @click:clear="start_date = null;" :value="start_date | shortdate"
                          class="input-card-search pass-exam-inputbox pass-exam-start-input"
                          id="start_date"></v-text-field>
                      </template>
                      <v-date-picker  locale="th" color="#2a9d8f" :max="end_date" v-model="start_date"
                        @input="start_date_menu = false;" style="width: 100%; height: 100%;" class="pass-exam-calendar"></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row> -->
                <!-- <v-row class="standard-row">
                  <v-col cols="12" md="4" align="center">
                    <p>วันหมดอายุประกาศนียบัตร</p>
                  </v-col>
                  <v-col cols="12" md="4" align="center">
                    <v-menu :close-on-content-click="false" offset-y v-model="end_date_menu">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field color="#2a9d8f" dense hide-details outlined readonly clearable v-on="on"
                          v-bind="attrs" @click:clear="end_date = null;" :value="end_date | shortdate"
                          class="input-card-search pass-exam-inputbox pass-exam-start-input"
                          id="end_date"></v-text-field>
                      </template>
                      <v-date-picker locale="th" color="#2a9d8f" :min="start_date" v-model="end_date"
                        @input="end_date_menu = false;" style="width: 100%; height: 100%;" class="pass-exam-calendar"></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row> -->
                <v-row class="standard-row">
                  <v-col align="center">
                    <v-btn class="search-btn" color="#2a9d8f" @click="getCertificatedStatus">
                      <v-icon class="text-white" color="#CFDB31" left> mdi-magnify </v-icon> ค้นหาข้อมูล
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </template>
        </v-card>
        <div v-if="card_certificated_status" style="margin-top: 20px" class="CardTrainingStatus">
          <template>
            <v-card class="mx-auto card-search-sm" max-width="90%">
              <v-card-title class="card-header">
                <v-icon color="white" style="padding-right: 5px;">mdi-account-check</v-icon>
                    ผลของการค้นหา
              </v-card-title>
              <template v-if="certificated_status && certificated_status.length">
                <v-card-text v-for="(data, index) in certificated_status" :key="index" class="card-text">
                  <v-row style="padding-top: 20px;" >
                    <v-col cols="12" sm="12" md="2" lg="2" align="center">
                        <v-img class="certificated-image" v-if="data.image_path" :src="data.image_path"></v-img>
                        <v-img class="certificated-image" v-else src="../assets/noname.jpg"></v-img>
                    </v-col>
                    <v-col cols="12" sm="12" md="5" lg="5">
                      <v-row no-gutters class="standard-row" style="text-align: left;">
                        <v-col cols="12" sm="5" md="12" lg="5" class="pl-md-2">
                          <p class="head-topic-certificated">ชื่อ - นามสกุล :</p>
                        </v-col>
                        <v-col cols="12" sm="7" md="12" lg="7" class="pl-md-2">
                          <p class="topic-certificated"> {{ data.title_name }}{{ data.first_name }} {{ data.last_name }}</p>
                        </v-col>
                      </v-row>
                      <v-row no-gutters class="standard-row" style="text-align: left;">
                        <v-col cols="12" sm="5" md="12" lg="5" class="pl-md-2">
                           <p class="head-topic-certificated">ระดับ :</p>
                        </v-col>
                        <v-col cols="12" sm="7" md="12" lg="7" class="pl-md-2">
                           <p class="topic-certificated">{{ data.level }}</p>
                        </v-col>
                      </v-row>
                      <!-- <v-row no-gutters class="standard-row" style="text-align: left;">
                        <v-col cols="12" sm="5" md="12" lg="5" class="pl-md-2">
                           <p class="head-topic-certificated">เลขที่ประกาศนียบัตร :</p>
                        </v-col>
                        <v-col cols="12" sm="7" md="12" lg="7" class="pl-md-2">
                           <p class="topic-certificated">{{ data.certificated_number }}</p>
                        </v-col>
                      </v-row> -->
                      <v-row no-gutters class="standard-row" style="text-align: left;">
                        <v-col cols="12" sm="5" md="12" lg="5" class="pl-md-2">
                           <p class="head-topic-certificated">อนุมัติให้วันที่ :</p>
                        </v-col>
                        <v-col cols="12" sm="7" md="12" lg="7" class="pl-md-2">
                           <p class="topic-certificated">{{ data.certificated_date }} จนถึง {{ data.expire_date }}</p>
                        </v-col>
                        <!-- <v-col cols="12" sm="12" md="12" lg="12"  class="pl-md-2">
                          <v-tooltip right>
                            <template v-slot:activator="{ on }">
                              <v-btn depressed small fab color="#2a9d8f" v-on="on" :href="data.new_certificate || data.old_certificate">
                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" color="#ffff"
                                  fill="currentColor" class="bi bi-file-earmark-pdf" viewBox="0 0 16 16">
                                  <path
                                    d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                  <path
                                    d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                                </svg>
                              </v-btn>
                            </template>
                            <span class="font-theme">ดาวน์โหลดใบประกาศนียบัตรผู้ปฏิบัติการ</span>
                          </v-tooltip>
                        </v-col> -->
                      </v-row>
                    </v-col>
                  </v-row> 
                  
                </v-card-text>
              </template>
              <template v-else>
                <v-card-text class="card-text">
                  <p align="center" style="padding: 10px;">
                    <v-icon class="text-white" color="red" left> mdi mdi-alert-circle-outline </v-icon> ไม่พบข้อมูล
                  </p>
                </v-card-text>
              </template>
            </v-card>
          </template>
        </div>
      </div>
    </v-row>
  </div>
</template>
<script>
import { EventBus } from '../EventBus';
export default {
  data: () => ({
    loading: false,
    list_title_name: [],
    windowSize: { x: 0, y: 0 },
    rules: {
      required: [(v) => !!v],
      number: [(v) => !isNaN(v) && /^[0-9]*$/.test(v)],
      textLength13: [(v) => v && v.length == 13],
      textLength8: [(v) => v && v.length == 8],
      noDigits: [(v) => /^[^0-9]*$/.test(v)],
      noSpecialChars: [(v) => /^[A-Za-zก-ฮะ-๙]*$/.test(v)],
    },
    start_date_menu: false,
    end_date_menu: false,
    start_date: '',
    end_date: '',
    showDescription: false,
    id_card_num_birth_date: "",
    title_name: "",
    selected_title_name: "",
    first_name: "",
    last_name: "",
    date_password: "",
    certificated_status: {},
    card_certificated_status: false,
  }),
  mounted() {
    this.getLisTitleName()
  },
  methods: {
    onResize() {
      this.windowSize = { x: window.innerWidth }
    },
    async getCertificatedStatus() {
      try {
        this.loading = true;
        if (!this.id_card_num_birth_date) {
          EventBus.$emit('alert-warning', 'กรอกข้อมูลเลขบัตรประชาชนให้ครบถ้วน');
          return;
        }
        const isValid = await this.$refs.form_2.validate();
        if (isValid) {
          const axios = this.axios;
          const [response] = await Promise.all([
            axios.get(`/public/api/v1/user_cert_data/`, {
              params: {
                id_card_num: this.id_card_num_birth_date,
                title_name: this.selected_title_name === 'อื่นๆ' ? this.title_name : this.selected_title_name,
                first_name: this.first_name,
                last_name: this.last_name,
                certificated_date: this.start_date,
                expire_date: this.end_date
              }
            }),
          ])
          this.card_certificated_status = false;
          this.card_certificated_status = true;
          // response.data.data.forEach((item) => {
          // item.certificated_date = (item.certificated_date);
          // item.expire_date = new (item.expire_date);
          // })
          this.certificated_status = response.data.data;
          console.log(this.certificated_status);
        } else {
          EventBus.$emit('alert-warning', 'ตรวจสอบข้อมูลอีกครั้ง !');
        }
      } catch (error) {
        EventBus.$emit('alert-error', 'ไม่พบข้อมูล ! กรุณาลองใหม่อีกครั้ง');
      } finally {
        this.loading = false;
      }
    },
    async getLisTitleName() {
      try {
        const baseURL = { baseURL: process.env.VUE_APP_API_URL, url: 'public/api/v1/get_titile_name' }
        const [lisTitleName] = await Promise.all([this.axios(baseURL)])
        this.list_title_name = lisTitleName.data.data
      } catch (error) {
        EventBus.$emit('alert-error', 'เกิดปัญหาระหว่างดึงข้อมูล กรุณาลองใหม่ภายหลัง')
        console.error(error);
      } finally {
        this.loading = false
      }

    },
    downloadCertificated() {
      console.log(this.id_card_num_birth_date)
    }
  }
};
</script>
<style>
.search-exam-header-block {
  background: #f4a261;
  border-radius: 32px;
  height: fit-content;
}

.card-search {
  border-radius: 10px 45px !important;
  border: 5px solid rgb(60, 86, 147) !important;
  box-shadow: none !important;
  background-color: #a7d1fd !important;
  color: #000000 !important;
}
.card-search-sm {
 border-radius: 20px !important;
}

.card-header {
 
    background-color: rgb(60,86,147);
    color: white;
}

.search-exam-header {
  font-family: "Noto Sans Thai", sans-serif;
  color: white;
  font-size: 22px;
}

.card-text {
  background-color: #a7d1fd;
  border: 3px solid rgb(60, 86, 147) !important;
  border-top: none !important;
}

.input-card-search {
  border-radius: 10px !important;
  background-color: white;
}

.input-box-card-search>.v-input__control>.v-input__slot {
  padding-left: 3% !important;
  padding-right: 1% !important;
}

.search-btn {
  color: white !important;
  border-radius: 32px !important;
}

.pass-exam-calendar {
  font-family: 'Noto Sans Thai', sans-serif;
}
.certificated-image {
  border-radius: 10px;
  border: 3px double rgb(60, 86, 147) !important;
  height: auto;
}
.head-topic-certificated {
    font-weight: bold;
    font-size: 16px;
}
.topic-certificated {
    font-size: 16px;
}
</style>
