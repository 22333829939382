<template>
    <v-container fluid class="index-page">
        <v-row>
            <v-col class="col-sm-3">
                <v-list>
                    <v-card>
                        <v-list-item-group v-model="selectedTab">
                            <v-list-item v-for="(item, index) in items" :key="index" @click="selectedTab = index" color="#1565C0" 
                            outlined class="text-start" style="border: 1px solid #c4c5c5; border-radius: 2px;">
                                <v-list-item-content>
                                    <v-list-item-title style="padding:5px"><v-icon color="primary"
                                            style="padding-right:10px;">{{ item.icon }}</v-icon>{{ item.text}}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-card>
                </v-list>
            </v-col>
            <v-col class="col-sm-9">
                <div v-if="selectedTab === 0">
                    <div>
                        <template>
                            <v-carousel cycle height="400" hide-delimiter-background :showArrows="true"
                                v-model="activeSlide" :interval="5000">
                                <v-carousel-item v-for="(slide, i) in slides" :key="i">
                                    <v-sheet>
                                        <v-img :src="slide" height="100%" contain></v-img>
                                    </v-sheet>
                                </v-carousel-item>
                            </v-carousel>
                        </template>
                        <br>
                        <v-card class="mx-auto" color="#FFFFFF">
                            <h3><v-avatar size="55">
                                    <v-icon color="primary">mdi-bullhorn</v-icon>
                                </v-avatar>ข่าวที่หน่วยงานจะประกาศ
                            </h3>
                            <v-card v-for="(course, index) in news_course_detail" :key="index" class="left-border-alert-red" color="#FFCDD2" elevation="2" 
                               style="padding:40px 20px 40px; align-items: center;">
                                <div style="display: flex; align-items: center;">
                                    <v-icon size="40" color="#FF1744">mdi-new-box</v-icon>
                                    <h4 style="margin-left: 10px;">{{ course.headline }}</h4>
                                </div>
                                <q style="margin-left: 50px;">{{ course.content }}</q>
                            </v-card>
                        </v-card>
                    </div>
                </div>
                <div v-else-if="selectedTab === 1">
                    <div>
                        <template>
                            <v-card class="mx-auto">
                                <v-card-title class="blue-card-header">
                                    <v-icon color="white" style="padding-right: 5px;">mdi-format-list-bulleted</v-icon>
                                    ขั้นตอนการสมัครอบรม
                                </v-card-title>
                                <div>
                                    <v-card-text v-for="(course, index) in step_apply_detail" :key="index" class="custom-card-text">
                                        <v-row>
                                            <v-col cols="3" align="center">
                                                <img src="../assets/logo.png" style="width: 100px;" />
                                            </v-col>
                                            <v-col cols="9">
                                                <h4>{{ course.header }}</h4>
                                                <q>{{ course.content }}</q>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </div>
                            </v-card>
                        </template>
                    </div>
                </div>
                <div v-else-if="selectedTab === 2">
                    <div>
                        <template>
                            <v-card class="mx-auto blue-card">
                                <v-card-title class="blue-card-header">
                                    <v-icon color="white" style="padding-right: 5px;">mdi-account-search</v-icon>
                                    ตรวจสอบสถานะการสมัครอบรม
                                </v-card-title>
                                <template>
                                    <v-form ref="form_1">
                                        <v-container style="padding: 50px;">
                                            <v-row>
                                                <v-col cols="12" md="4" align="center">
                                                    <p>กรอกเลขบัตรประชาชนของท่าน</p>
                                                </v-col>
                                                <v-col cols="12" md="4" align="center">
                                                    <v-text-field dense outlined hide-details 
                                                      class="pass-exam-person-input"  v-model="id_card_num" :onkeypress='`return ((event.charCode >= 48 && event.charCode <= 57) && "${id_card_num}".length<13)`' :rules="[...rules.required, ...rules.number, ...rules.textLength13]"></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col align="center">
                                                    <v-btn color="primary" @click="getTrainingStatus(id_card_num)">
                                                        <v-icon left>
                                                            mdi-magnify
                                                        </v-icon> ค้นหา
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-form>
                                </template>
                            </v-card>
                        </template>
                        <div v-if="card_training_status" style="margin-top: 20px;" class="CardTrainingStatus">
                            <template>
                                <v-card class="mx-auto blue-card">
                                    <v-card-title class="blue-card-header">
                                        <v-icon color="white" style="padding-right: 5px;">mdi-account-check</v-icon>
                                        ผลของการค้นหา
                                    </v-card-title>
                                    <v-card-title>
                                        คุณ : {{ training_status.full_name }}
                                    </v-card-title>
                                    <v-card-text v-for="(course, index) in training_status.course" :key="index" class="custom-card-text">
                                        <v-row>
                                            <v-col cols="9">
                                                <h4>{{ index + 1 }} : {{ course.course_name }}</h4>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </template>
                        </div>
                    </div>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { EventBus } from '../EventBus'
export default {
    data: () => ({
        loading: false,
        selectedTab: 0,
        birthDay: null,
        windowSize: { x: 0, y: 0 },
        rules: {
            required: [v => !!v ],
            number: [v => !isNaN(v) && /^[0-9]*$/.test(v)],
            textLength13: [v => (v && v.length == 13) ],
            textLength8: [v => (v && v.length == 8)]
        },
        items: [
            { text: 'หน้าแรก', icon: 'mdi-home' },
            { text: 'สมัครอบรม',icon: 'mdi-pencil' },
            { text: 'ตรวจสอบสถานะการสมัครอบรม', icon: 'mdi-check' },
        ],
        slides: [
            {
                src: 'https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg',
            },
            {
                src: 'https://cdn.vuetifyjs.com/images/carousel/sky.jpg',
            },
            {
                src: 'https://cdn.vuetifyjs.com/images/carousel/bird.jpg',
            },
            {
                src: 'https://cdn.vuetifyjs.com/images/carousel/planet.jpg',
            },
        ],
        activeSlide: 0,
        showDescription: false,
        id_card_num: '',
        date_password:'',
        news_course_detail: {},
        step_apply_detail:{},
        training_status:{},
        card_training_status :false,
    }),
    created() {
        this.courseId = this.$route.params.courseId;
        console.log('courseId:', this.courseId); // ตรวจสอบค่า courseId 
        this.getAllNewsCourseDetail();// เรียกใช้งาน function เพื่อดึงข้อมูลของคอร์สด้วย courseId
        this.getStepApplyCourseDetail()
    },
    watch: {
        selectedTab(newVal) {
            if (newVal) {
                this.id_card_num = '';
                this.card_training_status = false;
            }
        }
    },
    methods: {
        navigateToRoute(path) {
            this.$router.push({ path });
        },
        async getAllNewsCourseDetail() {
            try {
                this.loading = true;
                const axios = this.axios;
                const response = await axios.get(`/news/api/v1/all_news/`,{
                    params: {
                        course_id: this.courseId
                    }
                });
                this.news_course_detail = response.data.data;
                console.log(this.news_course_detail);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                this.loading = false;
            }
        },
        async getStepApplyCourseDetail() {
            try {
                this.loading = true;
                const axios = this.axios;
                const response = await axios.get(`manage/api/v1/step_apply/`,{
                    params: {
                        course_id: this.courseId
                    }
                });
                this.step_apply_detail = response.data.data;
                console.log(this.step_apply_detail);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                this.loading = false;
            }
        },
        async getTrainingStatus() {
            try {
                this.loading = true;
                if (!this.id_card_num) {
                    EventBus.$emit('alert-warning', 'กรอกข้อมูลให้ครบถ้วน');
                    return; // หยุดการทำงานในกรณีข้อมูลไม่ครบ
                }
                const isValid = await this.$refs.form_1.validate();
                if (isValid) {
                    const axios = this.axios;
                    const response = await axios.post(`certificated/api/v1/get_by_id_card_num/`, {
                        id_card_num: this.id_card_num
                    });
                    this.card_training_status = true;
                    this.training_status = response.data.data;
                    console.log(this.training_status);
                } else {
                    EventBus.$emit('alert-error', 'ตรวจสอบข้อมูลอีกครั้ง !');
                }
            } catch (error) {
                EventBus.$emit('alert-error', 'ไม่พบข้อมูล ! กรุณาลองใหม่อีกครั้ง');
                console.error('Error fetching data:', error);
            } finally {
                this.loading = false;
            }
        },
    }
}
</script>

<style>
.mx-auto,.text-start {
    font-family: 'Noto Sans Thai', sans-serif;
}

.left-border-alert-red {
    border-left: 4px solid #EF5350 !important;
}

.left-border-alert-blue {
    border-left: 4px solid #2979FF !important;
}

.index-page {
    padding: 50px 80px 10px 80px;

}

.blue-card-header {
    background-color: #1976D2;
    color: white;
}

.pass-exam-person-input {
    font-family: 'Noto Sans Thai', sans-serif;
    font-size: 14px;
}

.pass-exam-person-input>.v-input__control>.v-input__slot {
    min-height: 31px !important;
}

.custom-card {
    border-radius: 20px;
    border: 1px solid #ccc;
    padding: 20px;
    max-width: 600px;
    margin: auto;
}

.custom-card-text {
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
    margin-bottom: 10px;

}
.pd-pass-exam-birthday.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot{
  padding-left: 10px !important;
}
</style>