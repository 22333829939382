<template>
    <v-container fluid class="pa-0 container">
        <v-row class="header-container">
            <v-col cols="auto" sm="auto" md="auto" lg="auto" class="py-2">
                <v-btn depressed small fab color="rgb(154,179,209)" @click="$router.back()">
                    <v-icon color="rgb(60,86,147)">mdi-chevron-double-left</v-icon>
                </v-btn>
            </v-col>
            <v-col cols="9" sm="auto" md="auto" lg="auto" class="mt-1 px-4 py-2 header-text-container">
                <b class="header-text">
                    ข้อมูลบันทึกการลงทะเบียนเจ้าหน้าที่ขององค์กร
                </b>
            </v-col>
        </v-row>
        <v-row no-gutters class="table-container">
            <v-data-table
                range
                fixed-header
                class="elevation-0 regis-table"
                mobile-breakpoint="600" 
                :headers="headers"
                :items="itemTable"
                :options.sync="pageOption"
                :server-items-length="itemTableCount"
                :footer-props="footerTableProps"
                :loading="loading"
                @click:row="openDetailRegisLog($event)"
            >
                <template v-slot:top>
                    <v-row no-gutters align="center" class="search-bar-container">
                        <b>วันที่อบรม :</b>
                        <v-menu
                            :close-on-content-click="false"
                            min-width="auto"
                            offset-y
                            v-model="searchInputData['startMenu']"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    dense
                                    outlined
                                    rounded
                                    hide-details
                                    readonly
                                    v-on="on"
                                    clearable
                                    label="วันที่เริ่ม"
                                    class="date-picker-text"
                                    :value="searchInputData['startDate'] | shortdate"
                                    @click:clear="clearDatePicker"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                locale="th"
                                color="rgb(60,86,147)"
                                class="date-picker-input"
                                :max="searchInputData['endDate'] || searchInputData['maxDate']"
                                v-model="searchInputData['startDate']"
                                @change="selectedDatePicker"
                            ></v-date-picker>
                        </v-menu>
                        <b class="text-sm-right">ถึง :</b>
                        <v-menu
                            :close-on-content-click="false"
                            min-width="auto"
                            offset-y
                            v-model="searchInputData['endMenu']"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    dense
                                    outlined
                                    rounded
                                    hide-details
                                    readonly
                                    v-on="on"
                                    clearable
                                    label="วันที่สิ้นสุด"
                                    class="date-picker-text"
                                    :value="searchInputData['endDate'] | shortdate"
                                    @click:clear="clearDatePicker"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                locale="th"
                                color="rgb(60,86,147)"
                                class="date-picker-input"
                                :max="searchInputData['maxDate']"
                                :min="searchInputData['startDate']"
                                v-model="searchInputData['endDate']"
                                @change="selectedDatePicker"
                            ></v-date-picker>
                        </v-menu>
                        <v-text-field
                            label="ค้นหา"
                            single-line
                            hide-details
                            append-icon="mdi-magnify"
                            class="text-input-search"
                            v-model="searchInputData['keyword']"
                            @keyup.enter="confirmTextSearch"
                            @click:append="confirmTextSearch"
                            @input="clearFilterAndSearchData"
                        ></v-text-field>
                    </v-row>
                </template>
                <template v-slot:[`item.action_date`]="{ item }">
                    <p v-if="item.type == 'edit' || item.type == 'delete'" class="ma-0">{{ item.updatedAt | shortmonthyearandtime }}</p>
                    <p v-else class="ma-0">{{ item.createdAt | shortmonthyearandtime }}</p>
                </template>
                <template v-slot:[`item.type`]="{ item }">
                    <p class="ma-0" v-if="item.type === 'create'">ลงทะเบียนใหม่</p>
                    <p class="ma-0" v-else-if="item.type === 'edit'">แก้ไขข้อมูล</p>
                    <p class="ma-0" v-else-if="item.type === 'delete'">ลบข้อมูล</p>
                    <p class="ma-0" v-else></p>
                </template>
                <template v-slot:[`item.status`]="{ item }">
                    <p class="font-theme ma-0" 
                        v-if="(item.type == 'create' && item.status?.toLowerCase() === 'success') || 
                        (item.type == 'edit' && item.status_log?.toLowerCase() == 'success') || 
                        (item.type == 'delete' && item.status_log?.toLowerCase() == 'success') || 
                        (item.type == 'create' && item.description == 'error')"
                        style="color: green;"
                        >
                        สำเร็จ
                    </p>
                    <p class="font-theme ma-0" v-else style="color:red">
                        ไม่สำเร็จ
                    </p>
                    <!-- <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                v-if="(item.type == 'create' && item.status?.toLowerCase() === 'success') || (item.type == 'edit' && item.status_log?.toLowerCase() == 'success') || (item.type == 'delete' && item.status_log?.toLowerCase() == 'success') || (item.type == 'create' && item.description == 'error')"
                                v-bind="attrs"
                                v-on="on"
                                small
                                class="status-icon"
                                style="background-color: #1BDE3A;"
                            >
                                mdi-check-bold
                            </v-icon>
                            <v-icon
                                v-else
                                v-bind="attrs"
                                v-on="on"
                                small
                                class="status-icon"
                                style="background-color: #BDBDBD;"
                            >
                                mdi-close-thick
                            </v-icon>
                            </template>
                            <span class="font-theme">
                            {{ item.type == 'create'? (item.status?.toLowerCase() === 'success' || item.description == 'error' ? 'สำเร็จ' : 'ไม่สำเร็จ') : item.type == 'edit'? (item.status_log?.toLowerCase() == 'success' ? 'สำเร็จ' : 'ไม่เสำเร็จ') : item.type == 'delete'? (item.status_log?.toLowerCase() == 'success' ? 'สำเร็จ' : 'ไม่สำเร็จ') : 'ไม่สำเร็จ'}}
                        </span>
                    </v-tooltip> -->
                </template>
                <template slot="no-data">
                    <span>
                        ไม่พบข้อมูล
                    </span>
                </template>
            </v-data-table>
        </v-row>
        <DetailRegisSystemLogModal />
    </v-container>
</template>

<script>
import _ from 'lodash';
import { EventBus } from '../EventBus';
import DetailRegisSystemLogModal from '../components/DetailRegisSystemLogModal.vue';
export default {
    components: {
        DetailRegisSystemLogModal
    },
    data: () => ({
        headers: [
            { text: "เวลาที่ลงทะเบียนข้อมูล", value: "action_date", sortable: false },
            // { text: "ลำดับ", value: "no", align: "center", sortable: false },
            { text: "ชื่อ - นามสกุล", value: "name", sortable: false },
            { text: "หน่วยงานต้นสังกัด", value: "medical_operation_name", sortable: false },
            { text: "จังหวัดต้นสังกัด", value: "province", sortable: false },
            { text: "วุฒิการศึกษา", value: "education_level", sortable: false },
            { text: "การดำเนินการ", value: "type", align: "center", sortable: false },
            { text: "สถานะ", value: "status", align: "center", sortable: false }
        ],
        footerTableProps: {
            'items-per-page-text': 'จำนวนแถวต่อหน้า',
            'items-per-page-options': [ 10, 20, 30, 40, 50 ]
        },
        searchInputData: {
            "keyword": null,
            "startMenu": false,
            "endMenu": false,
            "startDate": null,
            "endDate": null,
            "maxDate": new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
        },
        pageOption: {
            "page": 1,
            "itemsPerPage": 20,
            "system": null,
            "start_date": null,
            "end_date": null,
            "keyword": null
        },
        itemTable: [],
        loading: false,
        itemTableCount: 0
    }),
    watch: {
        pageOption: {
            handler: _.debounce(function(newValue) {
                this.getRegisLogs(newValue);
            }, 100),
            deep: true
        }
    },
    mounted() {
        const system = sessionStorage?.system || null;
        if (system) {
            this.pageOption["system"] = system;
        } else {
            this.$router.back();
        }
    },
    beforeDestroy() {
        sessionStorage.removeItem("system");
    },
    methods: {
        async getRegisLogs({ keyword, system, start_date, end_date, page, itemsPerPage: limit } = {}) {
            try {
                this.loading = true;
                const axios = this.axios;
                const [ itemLog, countItemLog ] = await Promise.all([
                    axios.get('/register/api/v1/regis_system_log', {
                        params: {
                            offset: (page - 1) * limit,
                            system,
                            keyword,
                            start_date,
                            end_date,
                            limit
                        }
                    }),
                    axios.get('/register/api/v1/regis_system_log_count', {
                        params: {
                            system,
                            keyword,
                            start_date,
                            end_date
                        }
                    }),
                ]);
                this.itemTableCount = countItemLog.data.data.total;
                this.itemTable = itemLog.data.data.map((e, i) => ({
                    no: (page - 1) * limit + i + 1,
                    ...e,
                    ...e.actor,
                    ...e.data,
                    type: e.type?.toLowerCase() || null
                }));
                const tableElement = document.getElementsByClassName('regis-table')[0];
                tableElement.getElementsByClassName("v-data-table__wrapper")[0].scrollTop = 0;
            } catch (error) {
                this.itemTable = [];
                this.itemTableCount = 0;
                console.log(error);
            } finally {
                this.loading = false;
            }
        },
        confirmTextSearch() {
            if(this.searchInputData['keyword'] == this.pageOption['keyword']){
                return
            }
            this.pageOption = {
                ...this.pageOption,
                keyword: this.searchInputData['keyword'],
                page: 1
            };
        },
        clearFilterAndSearchData() {
            if (!this.searchInputData['keyword'] && this.pageOption['keyword']) {
                this.pageOption['keyword'] = null;
            }
        },
        selectedDatePicker() {
            if (this.searchInputData['startDate'] && this.searchInputData['endDate']) {
                this.pageOption = {
                    ...this.pageOption,
                    start_date: this.searchInputData['startDate'],
                    end_date: this.searchInputData['endDate'],
                    page: 1
                };
            }
            this.searchInputData = {
                ...this.searchInputData,
                startMenu: null,
                endMenu: null
            };
        },
        clearDatePicker() {
            this.pageOption = {
                ...this.pageOption,
                start_date: null,
                end_date: null
            };
            this.searchInputData = {
                ...this.searchInputData,
                startDate: null,
                endDate: null,
                startMenu: null,
                endMenu: null
            };
        },
        openDetailRegisLog(data) {
            EventBus.$emit('openDetailRegisSystemLogModal', data);
        }
    }
};
</script>

<style scoped>
    .container,
    .font-theme,
    .date-picker-input {
        font-family: "Noto Sans Thai", sans-serif;
    }

    .row {
        width: 100%;
        margin: 0%;
        padding: 0%;
    }

    .header-container {
        margin-top: 12px;
    }

    .header-text-container {
        background: rgb(154,179,209);
        border-radius: 32px;
        height: fit-content;
    }

    .header-text {
        font-family: 'Noto Sans Thai', sans-serif;
        color: rgb(60,86,147);
        font-size: 22px;
    }

    .search-bar-container {
        padding-bottom: 24px;
        display: grid;
        grid-template-columns: 73px 1fr 27px 1fr 50% !important;
        gap: 18px;
    }

    .date-picker-text {
        font-family: 'Noto Sans Thai', sans-serif;
        font-size: 15px;
    }

    .date-picker-text >>> .v-input__control > .v-input__slot {
        padding-left: 10px !important;
        padding-right: 3% !important;
    }

    .date-picker-text >>> .v-input__control > .v-input__slot > .v-text-field__slot > label {
        left: 20px !important;
    }

    .text-input-search {
        margin: 0;
        padding: 0;
    }

    .table-container {
        margin: 16px 0 28px 0;
        padding: 0 2.4%;
    }

    .regis-table {
        width: 100%;
        background: rgba(255, 255, 255, 0.44) !important;
        border-radius: 10px;
    }

    .regis-table >>> .v-data-table__wrapper {
        scroll-behavior: smooth;
    }

    .regis-table >>> .v-data-table__wrapper > table > thead > tr > th {
        background-color: #f0f2f5 !important;
    }

    .regis-table >>> .v-data-table__wrapper > table > thead > tr > th:first-child {
        border-radius: 10px 0 0 0;
        font-weight: 700;
    }

    .regis-table >>> .v-data-table__wrapper > table > thead > tr > th:last-child {
        border-radius: 0 10px 0 0;
    }

    .regis-table >>> .v-data-table__wrapper > table > tbody > tr:hover {
        cursor: pointer;
        background-color: #f0f2f5 !important;
    }

    .regis-table >>> .v-data-table__wrapper > table > thead {
        background-color: white;
    }

    .regis-table >>> .v-data-table__wrapper > table > thead > tr > th > span {
        font-weight: 700 !important;
        font-size: 15px;
    }

    .regis-table >>> .v-data-table__wrapper > table > tbody > tr > td {
        font-weight: 700 !important;
        font-size: 13px;
        border-bottom: none !important;
        padding-top: 5px;
    }

    .regis-table >>> .v-data-table__wrapper {
        height: calc(100vh - 283px);
        overflow: auto;
    }

    .status-icon {
        background-color: red;
        color: #fff;
        border-radius: 50%;
        padding: 4px;
    }

    @media only screen and (max-width: 60rem) {
        .search-bar-container {
            grid-template-columns: 74px auto 74px auto !important;
        }

        .text-input-search {
            grid-column: 1/-1;
        }
    }

    @media only screen and (max-width: 37.5rem) {

        .header-container {
            margin-top: 6px;
        }

        .header-text-container {
            border-radius: 25px;
        }

        .header-text {
            font-size: 17px;
        }

        .search-bar-container {
            grid-template-columns: 100% !important;
        }

        .regis-table >>> .v-data-footer {
            justify-content: flex-end;
            border: 2px solid #f0f2f5;
            border-top: transparent;
            margin-right: 0;
        }

        .regis-table >>> .v-data-footer > .v-data-footer__select {
            display: none;
        }

        .regis-table >>> .v-data-table__wrapper {
            border: 2px solid #f0f2f5;
            border-bottom: transparent;
        }

        .regis-table >>> .v-data-table__wrapper .v-data-table__mobile-table-row:nth-of-type(odd) {
            background-color: #f0f2f5  !important;
        }

        .regis-table >>> .v-data-table__wrapper > table > tbody > .v-data-table__empty-wrapper {
            display: flex;
            justify-content: center;
        }
    }
</style>