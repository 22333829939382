<template>
    <v-container fluid class="index-page" v-resize="onResize"
        style="background-color: rgba(151, 152, 153, 0.27); height: 100%;">
        <div align="center">
            <template>
                <v-card class="mx-auto blue-card" max-width="90%">
                    <v-card-title class="blue-card-header">
                        <v-icon color="white" style="padding-right: 5px;">mdi-account-search</v-icon>
                        ตรวจสอบสถานะใบประกาศนียบัตร
                    </v-card-title>
                    <template>
                        <v-form ref="form_2">
                            <v-container style="padding: 50px;">
                                <v-row>
                                    <v-col cols="12" md="4" align="center">
                                        <p>กรอกเลขบัตรประชาชนของท่าน</p>
                                    </v-col>
                                    <v-col cols="12" md="4" align="center">
                                        <v-text-field dense outlined hide-details class="pass-exam-person-input"
                                            v-model="id_card_num_birth_date"
                                            :onkeypress='`return ((event.charCode >= 48 && event.charCode <= 57) && "${id_card_num_birth_date}".length<13)`'
                                            :rules="[...rules.required, ...rules.number, ...rules.textLength13]"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="4" align="center">
                                        <p>กรอก วัน เดือน ปีเกิด ของท่าน</p>
                                    </v-col>
                                    <v-col cols="12" md="4" align="center">
                                        <v-text-field dense outlined hide-details class="pass-exam-person-input"
                                            v-model="date_password" @focus="showDescription = true"
                                            @blur="showDescription = false"
                                            :rules="[...rules.required, ...rules.number, ...rules.textLength8]"
                                            :onkeypress='`return ((event.charCode >= 48 && event.charCode <= 57) && "${date_password}".length<8)`'></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="4" align="center">
                                        <h6 v-if="showDescription" :style="{ color: '#C62828' }">
                                            กรอกวันเดือนปีเกิดของท่านโดยไม่ต้องไส่ / เช่น 16082566</h6>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col align="center">

                                        <v-btn color="primary"
                                            @click="getCertificatedStatus(id_card_num_birth_date, date_password)">
                                            <v-icon left>
                                                mdi-magnify
                                            </v-icon> ค้นหา
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-form>
                    </template>
                </v-card>
            </template>
            <div v-if="card_certificated_status" style="margin-top: 20px;" class="CardTrainingStatus">
                <template>
                    <v-card class="mx-auto blue-card" max-width="90%">
                        <v-card-title class="blue-card-header">
                            <v-icon color="white" style="padding-right: 5px;">mdi-account-check</v-icon>
                            ผลของการค้นหา
                        </v-card-title>
                        <v-card-text v-for="(course, index) in certificated_status.course" :key="index"
                            style="padding: 30px;" class="custom-card-text">
                            <v-row>
                                <v-col cols="2" sm="12" md="2" lg="2" align="center">
                                    <v-avatar
                                        color="white"
                                        size="150"
                                        rounded="lg"
                                    >
                                        <v-img v-if="course.image_path !== '-'" :src="course.image_path"></v-img>
                                        <v-img v-else class="background-image"></v-img>
                                    </v-avatar>
                                </v-col>
                                <v-col cols="12" sm="12" md="5" lg="5">
                                    <v-row no-gutters class="standard-row" style="text-align: left;">
                                        <v-col cols="12" sm="5" md="12" lg="5" class="pl-md-2">
                                            <p class="head-topic">ชื่อ - นามสกุล :</p>
                                        </v-col>
                                        <v-col cols="12" sm="7" md="12" lg="7" class="pl-md-2">
                                            <p> {{ certificated_status.full_name }}</p>
                                        </v-col>
                                    </v-row>
                                    <v-row no-gutters class="standard-row" style="text-align: left;">
                                        <v-col cols="12" sm="5" md="12" lg="5" class="pl-md-2">
                                            <p class="head-topic">เลขประกาศนียบัตร :</p>
                                        </v-col>
                                        <v-col cols="12" sm="7" md="12" lg="7" class="pl-md-2">
                                            <p>{{ course.certificated_number }}</p>
                                        </v-col>
                                    </v-row>
                                    <v-row no-gutters class="standard-row" style="text-align: left;">
                                        <v-col cols="12" sm="5" md="12" lg="5" class="pl-md-2">
                                            <p class="head-topic">จังหวัดต้นสังกัด :</p>
                                        </v-col>
                                        <v-col cols="12" sm="7" md="12" lg="7" class="pl-md-2">
                                            <p>{{ course.province }}</p>
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="windowSize.x > 959" no-gutters class="standard-row"
                                        style="text-align: left;">
                                        <v-col cols="12" sm="5" md="12" lg="5" class="pl-md-2">
                                            <p class="head-topic">วันที่รับรอง :</p>
                                        </v-col>
                                        <v-col cols="12" sm="7" md="12" lg="7" class="pl-md-2">
                                            <p>{{ course.certificated_date }}</p>
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="windowSize.x > 959" no-gutters class="standard-row"
                                        style="text-align: left;">
                                        <v-col cols="12" sm="5" md="12" lg="5" class="pl-md-2">
                                            <p class="head-topic">ใบประกาศนียบัตรตามเกณฑ์ใหม่ :</p>
                                        </v-col>
                                        <v-col cols="12" sm="7" md="12" lg="7" class="pl-md-2">
                                            <v-tooltip top v-if="course.certificate_data.new_certificate">
                                                <template v-slot:activator="{ on }">
                                                    <a v-on="on" :href="course.certificate_data.new_certificate">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22"
                                                            color="rgb(60,86,147)" fill="currentColor"
                                                            class="bi bi-file-earmark-pdf" viewBox="0 0 16 16">
                                                            <path
                                                                d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                                            <path
                                                                d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                                                        </svg>
                                                    </a>
                                                </template>
                                                <span class="font-theme">ดาวน์โหลดประกาศนียบัตรตามเกณฑ์ใหม่</span>
                                            </v-tooltip>
                                            <p v-else-if="!course.certificate_data.new_certificate"> - </p>
                                        </v-col>
                                    </v-row>
                                    <v-row no-gutters class="standard-row" style="text-align: left;">
                                        <v-col cols="12" sm="5" md="12" lg="5" class="pl-md-2">
                                            <p class="head-topic">หลักสูตร :</p>
                                        </v-col>
                                        <v-col cols="12" sm="7" md="12" lg="7" class="pl-md-2">
                                            <p>{{ course.course_selected }}</p>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12" sm="12" md="5" lg="5">
                                    <v-row no-gutters class="standard-row pr-md-2" style="text-align: left;">
                                        <v-col cols="12" sm="5" md="12" lg="5">
                                            <p class="head-topic">ระดับ :</p>
                                        </v-col>
                                        <v-col cols="12" sm="7" md="12" lg="7">
                                            {{ course.level }}
                                        </v-col>
                                    </v-row>
                                    <v-row no-gutters class="standard-row pr-md-2" style="text-align: left;">
                                        <v-col cols="12" sm="5" md="12" lg="5">
                                            <p class="head-topic">หน่วยงานต้นสังกัด :</p>
                                        </v-col>
                                        <v-col cols="12" sm="7" md="12" lg="7">
                                            <p>{{ course.agency }}</p>
                                        </v-col>
                                    </v-row>
                                    <v-row no-gutters class="standard-row pr-md-2" style="text-align: left;">
                                        <v-col cols="12" sm="5" md="12" lg="5">
                                            <p class="head-topic">อนุมัติ :</p>
                                        </v-col>
                                        <v-col cols="12" sm="7" md="12" lg="7">
                                            <p>{{ course.approve }}</p>
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="windowSize.x < 960" no-gutters class="standard-row"
                                        style="text-align: left;">
                                        <v-col cols="12" sm="5" md="12" lg="5" class="pl-md-2">
                                            <p class="head-topic">วันที่รับรอง :</p>
                                        </v-col>
                                        <v-col cols="12" sm="7" md="12" lg="7" class="pl-md-2">
                                            <p>{{ course.certificated_date }}</p>
                                        </v-col>
                                    </v-row>
                                    <v-row no-gutters class="standard-row pr-md-2" style="text-align: left;">
                                        <v-col cols="12" sm="5" md="12" lg="5">
                                            <p class="head-topic">วันหมดอายุ :</p>
                                        </v-col>
                                        <v-col cols="12" sm="7" md="12" lg="7">
                                            <p>{{ course.expire_date }}</p>
                                        </v-col>
                                    </v-row>
                                    <v-row no-gutters class="standard-row pr-md-2" style="text-align: left;">
                                        <v-col cols="12" sm="5" md="12" lg="5">
                                            <p class="head-topic">ใบประกาศนียบัตรตามเกณฑ์เดิม :</p>
                                        </v-col>
                                        <v-col cols="12" sm="7" md="12" lg="7">
                                            <v-tooltip top v-if="course.certificate_data.old_certificate">
                                                <template v-slot:activator="{ on }">
                                                    <a v-on="on" :href="course.certificate_data.old_certificate">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22"
                                                            color="rgb(60,86,147)" fill="currentColor"
                                                            class="bi bi-file-earmark-pdf" viewBox="0 0 16 16">
                                                            <path
                                                                d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                                            <path
                                                                d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                                                        </svg>
                                                    </a>
                                                </template>
                                                <span class="font-theme">ดาวน์โหลดประกาศนียบัตรตามเกณฑ์เดิม</span>
                                            </v-tooltip>
                                            <p v-else-if="!course.certificate_data.new_certificate"> - </p>
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="windowSize.x < 960" no-gutters class="standard-row"
                                        style="text-align: left;">
                                        <v-col cols="12" sm="5" md="12" lg="5" class="pl-md-2">
                                            <p class="head-topic">ใบประกาศนียบัตรตามเกณฑ์ใหม่ :</p>
                                        </v-col>
                                        <v-col cols="12" sm="7" md="12" lg="7" class="pl-md-2">
                                            <v-tooltip top v-if="course.certificate_data.new_certificate">
                                                <template v-slot:activator="{ on }">
                                                    <a v-on="on" :href="course.certificate_data.new_certificate">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22"
                                                            color="rgb(60,86,147)" fill="currentColor"
                                                            class="bi bi-file-earmark-pdf" viewBox="0 0 16 16">
                                                            <path
                                                                d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                                            <path
                                                                d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                                                        </svg>
                                                    </a>
                                                </template>
                                                <span class="font-theme">ดาวน์โหลดประกาศนียบัตรตามเกณฑ์ใหม่</span>
                                            </v-tooltip>
                                            <p v-else-if="!course.certificate_data.new_certificate"> - </p>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </template>
            </div>
        </div>
    </v-container>
</template>

<script>
import { EventBus } from '../EventBus'
export default {
    data: () => ({
        loading: false,
        windowSize: { x: 0, y: 0 },
        rules: {
            required: [v => !!v],
            number: [v => !isNaN(v) && /^[0-9]*$/.test(v)],
            textLength13: [v => (v && v.length == 13)],
            textLength8: [v => (v && v.length == 8)]
        },
        showDescription: false,
        id_card_num_birth_date: '',
        date_password: '',
        certificated_status: {},
        card_certificated_status: false,
    }),
    mounted() {
        this.onResize()
    },
    methods: {
        navigateToRoute(path) {
            this.$router.push({ path });
        },
        onResize() {
            this.windowSize = { x: window.innerWidth }
        },
        async getCertificatedStatus() {
            try {
                this.loading = true;
                if (!this.id_card_num_birth_date || !this.date_password) {
                    EventBus.$emit('alert-warning', 'กรอกข้อมูลให้ครบถ้วน');
                    return;
                }
                const isValid = await this.$refs.form_2.validate();
                if (isValid) {
                    const axios = this.axios;
                    const response = await axios.post(`certificated/api/v1/get_by_id_card_num_birth_date/`, {
                        id_card_num: this.id_card_num_birth_date,
                        birth_date: this.date_password
                    });
                    this.card_certificated_status = false;
                    this.card_certificated_status = true;
                    this.certificated_status = response.data.data;
                    console.log(this.certificated_status);

                } else {
                    EventBus.$emit('alert-error', 'ตรวจสอบข้อมูลอีกครั้ง !');
                }
            } catch (error) {
                EventBus.$emit('alert-error', 'ไม่พบข้อมูล ! กรุณาลองใหม่อีกครั้ง');
                console.error('Error fetching data:', error);
            } finally {
                this.loading = false;
            }
        },
    }
}
</script>

<style>
.mx-auto {
    font-family: 'Noto Sans Thai', sans-serif;
}

.index-page {
    padding: 50px 80px 10px 80px;

}

.blue-card-header {
    background-color: #1976D2;
    color: white;
}

.pass-exam-person-input {
    font-family: 'Noto Sans Thai', sans-serif;
    font-size: 14px;
}

.pass-exam-person-input>.v-input__control>.v-input__slot {
    min-height: 31px !important;
}

.custom-card-text {
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
    margin-bottom: 10px;

}

.pd-pass-exam-birthday.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)>.v-input__control>.v-input__slot {
    padding-left: 10px !important;
}

.head-topic {
    font-weight: bold;
}

.background-image {
  background-image: url('https://intranet.inet.co.th/assets/upload/staff/noname.jpg');
  background-size: 180px;
  background-position: top;
  /* You can also set other background properties like background-size, background-repeat, etc. */
}
</style>