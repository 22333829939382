<template>
	<div>
		<!-- Toolbar -->
		<v-toolbar dense color="rgb(60,86,147)" class="toolbar-top">
			<v-toolbar-title class="toolbar-icon-name">
				<v-row no-gutters class="toolbar-row">
					<v-col cols="auto" md="auto" lg="auto" align-self="center" class="pr-3">
						<v-avatar size="35" color="#FFF9C4">
							<v-icon color="rgb(60,86,147)">mdi-city</v-icon>
						</v-avatar>
					</v-col>
					<v-col cols="10" md="" lg="" align-self="center">
						<v-row class="toolbar-row">
							<b class="toolbar-organization-name text-truncate">{{ training_organization_name }}</b>
						</v-row>
						<v-row class="mt-0 toolbar-row">
							<span class="toolbar-organization-province">{{ province }}</span>
						</v-row>
					</v-col>
				</v-row>
			</v-toolbar-title>
			<v-spacer></v-spacer>
			<v-tooltip bottom>
				<template v-slot:activator="{ on }">
					<v-btn icon color="#FFF9C4" v-on="on" class="toolbar-logout-btn" @click="logout">
						<v-icon>mdi-export</v-icon>
					</v-btn>
				</template>
				<span>Logout</span>
			</v-tooltip>
		</v-toolbar>

		<!-- Main Content -->
		<v-container fluid class="pa-0">
			<router-view />
		</v-container>
	</div>
</template>

<script>
export default {
	data: () => ({
		training_organization_name: '',
		province: ''
	}),
	mounted() {
		const {training_organization_name, province} = JSON.parse(sessionStorage.userProfile)
		this.training_organization_name = training_organization_name
		this.province = province
	},
	methods: {
		logout() {
			sessionStorage.clear()
			this.$apiConfig.setToken(null)
			this.$router.replace({ name: 'login' })
		}
	}
}
</script>

<style>
.toolbar-top {
	position: sticky;
	top: 0px;
	/* z-index: 1000; */
	z-index: 1;
}

.toolbar-icon-name {
	width: 100%;
}

.toolbar-row {
	width: 100%;
	margin: 0%;
}

.toolbar-organization-name {
	font-family: 'Noto Sans Thai', sans-serif;
	color: #FFF9C4;
	font-size: 18px;
}

.toolbar-organization-province {
	font-family: 'Noto Sans Thai', sans-serif;
	color: #FFF9C4;
	font-size: 13px;
	line-height: 17px;
}

.toolbar-logout-btn {
	height: 35px !important;
	width: 35px !important;
}

/*========================================*/

@media only screen and (max-width:600px) {

	/* css for mobile */
	.toolbar-organization-name {
		font-size: 15px;
	}
}
</style>