import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import axios from 'axios'
import VueAxios from 'vue-axios'
import './filters/dateFilters'
import { Api } from './api/axiosInstant'


const axiosService = axios.create({
  baseURL: process.env.VUE_APP_API_URL
});

Vue.config.productionTip = false;
Vue.use(VueSweetalert2);
Vue.use(VueAxios, axiosService)
Vue.use(VueSweetalert2);
Vue.prototype.$apiConfig = new Api(axiosService)

Vue.prototype.$api_url = 'http://203.154.140.79/api'

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
