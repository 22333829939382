<template>
  <v-container
    fluid
    class="main-container"
  >
    <v-overlay :value="loadingPage">
      <!-- page loading -->
      <v-progress-circular
        indeterminate
        color="#FFF9C4"
        :size="80"
        :width="7"
      ></v-progress-circular>
    </v-overlay>
    <v-row align="center">
      <v-col
        cols="auto"
        md="auto"
        lg="auto"
        class="py-2 px-3"
      >
        <v-btn
          depressed
          small
          fab
          color="#B39DDB"
          @click="goBack()"
        >
          <v-icon color="rgb(60,86,147)">mdi-chevron-double-left</v-icon>
        </v-btn>
      </v-col>
      <v-col
        cols="auto"
        class="mt-1 pl-4 py-2 main-text-container"
      >
        <b v-if="$vuetify.breakpoint.xs" class="success-form-header">ระบบออกประกาศนียบัตรฯ</b>
        <b v-else class="success-form-header">ระบบออกประกาศนียบัตรผู้ปฏิบัติการตามรอบ</b>
      </v-col>
    </v-row>
    <v-row class="filter-container">
      <v-text-field
        append-icon="mdi-magnify"
        placeholder="0"
        outlined
        height="40"
        rounded
        hide-details
        v-model="searchKeyword"
        class="text-filter-input"
        clearable
        prefix="ประกาศนียบัตรชุดที่"
        @click:append="
          getGenerateCertificated({ ...pageOption, keyword: searchKeyword })
        "
        @keyup.enter="
          getGenerateCertificated({ ...pageOption, keyword: searchKeyword })
        "
        @input="
          !searchKeyword &&
            getGenerateCertificated({ ...pageOption, keyword: null })
        "
      ></v-text-field>
      <div class="upload-file-container">
        <v-row
          align="center"
          class="pl-3"
          @click="openUploadFile = true"
        >
          <v-col class="upload-text text-truncate">
            {{ uploadFileName ?? "อับโหลดไฟล์เอกสาร" }}
          </v-col>
          <v-col cols="auto">
            <v-btn
              depressed
              elevation="0"
              height="40"
              color="rgb(60,86,147)"
              class="upload-file-btn"
              @click="openUploadFile = true"
            >
              <v-icon color="#fff"> mdi-upload</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-row>
    <v-row>
      <v-data-table
        :headers="headers"
        :items="itemList"
        fixed-header
        :options.sync="pageOption"
        :server-items-length="countCertificatedList"
        :footer-props="footerProps"
        class="data-table"
      >
        <template v-slot:[`item.regis_round`]="{ item }">
          <span>
            รายชื่อ{{ item.regis_round }}&nbsp;
            <span v-if="item.in_progress">
              <v-icon
                size="20"
                color="#8bc34a"
              >
                mdi-timer-sand
              </v-icon>
              <b class="light-green--text">&nbsp;(กำลังดำเนินการ)</b>
            </span>
          </span>
        </template>
        <template v-slot:[`item.action`]="{ item, index }">
          <v-row
            v-if="$vuetify.breakpoint.smAndUp"
            class="action-container"
          >
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  depressed
                  elevation="0"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon
                    color="#3c5693"
                    @click="detailCertificatePerRound(item._id)"
                  >
                    mdi-eye
                  </v-icon></v-btn
                >
              </template>
              <span class="tooltip-action-btn">ดูรายละเอียด</span>
            </v-tooltip>
            <v-tooltip top v-if="(!item['in_progress']) && (item['fail'] > 0 || item['waiting'] > 0)">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  depressed
                  elevation="0"
                  v-bind="attrs"
                  v-on="on"
                  @click="confirmModal(item, index, 'regenerate')"
                  :loading="item['regenerate_loading']"
                >
                  <v-icon color="#2ecc71"> mdi-refresh </v-icon></v-btn
                >
              </template>
              <span class="tooltip-action-btn">สร้างใหม่</span>
            </v-tooltip>
            <span v-else style="width:36px;"></span>
            <v-tooltip top v-if="(!item['in_progress']) && (item['success'] > 0)">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  depressed
                  elevation="0"
                  v-bind="attrs"
                  v-on="on"
                  @click="confirmModal(item, index, 'download')"
                  :loading="item['download_loading']"
                >
                  <v-icon color="#e74c3c"> mdi-download </v-icon></v-btn
                >
              </template>
              <span class="tooltip-action-btn">ดาวน์โหลด</span>
            </v-tooltip>
            <span v-else style="width:36px;"></span>
          </v-row>
          <v-menu
            bottom
            left
            min-width="auto"
            transition="scroll-y-transition"
            :close-on-content-click="false"
            content-class="list-action-menu"
            v-else
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                icon
                v-bind="attrs"
                v-on="on"
                :ripple="false"
                class="menu-action-btn"
              >
                <v-icon color="#999">mdi-menu</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                @click="detailCertificatePerRound(item._id)"
                class="px-4"
              >
                <v-list-item-icon class="mr-3 my-auto">
                  <v-icon>mdi-eye</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="list-text-action-menu"
                  >ดูรายละเอียด</v-list-item-title
                >
              </v-list-item>
              <v-list-item
                v-if="
                  !item['in_progress'] &&
                  (item['fail'] > 0 || item['waiting'] > 0)
                "
                @click="confirmModal(item, index, 'regenerate')"
                class="px-4"
              >
                <v-list-item-icon class="mr-3 my-auto">
                  <v-icon>mdi-refresh</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="list-text-action-menu"
                  >สร้างใหม่</v-list-item-title
                >
              </v-list-item>
              <v-list-item
                v-if="!item['in_progress'] && item['success'] > 0"
                @click="confirmModal(item, index, 'download')"
                class="px-4"
              >
                <v-list-item-icon class="mr-3 my-auto">
                  <v-icon>mdi-download</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="list-text-action-menu"
                  >ดาวน์โหลด</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template slot="no-data">
          <span> ไม่พบข้อมูล </span>
        </template>
      </v-data-table>
    </v-row>
    <UploadGenerateCertificatedModal
      :state="openUploadFile"
      @updateUploadGenCertificated="closeGenCertificatedModal"
    />
    <GenerateCertificatedTableModal
      :state="openDetailCertificated"
      :round="selectedRound"
      @updateDetailCertificated="closeDetailCertificatedModal"
    />
    <StaffDetailByRoundModal/>
  </v-container>
</template>

<script>
import _ from "lodash";
import UploadGenerateCertificatedModal from "../components/UploadGenerateCertificatedModal.vue";
import GenerateCertificatedTableModal from "../components/GenerateCertificatedTableModal.vue";
import StaffDetailByRoundModal from "../components/StaffDetailByRoundModal.vue";
export default {
  components: {
    UploadGenerateCertificatedModal,
    GenerateCertificatedTableModal,
    StaffDetailByRoundModal
  },
  data: () => ({
    loading: false,
    openUploadFile: false,
    openDetailCertificated: false,
    selectedRound: null,
    searchKeyword: null,
    headers: [
      { text: "", align: "start", sortable: false, value: "regis_round" },
      {
        text: "สำเร็จ",
        align: "center",
        sortable: false,
        value: "success",
      },
      {
        text: "ไม่สำเร็จ",
        align: "center",
        sortable: false,
        value: "fail",
      },
      {
        text: "รอการสร้างใหม่",
        align: "center",
        sortable: false,
        value: "waiting",
      },
      {
        text: "ทั้งหมด",
        align: "center",
        sortable: false,
        value: "sum",
      },
      {
        text: "ดำเนินการ",
        align: "center",
        sortable: false,
        value: "action",
      },
    ],
    itemList: [],
    holdItemList: [],
    pageOption: {
      page: 1,
      itemsPerPage: 100,
      keyword: null,
    },
    countCertificatedList: 0,
    footerProps: {
      "items-per-page-text": "จำนวนแถวต่อหน้า",
      "items-per-page-options": [100,200,300,500],
    },
    loadingPage: false,
    uploadFileName: null,
  }),
  watch: {
    pageOption: {
      handler: _.debounce(function (newValue, oldValue) {
        if (
          newValue["page"] === oldValue["page"] &&
          newValue["itemsPerPage"] === oldValue["itemsPerPage"] &&
          newValue["keyword"] === oldValue["keyword"]
        ) {
          return;
        }
        this.getGenerateCertificated(newValue);
      }, 100),
      deep: true,
    },
  },
  mounted() {
    this.getGenerateCertificated(this.pageOption);
  },
  methods: {
    goBack() {
      this.$router.back()
    },
    detailCertificatePerRound(round_id) {
      this.selectedRound = round_id;
      this.$nextTick(() => (this.openDetailCertificated = true));
    },
    closeGenCertificatedModal(data) {
      this.openUploadFile = false;
      if (Object.keys(data).length) {
        this.uploadFileName = data.name ?? null;
        this.getGenerateCertificated(this.pageOption, data.round_id);
      }
    },
    closeDetailCertificatedModal(isGenerate) {
      this.openDetailCertificated = false;
      if (isGenerate) {
        this.getGenerateCertificated(this.pageOption);
      }
    },
    async getGenerateCertificated(
      { keyword, page, itemsPerPage: limit } = {},
      loadingRound
    ) {
      this.loadingPage = true;
      try {
        const [itemCertificated, countItemCertificated] =
          await Promise.allSettled([
            this.axios.get("/register_round/api/v1/search_regis_round", {
              params: {
                offset: (page - 1) * limit,
                keyword,
                limit,
              },
            }),
            this.axios.get("/register_round/api/v1/count_regis_round_home", {
              params: {
                keyword,
              },
            }),
          ]);

        if (
          itemCertificated["status"] === "fulfilled" &&
          itemCertificated["value"]["data"]["status"]
        ) {
          this.itemList = itemCertificated["value"]["data"]["data"].map(
            (item) => {
              return {
                ...item,
                regenerate_loading: false,
                download_loading: false,
                // waiting: 1
              };
            }
          );
          if (loadingRound >= 0) {
            const tempIndex = this.itemList.findIndex(
              (item) => item._id == loadingRound
            );
            tempIndex >= 0 && (this.itemList[tempIndex].in_progress = true);
          }
        } else {
          throw new Error(itemCertificated["value"]["data"]["message"]);
        }

        if (
          countItemCertificated["status"] === "fulfilled" &&
          countItemCertificated["value"]["data"]["status"]
        ) {
          this.countCertificatedList =
            countItemCertificated["value"]["data"]["total"];
        } else {
          throw new Error(countItemCertificated["value"]["data"]["message"]);
        }

        const tableElement = document.getElementsByClassName("data-table")[0];
        tableElement.getElementsByClassName(
          "v-data-table__wrapper"
        )[0].scrollTop = 0;
      } catch (error) {
        this.itemList = [];
        this.countCertificatedList = 0;
        const errorMsg = error?.message ?? error;
        this.alertError("เกิดข้อผิดพลาด!<br />กรุณาลองใหม่อีกครั้ง");
        console.log(errorMsg);
      } finally {
        this.loadingPage = false;
      }
    },
    async reGenerateCertificate(item, index) {
      const { regis_round, _id, fail, in_progress, waiting } = item;
      if (index < 0 || in_progress || (fail <= 0 && waiting <= 0)) {
        return;
      }
      this.$set(this.itemList[index], "regenerate_loading", true);
      try {
        const { data } = await this.axios.post(
          `/register_round/api/v1/round_get_certificate`,
          {
            round: _id.toString(),
          }
        );
        if (data["status"]) {
          this.$set(this.itemList[index], "in_progress", true);
          this.getGenerateCertificated(this.pageOption, _id);
        } else {
          throw new Error();
        }
      } catch (error) {
        this.alertError(
          `สร้าง${regis_round}<br /><b>ไม่สำเร็จ!</b> กรุณาติดต่อเจ้าหน้าที่`
        );
        console.log(error);
      } finally {
        this.$set(this.itemList[index], "regenerate_loading", false);
      }
    },
    async downloadCertificatePerRound(item, index) {
      const { regis_round, _id } = item;
      if (index < 0) {
        return;
      }
      this.$set(this.itemList[index], "download_loading", true);
      try {
        const config = {
          url: `${process.env.VUE_APP_API_URL}/register_round/api/v1/download_certificate`,
          responseType: "blob",
          params: {
            round: _id.toString(),
          },
        };
        const data = await this.axios(config);
        if (data.data) {
          const url = window.URL.createObjectURL(data.data);
          const a = document.createElement("a");
          a.style = "display: none";
          a.href = url;
          a.download = `${regis_round}.zip`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          URL.revokeObjectURL(url);
        } else {
          throw new Error();
        }
      } catch (error) {
        this.alertError(
          `ดาวน์โหลด${regis_round}<br /><b>ไม่สำเร็จ!</b> กรุณาลองใหม่อีกครั้ง`
        );
        console.log(error);
      } finally {
        this.$set(this.itemList[index], "download_loading", false);
      }
    },
    confirmModal(item, index, action) {
      const { regis_round } = item;

      let titleText;
      if (action === "regenerate") {
        titleText = `คุณต้องการสร้าง<br />${regis_round} ใหม่หรือไม่?`;
      } else {
        titleText = `คุณต้องการดาวน์โหลดไฟล์<br />${regis_round} หรือไม่?`;
      }

      this.$swal({
        title: titleText,
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ปิด",
        width: "363px",
        customClass: {
          title: "sweet-confirm-text",
          popup: "sweet-confirm-card",
          confirmButton: "sweet-confirm-btn",
          cancelButton: "sweet-confirm-cancel-btn",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          if (action === "regenerate") {
            this.reGenerateCertificate(item, index);
          } else {
            this.downloadCertificatePerRound(item, index);
          }
        }
      });
    },
    alertError(html = "", timer = 4000) {
      this.$swal({
        icon: "error",
        html,
        showCloseButton: true,
        showConfirmButton: false,
        timer: timer,
        customClass: {
          htmlContainer: "alert-text",
        },
      });
    },
  },
};
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Noto Sans Thai", sans-serif !important;
}

.row {
  padding: 0;
  margin: 0;
  width: 100% !important;
  box-sizing: border-box !important;
}

.main-container {
  height: 100%;
  padding-top: 12px;
  padding-bottom: 28px;
}

.main-text-container {
  background: #b39ddb;
  border-radius: 32px;
  height: fit-content;
  margin-top: 4px;
  padding: 8px 12px 8px 16px;
}

.success-form-header {
  color: #3c5693;
}

.filter-container {
  display: grid;
  justify-content: space-between;
  grid-template-columns: 0.3fr 0.3fr;
  padding: 0 54px;
  margin-top: 52px;
}

.text-filter-input >>> .v-input__control > .v-input__slot {
  min-height: auto;
}

.text-filter-input
  >>> .v-input__control
  > .v-input__slot
  > .v-text-field__slot
  > .v-label {
  top: 50%;
  transform: translateY(-50%);
}

.text-filter-input
  >>> .v-input__control
  > .v-input__slot
  > .v-input__append-inner {
  margin: 0;
  height: 100%;
  display: flex;
  align-items: center;
}

.upload-text {
  color: #d1d1d1;
}

.upload-file-container {
  display: flex;
  border: 1px solid #d1d1d1;
  border-radius: 30px;
}

.upload-file-btn {
  border-radius: 0 30px 30px 0;
}

.data-table {
  width: 100%;
  box-shadow: none;
  padding: 0 54px;
  margin-top: 38px;
}

.data-table >>> .v-data-table__wrapper {
  height: calc(100vh - 334.4px);
  scroll-behavior: smooth;
  overflow: auto;
}

.data-table
  >>> .v-data-table__wrapper
  > table
  > .v-data-table-header
  > tr
  > th {
  background-color: #f0f2f5;
  font-weight: 700 !important;
  font-size: 15px;
}

.data-table >>> .v-data-table__wrapper > table > tbody > tr > td {
  font-weight: 700;
  font-size: 13px;
}

.data-table >>> .v-data-table__wrapper > table > tbody > tr:hover {
  background-color: #f0f2f5 !important;
}

.data-table
  >>> .v-data-table__wrapper
  > table
  > .v-data-table-header
  > tr
  > th:first-child {
  border-radius: 10px 0 0 0;
}

.data-table
  >>> .v-data-table__wrapper
  > table
  > .v-data-table-header
  > tr
  > th:last-child {
  border-radius: 0 10px 0 0;
}

.data-table
  >>> .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td:not(:first-child):not(:last-child) {
  width: 125px;
}

.data-table >>> .v-data-table__wrapper > table > tbody > tr > td:last-child {
  min-width: 165px;
}

.action-container {
  justify-content: center;
  gap: 12px;
}

.tooltip-action-btn {
  font-family: "Noto Sans Thai", sans-serif !important;
  padding: 0 12px;
  font-size: 12px !important;
}

@media only screen and (max-width: 75em) {
  .data-table
    >>> .v-data-table__wrapper
    > table
    > tbody
    > tr
    > td:not(:first-child):not(:last-child) {
    width: unset;
  }

  .action-container {
    justify-content: center;
    gap: 12px;
  }
}

@media only screen and (max-width: 60em) {
  .filter-container {
    grid-template-columns: 0.5fr 0.5fr;
    column-gap: 20px;
  }
}

@media only screen and (max-width: 37.5em) {
  .main-text-container {
    border-radius: 25px;
    padding-right: 2% !important;
  }

  .filter-container {
    padding: 0 20px;
    grid-template-columns: 1fr;
    row-gap: 20px;
    margin-top: 38px;
  }

  .data-table {
    padding: 0 20px;
  }

  .data-table >>> .v-data-table__wrapper {
    border: 1px solid #d1d1d1;
    border-radius: 15px;
    height: calc(100vh - 289.6px);
  }

  .data-table >>> .v-data-table__wrapper > table > tbody > tr:nth-child(odd) {
    background-color: #f0f2f5;
  }

  .data-table >>> .v-data-table__wrapper > table > tbody > tr > td {
    min-height: 32px;
    font-size: 12px;
  }

  .data-table >>> .v-data-table__wrapper > table > tbody > tr > td:first-child {
    padding-top: 16px;
  }

  .data-table >>> .v-data-table__wrapper > table > tbody > tr > td:last-child {
    padding-bottom: 16px;
  }

  .data-table
    >>> .v-data-table__wrapper
    > table
    > tbody
    > .v-data-table__empty-wrapper {
    background-color: #fff !important;
    display: flex;
    justify-content: center;
  }

  .data-table
    >>> .v-data-table__wrapper
    > table
    > tbody
    > tr:hover:nth-child(even) {
    background-color: #fff !important;
  }

  .data-table >>> .v-data-footer {
    margin-right: 0;
    justify-content: end;
  }

  .data-table >>> .v-data-footer > .v-data-footer__select {
    display: none;
  }

  .menu-action-btn >>> .v-btn__content {
    justify-content: end;
  }

  .list-action-menu {
    border-radius: 24px;
  }

  .list-text-action-menu {
    font-size: 14px;
    color: #000;
  }
}
</style>
