<template>
    <v-dialog 
        v-if="dialog"
        v-model="dialog"
        max-width="800"
        persistent
        scrollable
        content-class="elevation-0 dialog-container"
    >
        <v-card fluid class="container">
            <v-card-title class="tab-header">
                <v-row no-gutters align="center">
                    <v-col class="pa-0">
                        <v-icon color="#FFF9C4" size="32">
                            mdi-information-variant-circle-outline
                        </v-icon>
                        <b class="ml-1 text-center text-header">
                            รายละเอียดเจ้าหน้าที่
                        </b>
                    </v-col>
                    <v-col
                        cols="auto"
                        sm="auto"
                        md="auto"
                        class="pa-0"
                    >
                        <v-btn
                            depressed
                            icon
                            class="close-button"
                            color="#FFF9C4"
                            @click="dialog = false"
                        >
                            <v-icon size="32">
                                mdi-close
                            </v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text class="card-text-container">
                <!-- <v-row no-gutters justify="center" class="pt-4">
                    <v-avatar
                        color="#e0e0e0"
                        size="180"
                        rounded="lg"
                    >
                        <v-img
                            v-if="false"
                            src="https://statusneo.com/wp-content/uploads/2023/02/MicrosoftTeams-image551ad57e01403f080a9df51975ac40b6efba82553c323a742b42b1c71c1e45f1.jpg"
                        ></v-img>
                        <v-icon v-if="false" size="150" color="#fff">
                            mdi-account-outline
                        </v-icon>
                    </v-avatar>
                </v-row> -->
                <v-row no-gutters class="detail-data-container">
                    <v-col 
                        cols="12"
                        sm="6"
                        md="6"
                        align-self="center"
                    >
                        <v-text-field
                            label="ชื่อ-นามสกุล"
                            color="rgb(60, 86, 147)"
                            hide-details
                            readonly
                            prepend-icon="mdi-account-outline"
                            class="input-text"
                            v-model="detailData.name"
                        ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        align-self="center"
                    >
                        <v-text-field
                            label="เลขบัตรประชาชน"
                            prepend-icon="mdi-card-account-details-outline"
                            color="rgb(60, 86, 147)"
                            hide-details
                            readonly
                            class="input-text"
                            v-model="detailData.id_card_num"
                        ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        align-self="center"
                    >
                        <v-text-field
                            label="เกิดวันที่"
                            prepend-icon="mdi-cake-variant-outline"
                            color="rgb(60, 86, 147)"
                            hide-details
                            readonly
                            class="input-text"
                            :value="detailData.birth_date | shortdate"
                        ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        align-self="center"
                    >
                        <v-text-field
                            label="วุฒิการศึกษา"
                            prepend-icon="mdi-school-outline"
                            color="rgb(60, 86, 147)"
                            hide-details
                            readonly
                            class="input-text"
                            v-model="detailData.education_level"
                        ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        align-self="center"
                    >
                        <v-text-field
                            label="เบอร์โทรติดต่อ"
                            prepend-icon="mdi-phone-outline"
                            color="rgb(60, 86, 147)"
                            hide-details
                            readonly
                            class="input-text"
                            v-model="detailData.tel_no"
                        ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        align-self="center"
                    >
                        <v-text-field
                            label="Line ID"
                            prepend-icon="mdi-chat-outline"
                            color="rgb(60, 86, 147)"
                            hide-details
                            readonly
                            class="input-text"
                            v-model="detailData.line_id"
                        ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="3"
                        md="3"
                        align-self="center"
                    >
                        <v-text-field
                            label="รุ่นที่อบรม"
                            prepend-icon="mdi-counter"
                            color="rgb(60, 86, 147)"
                            hide-details
                            readonly
                            class="input-text"
                            v-model="detailData.generation"
                        ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="3"
                        md="3"
                        align-self="center"
                    >
                        <v-text-field
                            label="ปีที่อบรม"
                            prepend-icon="mdi-calendar-account-outline"
                            color="rgb(60, 86, 147)"
                            hide-details
                            readonly
                            class="input-text"
                            v-model="detailData.year_generation"
                        ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="3"
                        md="3"
                        align-self="center"
                    >
                        <v-text-field
                            label="วันที่เริ่มอบรม"
                            prepend-icon="mdi-calendar"
                            color="rgb(60, 86, 147)"
                            hide-details
                            readonly
                            class="input-text"
                            :value="detailData.start_date | shortdate"
                        ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="3"
                        md="3"
                        align-self="center"
                    >
                        <v-text-field
                            label="วันที่สิ้นสุด"
                            prepend-icon="mdi-calendar"
                            color="rgb(60, 86, 147)"
                            hide-details
                            readonly
                            class="input-text"
                            :value="detailData.end_date | shortdate"
                        ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        align-self="center"
                    >
                        <v-text-field
                            label="หลักสูตร"
                            prepend-icon="mdi-certificate-outline"
                            color="rgb(60, 86, 147)"
                            hide-details
                            readonly
                            class="input-text"
                            v-model="detailData.course_selected"
                        ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        md="3"
                        align-self="center"
                    >
                        <v-text-field
                            label="จังหวัดต้นสังกัด"
                            prepend-icon="mdi-home-city-outline"
                            color="rgb(60, 86, 147)"
                            hide-details
                            readonly
                            class="input-text"
                            v-model="detailData.province"
                        ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        md="9"
                        align-self="center"
                    >
                        <v-text-field
                            label="หน่วยงานต้นสังกัด"
                            prepend-icon="mdi-hospital-building"
                            color="rgb(60, 86, 147)"
                            hide-details
                            readonly
                            class="input-text"
                            v-model="detailData.medical_operation_name"
                        ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        align-self="center"
                    >
                        <v-text-field
                            label="คำอธิบาย"
                            prepend-icon="mdi-alert-circle-outline"
                            color="rgb(60, 86, 147)"
                            hide-details
                            readonly
                            class="input-text"
                            v-model="detailData.description"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions></v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { EventBus } from '../EventBus';
    export default {
        data: () => ({
            dialog: false,
            detailData: {}
        }),
        mounted() {
            EventBus.$on('openDetailRegisSystemLogModal', this.openDialog);
        },
        beforeDestroy() {
            EventBus.$off('openDetailRegisSystemLogModal');
        },
        methods: {
            openDialog(data) {
                this.dialog = true;
                this.detailData = data;
            }
        }
    }
</script>

<style scoped>
    .row {
        width: 100%;
        margin: 0px;
    }

    .container {
        padding: 0;
        border-radius: 18px;
        font-family: "Noto Sans Thai", sans-serif;
        
        /* height: calc(100vh - 96px);
        display: flex !important;
        flex-direction: column; */
    }

    .tab-header {
        padding: 8px 12px !important;
        color: #FFF9C4;
        background-color: rgb(60, 86, 147);
    }

    .card-text-container {
        /* flex-grow: 1;
        overflow: auto; */

        padding: 24px;
    }

    .detail-data-container {
        padding-top: 14px;
    }

    .input-text {
        padding: 14px 8px;
        margin-top: 0;
    }

    .input-text >>> .v-input__control > .v-input__slot > .v-text-field__slot > input {
        line-height: 30px;
    }

    @media only screen and (max-width: 37.5rem) {
        .text-header {
            font-size: 22px;
        }

        .card-text-container {
            padding: 12px !important;
        }
    }

    @media only screen and (max-width: 25rem) {
        .text-header {
            font-size: 18px;
        }
    }
</style>