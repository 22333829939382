<template>
  <v-container style="max-width: none">
    <v-col
      cols="auto"
      sm="auto"
      md="auto"
      lg="auto"
      class="mt-1 px-4 py-2 upload-image-header-block text-center"
    >
      <b class="upload-image-header" color="#3495CF"
        >ระบบฝากรูปภาพผู้ปฏิบัติการเพื่อขอรับประกาศนียบัตร</b
      >
    </v-col>
    <v-form ref="form_1" lazy-validation>
      <v-card class="py-5 my-5" style="width: auto">
        <div class="warning-text-header text-center">
          กรุณาอัพโหลดรูปถ่ายหน้าตรง ไม่สวมหมวก ไม่สวมแว่นกันแดด
          และพื้นหลังไม่มีลวดลาย
        </div>
        <v-row class="mt-3 upload-image-row upload-image-namelist-block">
          <v-col
            v-for="(item, index) in list_trained"
            cols="12"
            md="4"
            lg="3"
            class="pl-0 pt-0 upload-image-person-block"
            :key="`list_upload_image_${index}`"
            :id="`list_upload_image_${index}`"
          >
            <!-- each person -->
            <v-card color="#eaf4ff">
              <v-card-title class="px-3 pt-1 pb-0 upload-image-people-header">
                <v-row align="center" class="upload-image-row">
                  <b class="upload-image-num-people"
                    >ลำดับที่ {{ index + 1 }}</b
                  >
                  <v-spacer></v-spacer>
                  <v-btn
                    icon
                    x-small
                    color="white"
                    @click="removeTrained(index)"
                  >
                    <!-- delete person button -->
                    <v-icon>mdi-window-close</v-icon>
                  </v-btn>
                </v-row>
              </v-card-title>
              <v-card-text class="px-3 pb-4 pt-3">
                <v-row
                  v-if="item.error_text"
                  class="upload-image-row"
                  style="color: red; font-family: 'Noto Sans Thai', sans-serif"
                >
                  {{ item.error_text }}
                </v-row>
                <v-row
                  v-if="item.success_text"
                  class="upload-image-row"
                  style="
                    color: green;
                    font-family: 'Noto Sans Thai', sans-serif;
                  "
                >
                  {{ item.success_text }}
                </v-row>
                <v-row class="upload-image-row">
                  <v-col
                    cols="auto"
                    md="auto"
                    lg="auto"
                    align-self="center"
                    class="pl-0 pt-0 pb-2 pr-2 upload-image-person-title"
                  >
                    ชื่อ - สกุล :
                  </v-col>
                  <v-col cols="12" md="" lg="" class="px-0 pt-0 pb-2">
                    <v-text-field
                      dense
                      outlined
                      hide-details
                      background-color="white"
                      class="upload-image-person-input"
                      v-model="item.name"
                      :rules="rules.required"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="mt-0 upload-image-row">
                  <v-col
                    cols="auto"
                    md="auto"
                    lg="auto"
                    align-self="center"
                    class="pl-0 pt-0 pb-2 pr-2 upload-image-person-title"
                  >
                    เลขที่บัตรประชาชน :
                  </v-col>
                  <v-col cols="12" md="auto" lg="auto" class="px-0 pt-0 pb-2">
                    <v-text-field
                      dense
                      outlined
                      hide-details
                      background-color="white"
                      class="upload-image-person-input"
                      v-model="item.id_card_num"
                      :onkeypress="`return ((event.charCode >= 48 && event.charCode <= 57) && &quot;${item.id_card_num}&quot;.length<13)`"
                      :rules="[
                        ...rules.required,
                        ...rules.number,
                        ...rules.textLength13,
                      ]"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="mt-0 upload-image-row">
                  <v-col
                    cols="auto"
                    md="auto"
                    lg="auto"
                    align-self="center"
                    class="pl-0 pt-0 pb-1 pr-2 upload-image-person-title"
                  >
                    รูปภาพผู้ปฏิบัติการ
                    <!-- <span style="color:red"> 
                                        (กรุณาใช้รูปหน้าตรงและพื้นหลังสีขาวหรือน้ำเงิน) 
                                    </span> : -->
                  </v-col>
                  <v-col cols="12" md="12" lg="12" class="px-0 pt-0 pb-0">
                    <v-file-input
                      outlined
                      dense
                      hide-details
                      background-color="white"
                      prepend-icon=""
                      @change="validateFileType(index)"
                      prepend-inner-icon="$file"
                      :accept="acceptedFormats"
                      placeholder="กรุณาเลือกไฟล์รูปภาพ"
                      :rules="rules.required"
                      class="upload-image-person-input icon-input-upload-image pd-icon-input-upload-image pd-upload-image-icon-close"
                      v-model="item.photo"
                      ref="fileInput"
                    ></v-file-input>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <img v-if="images[index]" :src="images[index]"  style="display: block; margin: 0 auto; max-width: 180px; max-height: 180px;">
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col
            cols="12"
            sm="12"
            md="4"
            lg="3"
            class="pl-0 pt-0 upload-image-person-block"
          >
            <v-btn
              block
              outlined
              x-large
              color="#d1d1d1"
              class="upload-image-add-btn"
              @click="addTrained"
            >
              <!-- add person button -->
              <v-avatar size="100" class="upload-image-add-icon">+</v-avatar>
            </v-btn>
          </v-col>
        </v-row>
        <v-row justify="center" class="upload-image-row">
          <v-btn
            depressed
            large
            color="#91CBA2"
            class="px-12 upload-image-submit-btn"
            @click="validateFn"
          >
            <b>Submit</b>
          </v-btn>
        </v-row>
      </v-card>
      <v-card>
        <v-col cols="auto" md="12" lg="12">
          <v-card-title
            class="ma-0"
            style="
              color: white;
              font-family: 'Noto Sans Thai', sans-serif;
              font-size: 20px;
              background: #b39ddb;
            "
            >Link ดาวน์โหลดรูปภาพผู้ปฏิบัติการ:</v-card-title
          >
          <v-card-text
            class="pt-5 ma-0"
            style="font-family: 'Noto Sans Thai', sans-serif; font-size: 16px"
            >path:
            http://203.154.140.79/api/public/api/v1/showimage_public/{หมายเลขบัตรประชาชน}</v-card-text
          >
          <v-card-text
            class="ma-0"
            style="font-family: 'Noto Sans Thai', sans-serif; font-size: 16px"
            >เช่น
            http://203.154.140.79/api/public/api/v1/showimage_public/1234567890123</v-card-text
          >
        </v-col>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
import { EventBus } from "../EventBus";
export default {
  data: () => ({
    firstName: "",
    lastName: "",
    cardID: "",
    loading: false,
    rules: {
      required: [(v) => !!v || "Name is required"],
      number: [
        (v) => (!isNaN(v) && /^[0-9]*$/.test(v)) || "ต้องเป็นตัวเลขเท่านั้น",
      ],
      numberUpperZero: [(v) => parseInt(v) > 0 || "ต้องมีค่ามากกว่า 0"],
      numberInteger: [(v) => Number(v) % 1 == 0 || "ต้องเป็นจำนวนเต็มเท่านั้น"],
      textLength13: [(v) => (v && v.length == 13) || "ต้องมี 13 หลัก"],
    },
    list_trained: [
      {
        name: "",
        id_card_num: "",
        photo: null,
      },
    ],
    acceptedFormats: '.jpeg,.jpg,.png',
    images: []
  }),
  mounted() {},
  methods: {
    async submit() {
      this.loading = true;
      await this.sendData();
    },
    removeTrained(index) {
      this.list_trained.splice(index, 1);
    },
    addTrained() {
      this.list_trained.push({
        name: "",
        id_card_num: "",
        // age: '20',
        // education_level: '',
        // province:'',
        // medical_operation_name: '',
        // birth_date:new Date(new Date().setFullYear(new Date().getFullYear() - 20)).toISOString().substr(0, 10),
        // line_id: '',
        // tel_no: '',
        photo: null,
      });
    },
    validateFn() {
      setTimeout(() => {
        if (this.list_trained.length <= 0) {
          return EventBus.$emit(
            "alert-warning",
            "จำเป็นต้องมีรายชื่ออย่างน้อย 1 คน"
          );
        }
        if (!this.$refs.form_1.validate()) {
          let valid = true;
          let contentError = null;
          let message = "กรุณากรอกข้อมูลให้ถูกต้องและครบถ้วน";
          if (valid) {
            valid = this.list_trained.every((item, index) => {
              if (
                !item.name ||
                !item.id_card_num ||
                !/^\d+$/.test(item.id_card_num) ||
                item.id_card_num.length != 13 ||
                !item.photo
              ) {
                contentError = `list_upload_image_${index}`;
                if (!item.name || !item.id_card_num)
                  message += ` (ลำดับที่ ${index + 1})`;
                else if (!/^\d+$/.test(item.id_card_num))
                  message = `กรุณากรอกเลขบัตรประชาชนเป็นตัวเลขเท่านั้น (ลำดับที่ ${
                    index + 1
                  })`;
                else if (item.id_card_num.length != 13)
                  message = `กรุณากรอกเลขบัตรประชาชน 13 หลักเท่านั้น (ลำดับที่ ${
                    index + 1
                  })`;
                // else if (isNaN(item.age)) message = `กรุณากรอกอายุเป็นตัวเลขเท่านั้น (ลำดับที่ ${index + 1})`
                // else if (item.age <= 0) message = `กรุณากรอกอายุให้ถูกต้อง (ลำดับที่ ${index + 1})`
                // else if (Number(item.age)%1 != 0) message = `กรุณากรอกอายุเป็นจำนวนเต็มเท่านั้น (ลำดับที่ ${index + 1})`
                else if (!item.photo)
                  message = `กรุณาเพิ่มรูปภาพ (ลำดับที่ ${index + 1})`;
                else message += ` (ลำดับที่ ${index + 1})`;
                return false;
              } else return true;
            });
          }
          if (!valid) {
            const element = document.getElementById(contentError);
            const yOffset = -70;
            const y =
              element.getBoundingClientRect().top +
              window.pageYOffset +
              yOffset;
            window.scrollTo({ top: y });
            EventBus.$emit("alert-warning", message);
          }
        } else {
          this.confirmSubmit();
        }
      }, 100);
    },
    confirmSubmit() {
      this.$swal({
        title: "คุณต้องการบันทึกหรือไม่ ?",
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: "บันทึก",
        cancelButtonText: "ปิด",
        width: "363px",
        customClass: {
          title: "sweet-confirm-text",
          popup: "sweet-confirm-card",
          confirmButton: "sweet-confirm-btn",
          cancelButton: "sweet-confirm-cancel-btn",
        },
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.sendData();
        } else if (result.isDenied) {
          // Swal.fire('Changes are not saved', '', 'info')
        }
      });
    },
    async sendData() {
      try {
        this.loading = true;
        let formdata = new FormData();
        JSON.parse(JSON.stringify(this.list_trained)).forEach((item, index) => {
          let obj = item;
          delete obj.photo;
          formdata.append(`register_data[]`, JSON.stringify(obj));
          formdata.append(`file`, this.list_trained[index].photo);
        });
        const config = {
          baseURL: process.env.VUE_APP_API_URL,
          url: "/public/api/v1/save_image",
          method: "POST",
          data: formdata,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
        const { data } = await this.axios(config);
        if (data.status) {
          var result = data.data.every((el) => {
            return el.status == true;
          });
          if (result == false) {
            data.data.forEach((el, index) => {
              if (
                el.status == false &&
                el.message ==
                  "First name and last name does not match the old data in the database"
              ) {
                this.list_trained[index].error_text =
                  "ข้อมูลชื่อ-นามสกุล และเลขบัตรประชาชนไม่ตรงกันกับข้อมูลในระบบ";
              } else {
                this.list_trained[index].success_text =
                  "ลงทะเบียนเข้าสู่ระบบเรียบร้อยแล้ว";
              }
            });
            EventBus.$emit("alert-error", "บันทึกข้อมูลไม่สำเร็จ");
          } else {
            EventBus.$emit("alert-success", "บันทึกสำเร็จ");
            this.list_trained = [];
            this.images = []
            this.$nextTick(() => {
              this.$refs.form_1.resetValidation();
            });
          }
        }
      } catch (error) {
        EventBus.$emit(
          "alert-error",
          "เกิดข้อผิดพลาดในการบันทึก" + "\n" + JSON.stringify(error),
          0
        );
        console.log(error);
      } finally {
        this.loading = false;
        if (!this.list_trained.length) {
          this.addTrained();
        }
      }
    },
    validateFileType(index) { // No file selected
      var upload_file = this.list_trained[index].photo;
      if (!upload_file) {
        this.images[index] = null
        return;
      }
      const fileType = upload_file.type;
      const allowedFormats = ['image/jpeg', 'image/jpg', 'image/png'];
      if (!allowedFormats.includes(fileType)) {
        this.$refs.fileInput[index].reset()
        this.list_trained[index].photo = null;
        EventBus.$emit(
          "alert-error",
          "กรุณาเลือกรูปภาพ นามสกุล .jpg .jpeg .png เท่านั้น" + "\n",
          0
        );
      }
      if(this.list_trained[index].photo) this.images[index] = URL.createObjectURL(upload_file);
    },
  },
};
</script>
<style>
.pd-upload-image-icon-close.v-text-field--enclosed.v-input--dense:not(
    .v-text-field--solo
  ).v-text-field--outlined
  .v-input__append-inner {
  margin-top: 5px !important;
}
.pd-upload-image-birthday.v-text-field.v-text-field--enclosed:not(
    .v-text-field--rounded
  )
  > .v-input__control
  > .v-input__slot {
  padding-left: 4px !important;
}
.pd-icon-input-upload-image.v-text-field.v-text-field--enclosed:not(
    .v-text-field--rounded
  )
  > .v-input__control
  > .v-input__slot {
  padding-left: 0px !important;
}
.icon-input-upload-image.v-text-field--enclosed.v-input--dense:not(
    .v-text-field--solo
  ).v-text-field--outlined
  .v-input__prepend-inner {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}
.upload-image-row {
  width: 100%;
  margin: 0%;
}

.upload-image-header-row {
  margin-top: 12px;
}

.upload-image-header-block {
  background: rgb(255, 229, 127);
  border-radius: 32px;
  height: fit-content;
}

.upload-image-header {
  font-family: "Noto Sans Thai", sans-serif;
  color: #3495cf;
  font-size: 22px;
}

.warning-text-header {
  font-family: "Noto Sans Thai", sans-serif;
  color: red;
  font-size: 20px;
}

.upload-image-export-btn {
  font-family: "Noto Sans Thai", sans-serif;
  text-transform: capitalize;
  font-size: 16px !important;
  color: #3495cf !important;
}

.upload-image-head-title {
  font-family: "Noto Sans Thai", sans-serif;
  font-size: 16px;
  padding-left: 4% !important;
}

.upload-image-input {
  font-family: "Noto Sans Thai", sans-serif;
  font-size: 15px;
}

.upload-image-dropdown-box.v-text-field--rounded
  > .v-input__control
  > .v-input__slot {
  padding-left: 2% !important;
  padding-right: 1% !important;
}

.upload-image-inputbox.v-text-field--rounded
  > .v-input__control
  > .v-input__slot {
  padding-left: 10px !important;
  padding-right: 3% !important;
}

.upload-image-year-title {
  font-family: "Noto Sans Thai", sans-serif;
  font-size: 16px;
  padding-left: 2% !important;
}

.upload-image-start-title {
  font-family: "Noto Sans Thai", sans-serif;
  font-size: 16px;
  padding-left: 4% !important;
}

.upload-image-start-input
  > .v-input__control
  > .v-input__slot
  > .v-text-field__slot
  > label {
  left: 20px !important;
}

.upload-image-calendar {
  font-family: "Noto Sans Thai", sans-serif;
}

.upload-image-end-title {
  font-family: "Noto Sans Thai", sans-serif;
  font-size: 16px;
}

.upload-image-name-header {
  font-family: "Noto Sans Thai", sans-serif;
  font-size: 16px;
  padding-left: 4%;
  font-weight: bold;
  color: rgb(60, 86, 147);
}

.upload-image-namelist-block {
  padding-left: 4%;
}

.upload-image-person-block {
  padding-right: 2% !important;
  padding-bottom: 2% !important;
}

.upload-image-people-header {
  background: rgb(60, 86, 147);
}

.upload-image-num-people {
  font-family: "Noto Sans Thai", sans-serif;
  font-size: 16px;
  color: white;
}

.upload-image-person-title {
  font-family: "Noto Sans Thai", sans-serif;
  font-size: 15px;
  color: black;
}

.upload-image-person-input {
  font-family: "Noto Sans Thai", sans-serif;
  font-size: 14px;
}

.upload-image-person-input > .v-input__control > .v-input__slot {
  min-height: 31px !important;
}

.upload-image-person-dropdown.v-text-field--enclosed.v-input--dense:not(
    .v-text-field--solo
  ).v-text-field--outlined
  .v-input__append-inner {
  margin-top: 4px !important;
}

.upload-image-person-dropdown.v-text-field.v-text-field--enclosed:not(
    .v-text-field--rounded
  )
  > .v-input__control
  > .v-input__slot {
  padding-left: 3% !important;
  padding-right: 1% !important;
}

.upload-image-person-province .v-input__icon {
  min-width: 15px !important;
  width: 15px !important;
}

.upload-image-add-btn {
  border: 4px dashed;
  height: 230px !important;
}

.upload-image-add-btn:hover {
  background-color: #efefef !important;
}

.upload-image-add-icon {
  font-size: 76px;
  font-weight: 200;
  border: 2px solid #d1d1d1 !important;
}

.upload-image-submit-btn {
  font-family: "Noto Sans Thai", sans-serif;
  text-transform: capitalize;
  color: white !important;
  font-size: 18px !important;
}

.format-pre pre {
  padding: 10px 0 0 2%;
  font-family: "Noto Sans Thai", sans-serif;
  font-size: 17px;
  justify-items: start;
  display: flex;
  text-align: start;
}

.format-pre p {
  padding: 10px 0 0 2%;
  font-family: "Noto Sans Thai", sans-serif;
  font-size: 15px;
  justify-items: start;
  display: flex;
  text-align: start;
  color: red;
}

.format-title {
  font-family: "Noto Sans Thai", sans-serif;
  color: black !important;
  font-size: 21px;
  padding-bottom: 0;
  padding-top: 30px;
}

.text-divider {
  display: flex;
  align-items: center;
  --text-divider-gap: 1rem;
}

.text-divider::before,
.text-divider::after {
  content: "";
  height: 1px;
  background-color: silver;
  flex-grow: 1;
}

.text-divider::before {
  margin-right: var(--text-divider-gap);
}

.text-divider::after {
  margin-left: var(--text-divider-gap);
}
/*========================================*/

@media only screen and (max-width: 600px) {
  /* css for mobile */
  .upload-image-header-row {
    margin-top: 6px;
  }

  .upload-image-header-block {
    border-radius: 25px;
  }

  .upload-image-header {
    font-size: 17px;
  }

  .warning-text-header {
    font-size: 16px;
  }

  .upload-image-year-title {
    padding-left: 4% !important;
  }

  .upload-image-person-block {
    padding-right: 5% !important;
    padding-bottom: 6% !important;
  }

  .upload-image-add-btn {
    height: 161px !important;
  }
}
</style>