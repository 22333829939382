import Vue from "vue";
import VueRouter from "vue-router";
import Login from '../views/Login.vue'
import Toolbar from '../views/Toolbar.vue'
import SelectRegis from '../views/SelectRegis.vue'
import PassExamForm from '../views/PassExamForm.vue'
import SuccessTrainingForm from '../views/SuccessTrainingForm.vue';
import staff_list from '../views/staff_list.vue'
import courses from '../views/courses.vue'
import courses_detail from '../views/courses_detail.vue'
import PassExamList from "../views/PassExamList.vue";
import SuccessTrainingList from "../views/SuccessTrainingList.vue";
import CertificatedStatus from "../views/certificated_status.vue";
import SaveLogRegisterList from "../views/SaveLogRegisterList.vue";
import UploadImage from "../views/UploadImage.vue";
import GenerateCertificated from "../views/GenerateCertificated.vue"
import SearchCertificated from "../views/searchCertificated.vue"
import { EventBus } from '../EventBus'
import AddCertificateForm from "../views/AddCertificateForm.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/search_certificated",
    name: "search_certificated",
    component: SearchCertificated,
  },
  {
    path: "/certificated_status",
    name: "certificated_status",
    component: CertificatedStatus,
  },
  {
    path: "/upload_image",
    name: "UploadImage",
    component: UploadImage,
  },
  {
    path: '',
    name: 'toobar',
    component: Toolbar,
    redirect: "/login",
    children: [
      {
        path: "/registration",
        name: "select_regis",
        component: SelectRegis,
        meta: { requiresAuth: true },
      },
      {
        path: "/registration/get_cert_form",
        name: "pass_exam_form",
        component: PassExamForm,
        meta: { requiresAuth: true },
      },
      {
        path: "/registration/guarantee_form",
        name: "success_training_form",
        component: SuccessTrainingForm,
        meta: { requiresAuth: true },
      },
      {
        path: "/staff_list",
        name: "staff_list",
        component: staff_list,
        meta: { requiresAuth: true },
      },
      {
        path: "/courses",
        name: "courses",
        component: courses,
        meta: { requiresAuth: true },
      },
      {
        path: "/courses_detail/:courseId",
        name: "courses_detail",
        component: courses_detail,
        meta: { requiresAuth: true },
      },
      {
        path: "/pass_exam_list",
        name: "pass_exam_list",
        component: PassExamList,
        meta: { requiresAuth: true },
      },
      {
        path: "/success_training_list",
        name: "success_training_list",
        component: SuccessTrainingList,
        meta: { requiresAuth: true },
      },
      {
        path: "/save_log_register_list",
        name: "save_log_register_list",
        component: SaveLogRegisterList,
        meta: { requiresAuth: true }
      },
      {
        path: "/generate_certificated",
        name: "generate_certificated",
        component: GenerateCertificated,
        meta: { requiresAuth: true }
      },
      {
        path: "/add_certificate_form",
        name: "add_certificated_form",
        component: AddCertificateForm,
        meta: { requiresAuth: true }
      }
    ]
  }

];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!sessionStorage.getItem("access_token")) {
      EventBus.$emit('alert-warning', 'กรุณาเข้าสู่ระบบใหม่อีกครั้ง')
      return next({name: 'login'})
    }
  } 
  if(to.matched.some(record => record.meta.admin)) {
    if(JSON.parse(sessionStorage.getItem("user_profile"))?.is_admin == true) return next()
    else return next({name: 'NotFound'})
  }
  next()
})
export default router;
