<template>
  <div>
    <v-overlay v-model="loading"> <!-- page loading -->
      <v-progress-circular indeterminate color="#FFF9C4" :size="80" :width="7"></v-progress-circular>
    </v-overlay>
    <v-form ref="form_1" lazy-validation>
      <v-row class="pass-exam-row pass-exam-header-row">
        <v-col cols="auto" md="auto" lg="auto" class="py-2">
          <v-btn depressed small fab color="rgb(154,179,209)" @click="$router.push({name:'select_regis'})"> <!-- back button -->
            <v-icon color="rgb(60,86,147)">mdi-chevron-double-left</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="9" md="auto" lg="auto" class="mt-1 px-4 py-2 pass-exam-header-block">
          <b class="pass-exam-header">ระบบลงทะเบียนรายชื่อผู้ผ่านการฝึกอบรมและผ่านการประเมินและสอบตามเกณฑ์และวิธีการประเมินและสอบเพื่อขอรับประกาศนียบัตร</b>
        </v-col>
      </v-row>
      <v-row justify="end" class="pass-exam-row">
        <v-col cols="auto" md="auto" lg="auto" class="pr-3 pb-0">
          <v-btn depressed color="#92f08d" class="pass-exam-export-btn" @click="goToEditStaff">
            <b>แก้ไข/ลบข้อมูลเจ้าหน้าที่</b>
          </v-btn>
        </v-col>
        <v-col cols="auto" md="auto" lg="auto" class="pr-3 pb-0">
          <v-btn depressed color="#FFE57F" class="pass-exam-export-btn" @click="goToDownloadExportData()" v-if="is_admin">
            <b>Export Excel ทั้งหมด</b>
          </v-btn>
        </v-col>
        <v-col cols="auto" md="auto" lg="auto" class="pr-3 pb-0">
          <v-btn depressed color="#FFE57F" class="pass-exam-export-btn" @click="confirmDialog" v-if="is_admin">
            <b>Export Excel แต่ละองค์กร</b>
          </v-btn>
        </v-col>
        <!-- <v-col cols="auto" md="auto" lg="auto" class="pl-0 pb-0">
          <v-btn depressed color="#f5b6fa" class="pass-exam-export-btn">
            <b>Import Excel</b>
          </v-btn>
        </v-col> -->
      </v-row>
      <v-row class="mt-0 pass-exam-row">
        <v-col cols="auto" md="auto" lg="auto" align-self="center" class="pb-0 pass-exam-head-title">
          <b>หลักสูตร :</b>
        </v-col>
        <v-col cols="9" md="7" lg="7" class="px-0 pb-0">
          <v-combobox dense outlined rounded hide-details append-icon="mdi-chevron-down" :items="course_list" class="pass-exam-input pass-exam-dropdown-box" v-model="course_selected" :rules="rules.required"  id="course_select"></v-combobox>
        </v-col>
      </v-row>
      <v-row class="mt-0 pass-exam-row">
        <v-col cols="auto" md="auto" lg="auto" align-self="center" class="pb-0 pass-exam-head-title">
          <b>รุ่นที่อบรม :</b>
        </v-col>
        <v-col cols="8" md="2" lg="2" class="px-0 pb-0">
          <v-autocomplete auto-select-first dense outlined rounded hide-details class="pass-exam-input pass-exam-inputbox" :items="generation_list" v-model="generation" :rules="rules.required" id="generation"></v-autocomplete>
        </v-col>
        <v-col cols="auto" md="auto" lg="auto" align-self="center" class="pb-0 pass-exam-year-title">
          <b>ปีที่อบรม :</b>
        </v-col>
        <v-col cols="3" md="1" lg="1" class="px-0 pb-0">
          <v-autocomplete auto-select-first dense outlined rounded hide-details class="pass-exam-input pass-exam-inputbox" :items="year_generation_list" v-model="year_generation" :rules="rules.required" id="year_generation"></v-autocomplete>
        </v-col>
        <v-col cols="12" md="" lg="" align-self="center" class="pa-0">
          <v-row class="pass-exam-row">
            <v-col cols="auto" md="auto" lg="auto" align-self="center" class="pb-0 pass-exam-start-title">
              <b>วันที่อบรม :</b>
            </v-col>
            <v-col cols="3" md="2" lg="2" class="px-0 pb-0">
              <v-menu :close-on-content-click="false" offset-y v-model="start_date_menu">
                <template v-slot:activator="{ on }">
                  <v-text-field dense outlined rounded hide-details readonly v-on="on" label="วันที่เริ่ม" :value="start_date | shortdate" class="pass-exam-input pass-exam-inputbox pass-exam-start-input" id="start_date"></v-text-field>
                </template>
                <v-date-picker locale="th" color="rgb(60,86,147)" v-model="start_date" @change="start_date_menu=false;checkEndDate()" class="pass-exam-calendar"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="auto" md="auto" lg="auto" align-self="center" class="pb-0 pass-exam-end-title">
              <b>ถึง</b>
            </v-col>
            <v-col cols="3" md="2" lg="2" class="px-0 pb-0">
              <v-menu :close-on-content-click="false" offset-y v-model="end_date_menu">
                <template v-slot:activator="{ on }">
                  <v-text-field dense outlined rounded hide-details readonly v-on="on" label="วันที่สิ้นสุด" :value="end_date | shortdate" class="pass-exam-input pass-exam-inputbox pass-exam-start-input"  id="end_date"></v-text-field>
                </template>
                <v-date-picker locale="th" color="rgb(60,86,147)" v-model="end_date" @change="end_date_menu=false" class="pass-exam-calendar"  :min="start_date"></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="mt-6 pass-exam-row">
        <u class="pass-exam-name-header">รายชื่อผู้ผ่านการฝึกอบรม และผ่านการประเมินและสอบ (กรณีไม่พบชื่อหน่วยปฏิบัติการในจังหวัด หมายถึง ไม่ได้เป็นหน่วยปฏิบัติการ หรือสถานพยาบาล)</u>
      </v-row>
      <v-row class="mt-3 pass-exam-row pass-exam-namelist-block">
        <v-col v-for="(item, index) in list_trained" cols="12" md="3" lg="3" class="pl-0 pt-0 pass-exam-person-block" :key="`list_trained_1_${index}`" :id="`list_trained_1_${index}`"> <!-- each person -->
          <v-card color="#eaf4ff">
            <v-card-title class="px-3 pt-1 pb-0 pass-exam-people-header">
              <v-row align="center" class="pass-exam-row">
                <b class="pass-exam-num-people">ลำดับที่ {{ index + 1 }}</b>
                <v-spacer></v-spacer>
                <v-btn icon x-small color="white" @click="removeTrained(index)"> <!-- delete person button -->
                  <v-icon>mdi-window-close</v-icon>
                </v-btn>
              </v-row>
            </v-card-title>
            <v-card-text class="px-3 pb-4 pt-3">
              <v-row v-if="item.error_text" class="pass-exam-row" style="color: red; font-family: 'Noto Sans Thai', sans-serif;">
                {{ item.error_text }}
              </v-row>
              <v-row v-if="item.success_text" class="pass-exam-row" style="color: green; font-family: 'Noto Sans Thai', sans-serif;">
                {{ item.success_text }}
              </v-row>
              <v-row class="pass-exam-row">
                <v-col cols="auto" md="auto" lg="auto" align-self="center" class="pl-0 pt-0 pb-2 pr-2 pass-exam-person-title">
                  ชื่อ - สกุล :
                </v-col>
                <v-col cols="9" md="" lg="" class="px-0 pt-0 pb-2">
                  <v-text-field dense outlined hide-details background-color="white" class="pass-exam-person-input" v-model="item.name" :rules="rules.required"></v-text-field>
                </v-col>
              </v-row>
              <v-row class="mt-0 pass-exam-row">
                <v-col cols="auto" md="auto" lg="auto" align-self="center" class="pl-0 pt-0 pb-2 pr-2 pass-exam-person-title">
                  เลขที่บัตรประชาชน :
                </v-col>
                <v-col cols="5" md="6" lg="6" class="px-0 pt-0 pb-2">
                  <v-text-field dense outlined hide-details background-color="white" class="pass-exam-person-input" v-model="item.id_card_num" :onkeypress='`return ((event.charCode >= 48 && event.charCode <= 57) && "${item.id_card_num}".length<13)`' :rules="[...rules.required, ...rules.number, ...rules.textLength13]"></v-text-field>
                </v-col>
              </v-row>
              <v-row class="mt-0 pass-exam-row">
                <v-col cols="auto" md="auto" lg="auto" align-self="center" class="pl-0 pt-0 pb-2 pr-2 pass-exam-person-title">
                  วุฒิการศึกษา :
                </v-col>
                <v-col cols="8" md="" lg="" class="px-0 pt-0 pb-2">
                  <v-combobox dense outlined hide-details auto-select-first background-color="white" append-icon="mdi-chevron-down" class="pass-exam-person-input pass-exam-person-dropdown" :rules="rules.required" v-model="item.education_level" :items="education_level_list"></v-combobox>
                </v-col>
              </v-row>
              <v-row class="mt-0 pass-exam-row">
                <v-col cols="auto" md="auto" lg="auto" align-self="center" class="pl-0 pt-0 pb-2 pr-2 pass-exam-person-title">
                  วันเกิด :
                </v-col>
                <v-col cols="4" md="4" lg="4" class="px-0 pt-0 pb-2">
                  <v-menu :close-on-content-click="false" offset-y v-model="birthMenu[index]">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field dense outlined hide-details background-color="white" class="pass-exam-person-input pd-pass-exam-birthday" v-on="on" v-bind="attrs" :value="item.birth_date | shortdate" readonly></v-text-field>
                    </template>
                    <v-date-picker locale="th" color="rgb(60,86,147)" v-model="item.birth_date" class="pass-exam-calendar" @input="birthMenu[index] = false" @change="calcAge($event, index)" :max="maxBirthdate" :min="minBirthdate"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="auto" md="auto" lg="auto" align-self="center" class="pl-4 pt-0 pb-2 pr-2 pass-exam-person-title">
                  อายุ :
                </v-col>
                <v-col cols="2" md="3" lg="3" class="px-0 pt-0 pb-2">
                  <v-text-field dense outlined hide-details background-color="white" suffix="ปี" class="pass-exam-person-input" readonly v-model="item.age" :rules="[...rules.required, ...rules.number, ...rules.numberUpperZero, ...rules.numberInteger]" hide-spin-buttons></v-text-field>
                </v-col>
              </v-row>
              <!-- <v-row class="mt-0 pass-exam-row">
                <v-col cols="auto" md="auto" lg="auto" align-self="center" class="pl-0 pt-0 pb-2 pr-2 pass-exam-person-title">
                  อายุ :
                </v-col>
                <v-col cols="2" md="3" lg="3" class="px-0 pt-0 pb-2">
                  <v-text-field dense outlined hide-details background-color="white" suffix="ปี" class="pass-exam-person-input" readonly v-model="item.age" :rules="[...rules.required, ...rules.number, ...rules.numberUpperZero, ...rules.numberInteger]" hide-spin-buttons></v-text-field>
                </v-col>
              </v-row> -->
              <v-row class="mt-0 pass-exam-row">
                <v-col cols="auto" md="auto" lg="auto" align-self="center" class="pl-0 pt-0 pb-2 pr-2 pass-exam-person-title">
                  เบอร์โทรติดต่อ :
                </v-col>
                <v-col cols="4" md="5" lg="5" class="px-0 pt-0 pb-2">
                  <v-text-field dense outlined hide-details background-color="white" class="pass-exam-person-input" v-model="item.tel_no" :onkeypress='`return (event.charCode >= 48 && event.charCode <= 57)`'></v-text-field>
                </v-col>
              </v-row>
              <v-row class="mt-0 pass-exam-row">
                <v-col cols="auto" md="auto" lg="auto" align-self="center" class="pl-0 pt-0 pb-2 pr-2 pass-exam-person-title">
                  Line ID :
                </v-col>
                <v-col cols="9" md="" lg="" class="px-0 pt-0 pb-2">
                  <v-text-field dense outlined hide-details background-color="white" class="pass-exam-person-input" v-model="item.line_id"></v-text-field>
                </v-col>
              </v-row>
              <v-row class="mt-0 pass-exam-row">
                <v-col cols="auto" md="auto" lg="auto" align-self="center" class="pl-0 pt-0 pb-2 pr-2 pass-exam-person-title">
                  จังหวัดหน่วยปฏิบัติการ :
                </v-col>
                <v-col cols="6" md="" lg="" class="px-0 pt-0 pb-2">
                  <v-combobox dense outlined hide-details auto-select-first background-color="white" append-icon="mdi-chevron-down" class="pass-exam-person-input pass-exam-person-dropdown pass-exam-person-province" v-model="item.province" :rules="rules.required" :items="province_list" @change="item.medical_operation_name='';changeProvince($event)"></v-combobox>
                </v-col>
              </v-row>
              <v-row class="mt-0 pass-exam-row">
                <v-col cols="auto" md="auto" lg="auto" align-self="center" class="pl-0 pt-0 pb-1 pr-2 pass-exam-person-title">
                  ชื่อสังกัดหน่วยปฏิบัติการ :
                </v-col>
                <v-col cols="12" md="12" lg="12" class="px-0 pt-0 pb-2">
                  <v-combobox dense outlined hide-details auto-select-first background-color="white" append-icon="mdi-chevron-down" class="pass-exam-person-input pass-exam-person-dropdown" v-model="item.medical_operation_name" :rules="rules.required" :items="item.province in medical_with_province_list ? medical_with_province_list[item.province] : []"></v-combobox>
                </v-col>
              </v-row>
              <v-row class="mt-0 pass-exam-row">
                <v-col cols="auto" md="auto" lg="auto" align-self="center" class="pl-0 pt-0 pb-1 pr-2 pass-exam-person-title">
                  รูปภาพผู้ปฏิบัติการ
                  <span style="color:red"> 
                    (กรุณาใช้รูปหน้าตรงและพื้นหลังสีขาวหรือน้ำเงิน)  
                  </span> :
                </v-col>
                <v-col cols="12" md="12" lg="12" class="px-0 pt-0 pb-0">
                  <v-file-input 
                    outlined 
                    dense 
                    hide-details 
                    background-color="white" 
                    prepend-icon='' 
                    prepend-inner-icon="$file" 
                    accept="image/png, image/jpeg" 
                    placeholder="กรุณาเลือกไฟล์"  
                    class=" pass-exam-person-input icon-input-pass-exam pd-icon-input-pass-exam pd-pass-exam-icon-close" 
                    v-model="item.photo"
                    @change="validateFileType(index)"
                    ref="fileInput"
                    >
                  </v-file-input>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <img v-if="images[index]" :src="images[index]"  style="display: block; margin: 0 auto; max-width: 180px; max-height: 180px;">
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="3" lg="3" class="pl-0 pt-0 pass-exam-person-block">
          <v-btn block outlined x-large color="#d1d1d1" class="pass-exam-add-btn" @click="addTrained"> <!-- add person button -->
            <v-avatar size="100" class="pass-exam-add-icon">+</v-avatar>
          </v-btn>
        </v-col>
      </v-row>
      <v-row cols="12" md="3" lg="3" justify="center" class="pass-exam-row ">
        <p class="pass-exam-info-user">
          องค์กรฝึกอบรม ดำเนินการตรวจสอบรูปภาพผู้ปฏิบัติการให้ถูกต้อง เพื่อการออกประกาศนียบัตร 
        <br>
          หากใส่ข้อมูลที่ไม่ถูกต้องซึ่งส่งผลต่อประกาศนียบัตรที่ได้รับ  การแก้ไขดำเนินการโดย “แบบยื่นคำร้องขอแก้ไข/เปลี่ยนแปลงข้อมูลประกาศนียบัตร”
        </p>
      </v-row>
      <v-row justify="center" class="pass-exam-row">
        <v-btn depressed large color="rgb(60,86,147)" class="px-12 pass-exam-submit-btn" @click="validateFn">
          <b>Submit</b>
        </v-btn>
      </v-row>

    </v-form>
    <br>
    <v-dialog v-model="dialog_export" width="auto" class="dialog">
        <template>
            <v-card style="font-family: 'Noto Sans Thai', sans-serif;">
                <v-toolbar color="rgb(60,86,147)" align="center" dark>export excel
                  <v-spacer></v-spacer>
                    <v-btn
                      icon
                      dark
                      @click="dialog_export = false"
                      >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-card-text style="font-family: 'Noto Sans Thai', sans-serif;">
                    <h3 class="pa-10">{{dialog_text}}
                    </h3>
                    <v-row class="mt-0 pass-exam-row">
                      <v-col cols="auto" md="6" lg="12" align-self="center" class="pl-0 pt-0 pb-2 pr-2 pass-exam-person-title">
                        จังหวัดหน่วยปฏิบัติการ :
                      </v-col>
                      <v-col cols="auto" md="6" lg="12" class="px-0 pt-0 pb-2">
                        <v-combobox dense outlined hide-details auto-select-first background-color="white" append-icon="mdi-chevron-down" class="pass-exam-person-input pass-exam-person-dropdown pass-exam-person-province" v-model="export_data_province" :rules="rules.required" :items="province_list" @change="export_operation_name='';changeProvince($event),validDialog = false"></v-combobox>
                      </v-col>
                    </v-row>
                    <v-row class="mt-0 pass-exam-row">
                      <v-col cols="auto" md="6" lg="12" align-self="center" class="pl-0 pt-0 pb-1 pr-2 pass-exam-person-title">
                        ชื่อสังกัดหน่วยปฏิบัติการ :
                      </v-col>
                      <v-col cols="auto" md="6" lg="12" class="px-0 pt-0 pb-2">
                        <v-combobox dense outlined hide-details auto-select-first background-color="white" append-icon="mdi-chevron-down" class="pass-exam-person-input pass-exam-person-dropdown" v-model="export_operation_name" :rules="rules.required" :items="export_data_province in medical_with_province_list ? medical_with_province_list[export_data_province] : []" @change="validateDialog"></v-combobox>
                      </v-col>
                    </v-row>
                    <v-row class="mt-0 pass-exam-row">
                      <v-col cols="auto" md="6" lg="12" align-self="center" class="pl-0 pt-0 pb-1 pr-2 pass-exam-person-title">
                        วันที่เริ่มต้น :
                      </v-col>
                      <v-col cols="auto" md="6" lg="12" class="px-0 pt-0 pb-2">
                        <v-menu offset-y :close-on-content-click="false" v-model="start_date_menu_export_Org">
                          <template v-slot:activator="{ on }">
                            <v-text-field id="start_date_export_Org" dense outlined hide-details  readonly v-on="on" :value="start_date_export_Org | shortdate" class="success-form-input success-form-inputbox success-form-date-box"></v-text-field>
                          </template>
                          <v-date-picker locale="th" color="rgb(60,86,147)" v-model="start_date_export_Org" @change="start_date_menu_export_Org = false; checkEndDate()"
                                  class="success-form-calendar"></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                    <v-row class="mt-0 pass-exam-row">
                      <v-col cols="auto" md="6" lg="12" align-self="center" class="pl-0 pt-0 pb-1 pr-2 pass-exam-person-title">
                        วันที่สิ้นสุด :
                      </v-col>
                      <v-col cols="auto" md="6" lg="12" class="px-0 pt-0 pb-2">
                        <v-menu offset-y :close-on-content-click="false" v-model="end_date_menu_export_Org">
                              <template v-slot:activator="{ on }">
                                <v-text-field id="end_date_export_Org" dense outlined hide-details  readonly v-on="on" :value="end_date_export_Org | shortdate" class="success-form-input success-form-inputbox success-form-date-box"></v-text-field>
                              </template>
                                <v-date-picker locale="th" color="rgb(60,86,147)" :min="start_date_export_Org" v-model="end_date_export_Org"  @change="end_date_menu_export_Org = false" class="success-form-calendar"></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="justify-end">
                    <v-btn color="error" variant="text" @click="dialog_export = false" outlined>ยกเลิก</v-btn>
                    <v-btn color="primary" variant="text" :disabled="!validDialog" @click="exportDataOrg(start_date_export_Org,end_date_export_Org)">export</v-btn>
                </v-card-actions>
            </v-card>
        </template>
    </v-dialog>
    <v-dialog v-model="excel_dialog" max-width="500"  max-weight="500" class="dialog" >
        <template>
            <v-card style="font-family: 'Noto Sans Thai', sans-serif;">
                <v-toolbar color="rgb(60,86,147)" align="center" dark>
                  export excel
                  <v-spacer></v-spacer>
                    <v-btn
                      icon dark @click="excel_dialog = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar>
              <v-card-text style="font-family: 'Noto Sans Thai', sans-serif; font-weight: 100; padding-top:30px;" class="black--text"> 
                <v-row class="mt-0 pass-exam-row">
                    <v-col cols="auto" md="6" lg="12" align-self="center" class="pl-0 pt-0 pb-2 pr-2 pass-exam-person-title">
                        วันที่เริ่มต้น :
                    </v-col>
                    <v-col cols="auto" md="6" lg="12" class="px-0 pt-0 pb-2">
                      <v-menu offset-y :close-on-content-click="false" v-model="start_date_menu_export">
                          <template v-slot:activator="{ on }">
                            <v-text-field id="start_date_export" dense outlined hide-details  readonly v-on="on" 
                              :value="start_date_export | shortdate" class="success-form-input success-form-inputbox success-form-date-box"></v-text-field>
                          </template>
                            <v-date-picker locale="th" color="rgb(60,86,147)" v-model="start_date_export" @change="start_date_menu_export = false; checkEndDate()"
                                  class="success-form-calendar"></v-date-picker>
                      </v-menu>
                    </v-col>
                 </v-row>
                  <v-row class="mt-0 pass-exam-row">
                      <v-col cols="auto" md="6" lg="12" align-self="center" class="pl-0 pt-0 pb-1 pr-2 pass-exam-person-title">
                        วันที่สิ้นสุด :
                      </v-col>
                      <v-col cols="auto" md="6" lg="12" class="px-0 pt-0 pb-2">
                            <v-menu offset-y :close-on-content-click="false" v-model="end_date_menu_export">
                              <template v-slot:activator="{ on }">
                                  <v-text-field id="end_date_export" dense outlined hide-details  readonly v-on="on" 
                                    :value="end_date_export | shortdate"
                                    class="success-form-input success-form-inputbox success-form-date-box"></v-text-field>
                              </template>
                                <v-date-picker locale="th" color="rgb(60,86,147)" :min="start_date_export" v-model="end_date_export"
                                  @change="end_date_menu_export = false" class="success-form-calendar"></v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
              </v-card-text>
              <v-card-actions cols="12" sm="12" md="12" lg="3" class="pl-lg-3 mt-10 mt-lg-0 pr-md-6 pr-lg-0" :class="windowSize.x < 1264? 'd-flex justify-center': ''">
                <v-btn color="#0B68D6" class="white--text text-center" @click="exportData(start_date_export , end_date_export)" height="43" width="120" style="font-weight: 0;  border-radius: 20px; box-shadow: none;">
                  Export
                </v-btn>
              </v-card-actions>    
          </v-card>
        </template>
    </v-dialog>
  </div>
</template>

<script>
import { EventBus } from '../EventBus'
  export default {
    data: () => ({
      loading: false,
      course_list: [],
      course_selected: null,
      generation: null,
      year_generation: null,
      start_date_menu: false,
      start_date_menu_export: false,
      start_date_menu_export_Org: false,
      start_date: new Date().toISOString().substr(0, 10),
      start_date_export: new Date().toISOString().substr(0, 10),
      start_date_export_Org: new Date().toISOString().substr(0, 10),
      end_date_menu: false,
      end_date_menu_export: false,
      end_date_menu_export_Org: false,
      end_date: new Date().toISOString().substr(0, 10),
      end_date_export: new Date().toISOString().substr(0, 10),
      end_date_export_Org: new Date().toISOString().substr(0, 10),
      rules: {
        required: [v => !!v || 'Name is required'],
        number: [v => !isNaN(v) && /^[0-9]*$/.test(v) || 'ต้องเป็นตัวเลขเท่านั้น'],
        numberUpperZero: [v => parseInt(v) > 0 || 'ต้องมีค่ามากกว่า 0'],
        numberInteger: [v => (Number(v)%1 == 0) || 'ต้องเป็นจำนวนเต็มเท่านั้น'],
        textLength13: [v => (v && v.length == 13) || 'ต้องมี 13 หลัก']
      },
      list_trained: [
        {
          name: '',
          id_card_num: '',
          age: '20',
          education_level: '',
          province:'',
          medical_operation_name: '',
          birth_date: new Date(new Date().setFullYear(new Date().getFullYear() - 20)).toISOString().substr(0, 10),
          line_id: '',
          tel_no: '',
          photo: null
        }
      ],
      province_list: [],
      medical_with_province_list: {},
      education_level_list: ['มัธยมศึกษาตอนต้น','มัธยมศึกษาตอนปลาย','ปวช.','ปวส.','ปริญญาตรี','ปริญญาโท','ปริญญาเอก'],
      year_generation_list: [],
      generation_list: Array.from({ length: 10 }, (_, i) => i + 1),
      maxBirthdate: new Date(new Date().setFullYear(new Date().getFullYear() - 1)).toISOString().substr(0, 10),
      minBirthdate: new Date(new Date().setFullYear(new Date().getFullYear() - 100)).toISOString().substr(0, 10),
      birthMenu: [],
      is_admin: false,
      dialog_export: false,
      dialog_text: "",
      export_data_province: "",
      export_operation_name: "",
      validDialog: false,
      excel_dialog: false,
      windowSize: { x: 0 },
      images: []
    }),
    mounted(){
      const userProfile = JSON.parse(sessionStorage.userProfile)
      this.is_admin = userProfile.is_admin
      this.init()
    },
    methods: {
      async init() {
        try {
          const datenow = new Date()
          this.year_generation_list = Array.from({length: datenow.getFullYear() - 2016}, (_, i) => (datenow.getFullYear() + 543 - i).toString());
          this.loading = true
          const configCourse = { baseURL: process.env.VUE_APP_API_URL, url: '/manage/api/v1/getcourse', params: { course_num: 1 } }
          const configProvice = { baseURL: process.env.VUE_APP_API_URL, url: '/manage/api/v1/getprovince' }
          const [courseData, provinceData] = await Promise.all([this.axios(configCourse), this.axios(configProvice)])
          if(courseData.data.status && provinceData.data.status) {
            this.course_list = courseData.data.data.map(item => item.course_name)
            this.province_list =  provinceData.data.data
            this.medical_with_province_list = this.province_list.reduce((obj, key) => Object.assign(obj, {[key]: []}), {})
          }
        } catch (error) {
          EventBus.$emit('alert-error', 'เกิดปัญหาระหว่างดึงข้อมูล กรุณาลองใหม่ภายหลัง')
          console.error(error);
        } finally {
          this.loading = false
        }
        
      },
      async changeProvince(province_selected) {
        if(Object.prototype.hasOwnProperty.call(this.medical_with_province_list, province_selected)&& this.medical_with_province_list[province_selected].length <= 0) {
          try {
            const config = { baseURL: process.env.VUE_APP_API_URL, url: '/manage/api/v1/getmo', params: { province:province_selected } }
            const { data } = await this.axios(config)
            if(data.status) this.medical_with_province_list[province_selected] = data.data.map(item => item.medical_operation_name)
          } catch (error) {
            console.error(error);
            this.medical_with_province_list[province_selected] = []
          }
        }
      },
      addTrained() {
        this.list_trained.push({name: '',id_card_num: '',age: '20',education_level: '',province:'',medical_operation_name: '',birth_date:new Date(new Date().setFullYear(new Date().getFullYear() - 20)).toISOString().substr(0, 10),line_id: '', tel_no: '', photo: null})
      },
      removeTrained(index) {
        this.list_trained.splice(index, 1)
      },
      confirmSubmit() {
        this.$swal({
          title: 'คุณต้องการบันทึกหรือไม่ ?',
          showDenyButton: false,
          showCancelButton: true,
          confirmButtonText: 'บันทึก',
          cancelButtonText: 'ปิด',
          width: "363px",
          customClass: {
            title: 'sweet-confirm-text',
            popup: 'sweet-confirm-card',
            confirmButton: 'sweet-confirm-btn',
            cancelButton: 'sweet-confirm-cancel-btn'
          }
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.sendData()
          } else if (result.isDenied) {
            // Swal.fire('Changes are not saved', '', 'info')
          }
        })
      },
      validateFn () {
        setTimeout(() => {
          
          if (this.list_trained.length <= 0) {
            return EventBus.$emit('alert-warning', 'จำเป็นต้องมีรายชื่ออย่างน้อย 1 คน')
          }
          if (!this.$refs.form_1.validate()) {
            let valid = true
            let contentError = null
            let message = 'กรุณากรอกข้อมูลให้ถูกต้องและครบถ้วน'
            if (!this.course_selected) {
              valid = false
              contentError = 'course_select'
            }
            if(valid && !this.generation) {
              valid = false
              contentError = 'generation'
            }
            if(valid && !this.year_generation) {
              valid = false
              contentError = 'year_generation'
            }
            if(valid && !this.start_date) {
              valid = false
              contentError = 'start_date'
            }
            if(valid && !this.end_date) {
              valid = false
              contentError = 'end_date'
            }
            if(valid) {
              valid = this.list_trained.every((item, index) => {
                if(!item.name || !item.id_card_num || !(/^\d+$/.test(item.id_card_num)) || item.id_card_num.length != 13 || !item.age || isNaN(item.age) || item.age <= 0 || Number(item.age)%1 != 0 || !item.province || !item.medical_operation_name ){
                  contentError = `list_trained_1_${index}`
                  if (!item.name || !item.id_card_num || !item.age || !item.province || !item.medical_operation_name) message += ` (ลำดับที่ ${index + 1})`
                  else if(!(/^\d+$/.test(item.id_card_num))) message = `กรุณากรอกเลขบัตรประชาชนเป็นตัวเลขเท่านั้น (ลำดับที่ ${index + 1})`
                  else if (item.id_card_num.length != 13) message = `กรุณากรอกเลขบัตรประชาชน 13 หลักเท่านั้น (ลำดับที่ ${index + 1})`
                  else if (isNaN(item.age)) message = `กรุณากรอกอายุเป็นตัวเลขเท่านั้น (ลำดับที่ ${index + 1})`
                  else if (item.age <= 0) message = `กรุณากรอกอายุให้ถูกต้อง (ลำดับที่ ${index + 1})`
                  else if (Number(item.age)%1 != 0) message = `กรุณากรอกอายุเป็นจำนวนเต็มเท่านั้น (ลำดับที่ ${index + 1})`
                  // else if (!item.photo) message = `กรุณาเพิ่มรูปภาพ (ลำดับที่ ${index + 1})`
                  else message += ` (ลำดับที่ ${index + 1})`
                  return false
                }
                else return true
              })
            }
  
  
            if(!valid) {
              const element = document.getElementById(contentError);
              const yOffset = -70; 
              const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset
              window.scrollTo({top: y});
              EventBus.$emit('alert-warning', message)
            }
          } else {
            this.confirmSubmit()
          }
        }, 100);
      },
      validateFileType(index) { // No file selected
        var upload_file = this.list_trained[index].photo;
        if (!upload_file) {
          this.images[index] = null
          return;
        }
        const fileType = upload_file.type;
        const allowedFormats = ['image/jpeg', 'image/jpg', 'image/png'];
        if (!allowedFormats.includes(fileType)) {
          this.$refs.fileInput[index].reset()
          this.list_trained[index].photo = null;
          EventBus.$emit(
            "alert-error",
            "กรุณาเลือกรูปภาพ นามสกุล .jpg .jpeg .png เท่านั้น" + "\n",
            0
          );
        }
        if(this.list_trained[index].photo) {
          this.images[index] = URL.createObjectURL(upload_file);
        }
      },
      getBase64(file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.onloadend = () => resolve(reader.result)
          reader.onerror = (error) => reject(error)
          reader.readAsDataURL(file)
        })
      },
      async sendData () {
        try {
          this.loading = true
          // console.log(this.list_trained);
          let formdata = new FormData()
          JSON.parse(JSON.stringify(this.list_trained)).forEach((item,index) => {
            let obj = item
            obj.course_selected = this.course_selected.trim()
            obj.generation = this.generation
            obj.year_generation = this.year_generation
            obj.start_date = this.start_date.trim()
            obj.end_date = this.end_date.trim()
            obj.is_upload_img = this.list_trained[index].photo ? true : false
            delete obj.photo
            // obj.photo.name = `${index}.png`
            // console.log(index, JSON.stringify(obj.photo));
            formdata.append(`register_data[]`, JSON.stringify(obj))
            formdata.append(`file`, this.list_trained[index].photo)
          })
          // console.log(formdata.getAll('register_data[]'));
          // let payload = []
          // for (let index = 0; index < this.list_trained.length; index++) {
          //   const element = this.list_trained[index];
          //   const img_base = await this.getBase64(element.photo)
          //   const obj = {
          //       ...element,
          //       course_selected: this.course_selected.trim(),
          //       generation: this.generation.trim(),
          //       year_generation: this.year_generation,
          //       start_date: this.start_date.trim(),
          //       end_date: this.end_date.trim(),
          //       img_base
          //   }
          //   payload.push(obj)
          // }
          const config = {
            baseURL: process.env.VUE_APP_API_URL,
            url: '/register/api/v1/add_register_s1',
            method: 'POST',
            data: formdata,
            headers: {
              "Content-Type": "multipart/form-data"
            }
          }
          // console.log(config);
          const { data } = await this.axios(config)
          if(data.status) {
            if(data.data.unsuccess.length && !data.data.success.length){
              let error_index = ""
              let error_text = ""
              let error_data = []
              let text = ''
              this.list_trained.forEach((el) => {
                if(el.error_text){
                  el.error_text = ''
                }else if(el.success_text){
                  el.success_text = ''
                }
              })
              data.data.unsuccess.forEach((element,index) => {
                //หลักสูตร วุฒิการศึกษา ข้อมูลเลขปชช.ซ้ำ
                // if(element.description == 'มีเลขที่บัตรประชาชนอยู่แล้วในระบบ' || element.description == 'หลักสูตรและวุฒิการศึกษาไม่ตรงกัน'){
                  error_data[index] = {
                    "index": element.index+1,
                    "data": element.description
                  }
                  if(error_index == ''){
                    error_index = error_index + (element.index+1)
                  } else {
                    error_index = error_index + ', ' + (element.index+1)
                  }
                // }
                this.list_trained[element.index].error_text = element.description
              });
              error_data.forEach(el => {
                if(error_text == ""){
                  error_text = error_text + el.data
                }
                else{
                  error_text = error_text + ' / ' + el.data
                }
              })
              let str = ''
              error_data.forEach((el) => {
                str += `ลำดับที่ ${el.index}    ลงทะเบียนไม่สำเร็จ เนื่องจาก : ${el.data}\n`
              })
              text += `กรณีมีลำดับที่ลงทะเบียนไม่สำเร็จ กรุณาตรวจสอบข้อมูลของลำดับนั้นให้ถูกต้องและลงทะเบียนใหม่อีกครั้ง`
              this.$swal({
                title: 'ผลการลงทะเบียน',
                showDenyButton: false,
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonText: 'ปิด',
                width: "800px",
                html: '<pre>' + str + '</pre>' + '<p>' + text + '</p>',
                customClass: {
                  title: 'sweet-confirm-text',
                  popup: 'format-pre',
                  cancelButton: 'sweet-confirm-cancel-btn'
                }
              })
            }else if (data.data.success.length && data.data.unsuccess.length){
              let error_index = ""
              let error_text = ""
              let error_data = []
              let success_data = []
              let text = ''
              this.list_trained.forEach((el) => {
                if(el.error_text){
                  el.error_text = ''
                }else if(el.success_text){
                  el.success_text = ''
                }
              })
              data.data.unsuccess.forEach((element,index) => {
                //หลักสูตร วุฒิการศึกษา ข้อมูลเลขปชช.ซ้ำ
                // if(element.description == 'มีเลขที่บัตรประชาชนอยู่แล้วในระบบ' || element.description == 'หลักสูตรและวุฒิการศึกษาไม่ตรงกัน'){
                  error_data[index] = {
                    "index": element.index+1,
                    "data": element.description
                  }
                  if(error_index == ''){
                    error_index = error_index + (element.index+1)
                  } else {
                    error_index = error_index + ', ' + (element.index+1)
                  }
                // }
                this.list_trained[element.index].error_text = element.description
              });
              error_data.forEach(el => {
                if(error_text == ""){
                  error_text = error_text + el.data
                }
                else{
                  error_text = error_text + ' / ' + el.data
                }
              })
              data.data.success.forEach((element,index) => {
                success_data[index] = {
                  "index" : element.index + 1
                }
                this.list_trained[element.index].success_text = 'ลงทะเบียนเข้าสู่ระบบเรียบร้อยแล้ว'
              })
              let str = ''
              success_data.forEach(el => {
                str += `ลำดับที่ ${el.index}    ลงทะเบียนสำเร็จ\n`
              })
              error_data.forEach((el) => {
                str += `ลำดับที่ ${el.index}    ลงทะเบียนไม่สำเร็จ เนื่องจาก : ${el.data}\n`
              })
              text += `กรณีมีลำดับที่ลงทะเบียนไม่สำเร็จ กรุณาตรวจสอบข้อมูลของลำดับนั้นให้ถูกต้องและลงทะเบียนใหม่อีกครั้ง` 
              this.$swal({
                title: 'ผลการลงทะเบียน',
                showDenyButton: false,
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonText: 'ปิด',
                width: "800px",
                html: '<pre>' + str + '</pre>' + '<p>' + text + '</p>',
                customClass: {
                  title: 'format-title',
                  popup: 'format-pre',
                  text: 'format-text',
                  cancelButton: 'sweet-confirm-cancel-btn'
                }
              })
            }else{
              EventBus.$emit('alert-success', 'บันทึกสำเร็จ');
              this.list_trained = []
              this.images = []
              this.course_selected = ''
              this.generation = ''
              this.year_generation = ''
              this.start_date = new Date().toISOString().substr(0, 10)
              this.end_date = new Date().toISOString().substr(0, 10)
              this.medical_with_province_list = {}
              this.init()
              this.$nextTick(() => {
                this.$refs.form_1.resetValidation()
              })
            }
          }
        } catch (error) {
          EventBus.$emit('alert-error', 'เกิดข้อผิดพลาดในการบันทึก', 0)
          console.error(error);
        } finally {
          this.loading = false
        }
      },
      checkEndDate () {
        const start_date = new Date(this.start_date),
        end_date = new Date(this.end_date)
        if(end_date < start_date) this.end_date = this.start_date
      },
      confirmDialog(){
        this.dialog_text = 'กรุณาเลือกจังหวัดและชื่อสังกัดของหน่วยปฏิบัติการ'
        this.dialog_export = true
      },
      validateDialog(){
        if(this.export_data_province && this.export_operation_name) {
          this.validDialog = true
        }else{
          this.validDialog = false
        }
      },
      async exportDataOrg() {
        try {
          this.loading = true
          const config = {
            baseURL: process.env.VUE_APP_API_URL,
            url: '/register/api/v1/export_org_s1',
            params: {
              "province": this.export_data_province,
              "medical_operation_name": this.export_operation_name,
              "start_date": this.start_date_export_Org,
              "end_date": this.end_date_export_Org
            },
            responseType: 'blob'
          }
          const { data } = await this.axios(config)
          
          const months_th = [ "มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม" ];
          const date = new Date()
          const map = {
              MMMM: months_th[date.getMonth()],
              DD: date.getDate(),
              YY: date.getFullYear().toString().slice(-2),
              YYYY: date.getFullYear() + 543,
              h: date.getHours(),
              mm: date.getMinutes(),
              ss: date.getSeconds()
          }
          const href = URL.createObjectURL(data);

          // create "a" HTML element with href to file & click
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', 'export DD MMMM YYYY h_mm_ss.xlsx'.replace(/MMMM|DD|YYYY|YY|h|mm|ss/gi, matched => map[matched])) //or any other extension
          document.body.appendChild(link);
          link.click();

          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        } catch (error) {
          if(error.response) {
            const objData = JSON.parse(await error.response.data.text())
            EventBus.$emit('alert-error', objData.message)
          } else {
            EventBus.$emit('alert-error', 'ไม่สามารถ Export ข้อมูลได้ กรุณาติดต่อเจ้าหน้าที่')
          }
        } finally {
          this.loading = false
        }
      },
      async exportData() {
        try {
          this.loading = true
          const config = {
            baseURL: process.env.VUE_APP_API_URL,
            url: '/register/api/v1/export_register_s1',
            responseType: 'blob',
            params: {
              "start_date": this.start_date_export,
              "end_date": this.end_date_export
            },
          }
          const { data } = await this.axios(config)
          
          const months_th = [ "มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม" ];
          const date = new Date()
          const map = {
              MMMM: months_th[date.getMonth()],
              DD: date.getDate(),
              YY: date.getFullYear().toString().slice(-2),
              YYYY: date.getFullYear() + 543,
              h: date.getHours(),
              mm: date.getMinutes(),
              ss: date.getSeconds()
          }
          const href = URL.createObjectURL(data);

          // create "a" HTML element with href to file & click
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', 'export DD MMMM YYYY h_mm_ss.xlsx'.replace(/MMMM|DD|YYYY|YY|h|mm|ss/gi, matched => map[matched])) //or any other extension
          document.body.appendChild(link);
          link.click();

          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        } catch (error) {
          if(error.response) {
            const objData = JSON.parse(await error.response.data.text())
            EventBus.$emit('alert-error', objData.message)
          } else {
            EventBus.$emit('alert-error', 'ไม่สามารถ Export ข้อมูลได้ กรุณาติดต่อเจ้าหน้าที่')
          }
        } finally {
          this.loading = false
        }
      },
      calcAge(birth_date, index) {
        let ageDifMs = Date.now() - new Date(birth_date).getTime();
        let ageDate = new Date(ageDifMs); // miliseconds from epoch
        this.list_trained[index].age = Math.abs(ageDate.getUTCFullYear() - 1970);
      },
      goToDownloadExportData() {
        this.excel_dialog = true;
      },
      goToEditStaff(){
        this.$router.push({ name : 'pass_exam_list'})
      }
    }
  }
</script>

<style>
.pd-pass-exam-icon-close.v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__append-inner{
  margin-top: 5px !important;
}
.pd-pass-exam-birthday.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot{
  padding-left: 4px !important;
}
.pd-icon-input-pass-exam.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot{
  padding-left: 0px !important;
}
.icon-input-pass-exam.v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__prepend-inner {
  margin-top : 4px !important;
  margin-bottom: 4px !important;
}
  .pass-exam-row {
    width: 100%;
    margin: 0%;
  }

  .pass-exam-header-row {
    margin-top: 12px;
  }

  .pass-exam-header-block {
    background: rgb(154,179,209);
    border-radius: 32px;
    height: fit-content;
  }

  .pass-exam-header {
    font-family: 'Noto Sans Thai', sans-serif;
    color: rgb(60,86,147);
    font-size: 22px;
  }

  .pass-exam-export-btn {
    font-family: 'Noto Sans Thai', sans-serif;
    text-transform: capitalize;
    font-size: 16px !important;
    color: #3495cf !important;
  }
  
  .pass-exam-head-title {
    font-family: 'Noto Sans Thai', sans-serif;
    font-size: 16px;
    padding-left: 4% !important;
  }

  .pass-exam-input {
    font-family: 'Noto Sans Thai', sans-serif;
    font-size: 15px;
  }

  .pass-exam-dropdown-box.v-text-field--rounded > .v-input__control > .v-input__slot {
    padding-left: 2% !important;
    padding-right: 1% !important;
  }

  .pass-exam-inputbox.v-text-field--rounded > .v-input__control > .v-input__slot {
    padding-left: 10px !important;
    padding-right: 3% !important;
  }

  .pass-exam-year-title {
    font-family: 'Noto Sans Thai', sans-serif;
    font-size: 16px;
    padding-left: 2% !important;
  }

  .pass-exam-start-title {
    font-family: 'Noto Sans Thai', sans-serif;
    font-size: 16px;
    padding-left: 4% !important;
  }

  .pass-exam-start-input > .v-input__control > .v-input__slot > .v-text-field__slot > label {
    left: 20px !important;
  }

  .pass-exam-calendar {
    font-family: 'Noto Sans Thai', sans-serif;
  }

  .pass-exam-end-title {
    font-family: 'Noto Sans Thai', sans-serif;
    font-size: 16px;
  }

  .pass-exam-name-header {
    font-family: 'Noto Sans Thai', sans-serif;
    font-size: 16px;
    padding-left: 4%;
    font-weight: bold;
    color: rgb(60,86,147);
  }

  .pass-exam-namelist-block {
    padding-left: 4%;
  }

  .pass-exam-person-block {
    padding-right: 2% !important;
    padding-bottom: 2% !important;
  }

  .pass-exam-people-header {
    background: rgb(60,86,147);
  }

  .pass-exam-num-people {
    font-family: 'Noto Sans Thai', sans-serif;
    font-size: 16px;
    color: white;
  }

  .pass-exam-person-title {
    font-family: 'Noto Sans Thai', sans-serif;
    font-size: 15px;
    color: black;
  }

  .pass-exam-info-user {
    color: red;
    font-family: 'Noto Sans Thai', sans-serif; 
    font-size: 20px; 
    border-radius: 25px;
    border: 1px solid rgb(155, 154, 154);
    text-align: center;
    padding: 5px 15px 5px 15px;
  }

  .pass-exam-person-input {
    font-family: 'Noto Sans Thai', sans-serif;
    font-size: 14px;
  }

  .pass-exam-person-input > .v-input__control > .v-input__slot {
    min-height: 31px !important;
  }
  

  .pass-exam-person-dropdown.v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__append-inner {
    margin-top: 4px !important;
  }

  .pass-exam-person-dropdown.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot {
    padding-left: 3% !important;
    padding-right: 1% !important;
  }

  .pass-exam-person-province .v-input__icon {
    min-width: 15px !important;
    width: 15px !important;
  }

  .pass-exam-add-btn {
    border: 4px dashed;
    height: 468px !important;
  }

  .pass-exam-add-btn:hover {
    background-color: #efefef !important;
  }

  .pass-exam-add-icon {
    font-size: 76px;
    font-weight: 200;
    border: 2px solid #d1d1d1 !important;
  }

  .pass-exam-submit-btn {
    font-family: 'Noto Sans Thai', sans-serif;
    text-transform: capitalize;
    color: #FFE57F !important;
    font-size: 18px !important;
  }

  .format-pre pre {
    padding: 10px 0 0 2%;
    font-family: 'Noto Sans Thai', sans-serif;
    font-size: 17px;
    justify-items: start;
    display: flex;
    text-align: start;
  }

  .format-pre p {
    padding: 10px 0 0 2%;
    font-family: 'Noto Sans Thai', sans-serif;
    font-size: 15px;
    justify-items: start;
    display: flex;
    text-align: start;
    color: red;
  }

  .format-title {
    font-family: 'Noto Sans Thai', sans-serif;
    color: black !important;
    font-size: 21px;
    padding-bottom: 0;
    padding-top: 30px;
  }

  .text-divider {
    display: flex;
    align-items: center;
    --text-divider-gap: 1rem; 
  }

  .text-divider::before,
  .text-divider::after {
    content: '';
    height: 1px;
    background-color: silver;
    flex-grow: 1;
  }

  .text-divider::before {
    margin-right: var(--text-divider-gap);
  }

  .text-divider::after {
    margin-left: var(--text-divider-gap);
  }
  /*========================================*/

  @media only screen and (max-width:600px) { /* css for mobile */
    .pass-exam-header-row {
      margin-top: 6px;
    }

    .pass-exam-header-block {
      border-radius: 25px;
    }

    .pass-exam-header {
      font-size: 17px;
    }

    .pass-exam-year-title {
      padding-left: 4% !important;
    }

    .pass-exam-person-block {
      padding-right: 5% !important;
      padding-bottom: 6% !important;
    }

    .pass-exam-add-btn {
      height: 161px !important;
    }
  }
</style>