<template>
  <v-dialog
    v-model="state"
    max-width="1100"
    persistent
    content-class="elevation-0"
    scrollable
  >
    <v-overlay :value="loading">
      <!-- page loading -->
      <v-progress-circular
        indeterminate
        color="#FFF9C4"
        :size="80"
        :width="7"
      ></v-progress-circular>
    </v-overlay>
    <v-card
      fluid
      class="card-container"
    >
      <v-card-title class="tab-header">
        <v-row
          no-gutters
          align="center"
        >
          <v-col
            class="pa-0 text-truncate"
            style="color: #fff9c4"
          >
            <v-icon
              color="#FFF9C4"
              size="32"
            >
              mdi-information-variant-circle-outline
            </v-icon>
            <b class="ml-1 text-center text-header">
              รายการออกใบประกาศนียบัตร
            </b>
          </v-col>
          <v-col
            cols="auto"
            sm="auto"
            md="auto"
            class="pa-0"
          >
            <v-btn
              depressed
              icon
              color="#FFF9C4"
              @click="closeModal()"
            >
              <v-icon size="32"> mdi-close </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row 
          class="mt-8"  
          align="center"
        >
        <v-col cols="12" sm="8" md="8" lg="8" class="pl-2 pt-2">
          <v-text-field
              append-icon="mdi-magnify"
              placeholder="ค้นหา ชื่อ - สกุล"
              outlined
              height="40"
              rounded
              hide-details
              class="text-filter-input"
              v-model="searchKeyword"
              @click:append="
                getDetailCertificated({
                  ...pageOption,
                  keyword: searchKeyword,
                })
              "
              @keyup.enter="
                getDetailCertificated({
                  ...pageOption,
                  keyword: searchKeyword,
                })
              "
              @input="
                !searchKeyword &&
                  getDetailCertificated({ ...pageOption, keyword: '' })
              "
            ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4" md="4" lg="4" class="pl-2 pt-2">
          <v-autocomplete auto-select-first dense outlined rounded hide-details 
          class="pass-exam-input pass-exam-inputbox" :items="ListStatusCertificated" 
          v-model="statusCertificated" id="StatusCertificated" @input="
                !searchKeyword &&
                  getDetailCertificated({ ...pageOption, keyword: '' ,statusCertificated})
              "></v-autocomplete>
        </v-col>
        </v-row>
        <v-row class="mt-8">
          <v-data-table
            fixed-header
            :headers="headers"
            :items="itemList"
            :options.sync="pageOption"
            :server-items-length="itemTableCount"
            :footer-props="footerTableProps"
            class="data-table more-detail-certificated-table"
          >
            <template v-slot:[`item.no`]="{ index }">
              <p>
                {{
                  (pageOption["page"] - 1) * pageOption["itemsPerPage"] +
                  index +
                  1
                }}
              </p>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <span
                v-if="item.status === 'success'"
                style="color: #2ecc71; font-weight: 700"
              >
                สำเร็จ
              </span>
              <span
                v-else-if="item.status === 'waiting'"
                style="color: #3c5693; font-weight: 700"
              >
                รอการสร้างใหม่
              </span>
              <span
                v-else-if="item.status === 'in_progress'"
                style="color: #f39c12; font-weight: 700"
              >
                กำลังดำเนินการ
              </span>
              <span
                v-else
                style="color: #ff0000; font-weight: 700"
              >
                ไม่สำเร็จ
              </span>
            </template>
            <template v-slot:[`item.action`]="{ item, index }">
              <v-row justify="start">
                <template>
                  <v-menu
                    bottom
                    left
                    min-width="auto"
                    transition="scroll-y-transition"
                    :close-on-content-click="false"
                    content-class="list-action-menu"
                    v-if="$vuetify.breakpoint.xs"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        dark
                        icon
                        v-bind="attrs"
                        v-on="on"
                        :ripple="false"
                        class="menu-action-btn"
                      >
                        <v-icon color="#999">mdi-menu</v-icon>
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item
                        @click="getStaffDetail(item,'view')"
                        class="px-4"
                      >
                        <v-list-item-icon class="mr-3 my-auto">
                          <v-icon>mdi-eye</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title class="list-text-action-menu"
                          >ดูรายละเอียด</v-list-item-title
                        >
                      </v-list-item>
                      <v-list-item
                        @click="getStaffDetail(item,'edit')"
                        class="px-4"
                      >
                        <v-list-item-icon class="mr-3 my-auto">
                          <v-icon>mdi-pencil</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title class="list-text-action-menu"
                          >แก้ไขข้อมูล</v-list-item-title
                        >
                      </v-list-item>
                      <v-list-item
                        v-if="
                          ['waiting', 'fail'].includes(item.status)
                        "
                        @click="confirmModal(item, index, 'regenerate')"
                        class="px-4"
                      >
                        <v-list-item-icon class="mr-3 my-auto">
                          <v-icon>mdi-refresh</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title class="list-text-action-menu"
                          >สร้างใหม่</v-list-item-title
                        >
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
                <template>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <!-- desktop screen -->
                      <v-btn
                        v-if="$vuetify.breakpoint.smAndUp"
                        icon
                        depressed
                        elevation="0"
                        v-bind="attrs"
                        v-on="on"
                        :loading="item.regenerate_loading"
                        @click="getStaffDetail(item,'view')"
                      >
                        <v-icon
                          size="25"
                          color="#3c5693"
                        >
                          mdi-eye
                        </v-icon>
                      </v-btn>
                    </template>
                    <span class="tooltip-action-btn">ดูรายละเอียด</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <!-- desktop screen -->
                      <v-btn
                        v-if="$vuetify.breakpoint.smAndUp"
                        icon
                        depressed
                        elevation="0"
                        v-bind="attrs"
                        v-on="on"
                        :loading="item.regenerate_loading"
                        @click="getStaffDetail(item,'edit')"
                      >
                        <v-icon
                          size="25"
                          color="#f72f78"
                        >
                        mdi-pencil
                        </v-icon>
                      </v-btn>
                    </template>
                    <span class="tooltip-action-btn">แก้ไขข้อมูล</span>
                  </v-tooltip>
                  <v-tooltip top v-if="['waiting', 'fail'].includes(item.status)">
                    <template v-slot:activator="{ on, attrs }">
                      <!-- desktop screen -->
                      <v-btn
                        v-if="$vuetify.breakpoint.smAndUp"
                        icon
                        depressed
                        elevation="0"
                        v-bind="attrs"
                        v-on="on"
                        :loading="item.regenerate_loading"
                      >
                        <v-icon
                          size="25"
                          color="#2ecc71" 
                          @click="confirmModal(item, index, 'regenerate')"
                        >
                          mdi-refresh
                        </v-icon>
                      </v-btn>
                    </template>
                    <span class="tooltip-action-btn">สร้างใหม่</span>
                  </v-tooltip>
                </template>
                <!-- <template v-if="['waiting', 'fail'].includes(item.status)">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }"> -->
                      <!-- mobile screen -->
                      <!-- <v-btn
                        v-if="$vuetify.breakpoint.xs"
                        depressed
                        elevation="0"
                        v-bind="attrs"
                        v-on="on"
                        rounded
                        color="#00b300"
                        height="28"
                        class="regenerate-btn--mobile"
                        @click="confirmModal(item, index)"
                      >
                        <v-icon
                          size="20"
                          class="mr-1"
                          color="#fff"
                        >
                          mdi-refresh
                        </v-icon>
                        สร้างใหม่
                      </v-btn> -->

                      <!-- desktop screen -->
                      <!-- <v-btn
                        v-if="$vuetify.breakpoint.smAndUp"
                        icon
                        depressed
                        elevation="0"
                        v-bind="attrs"
                        v-on="on"
                        :loading="item.regenerate_loading"
                        @click="confirmModal(item, index)"
                      >
                        <v-icon
                          size="25"
                          color="#00b300"
                          @click="openDetailCertificated = true"
                        >
                          mdi-refresh
                        </v-icon>
                      </v-btn> -->
                    <!-- </template>
                    <span class="tooltip-action-btn">สร้างใหม่</span>
                  </v-tooltip>
                </template> -->
                <!-- <v-row
                  v-else-if="
                    !['fail', 'waiting'].includes(item.status) &&
                    $vuetify.breakpoint.xs
                  "
                >
                  ไม่พบการดำเนินการ
                </v-row> -->
              </v-row>
            </template>
            <template slot="no-data">
              <span> ไม่พบข้อมูล </span>
            </template>
          </v-data-table>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from "lodash";
import { EventBus } from "../EventBus";
export default {
  props: {
    state: {
      type: Boolean,
      default: false,
    },
    round: {
      type: String,
    },
  },
  watch: {
    state(newValue) {
      if (newValue) {
        this.getDetailCertificated({
          page: 1,
          itemsPerPage: 20,
          keyword: "",
        });
      }
    },
    pageOption: {
      handler: _.debounce(function (newValue, oldValue) {
        if (
          newValue["page"] === oldValue["page"] &&
          newValue["itemsPerPage"] === oldValue["itemsPerPage"] &&
          newValue["keyword"] === oldValue["keyword"]
        ) {
          return;
        }
        this.getDetailCertificated(newValue);
      }, 100),
      deep: true,
    },
  },
  mounted() {
    EventBus.$on('reloadGenCertModal', this.getDetailCertificated);
    },
  beforeDestroy() {
    EventBus.$off('reloadGenCertModal');
  },
  data: () => ({
    headers: [
      {
        text: "ลำดับ",
        sortable: false,
        align: "center",
        value: "no",
      },
      {
        text: "เลขประจำตัวประชาชน",
        sortable: false,
        align: "center",
        value: "id_card_num",
      },
      {
        text: "ระดับ",
        sortable: false,
        align: "center",
        value: "level",
      },
      {
        text: "เลขที่ประกาศนียบัตร",
        sortable: false,
        align: "center",
        value: "certificated_number",
      },
      {
        text: "ชื่อ-สกุล",
        sortable: false,
        align: "center",
        value: "full_name_th",
      },
      {
        text: "สถานะ",
        sortable: false,
        align: "center",
        value: "status",
      },
      {
        text: "ดำเนินการ",
        sortable: false,
        align: "center",
        value: "action",
      },
    ],
    itemList: [],
    ListStatusCertificated:[
    { text:'ทั้งหมด',value: ""},
    { text:'สำเร็จ',value: "success"},
    { text:'ไม่สำเร็จ',value: "fail"},
    { text:'รอการสร้างใหม่',value: "waiting"},
    { text:'กำลังดำเนินการ',value: "in_progress"},
    ],
    statusCertificated:'',
    itemTableCount: null,
    searchKeyword: undefined,
    pageOption: {
      page: 1,
      itemsPerPage: 20,
      keyword: "",
    },
    footerTableProps: {
      "items-per-page-text": "จำนวนแถวต่อหน้า",
      "items-per-page-options": [20, 40, 50, -1],
    },
    loading: false,
    isGenerate: false,
  }),
  methods: {
    closeModal() {
      this.itemTableCount = null;
      this.itemList = [];
      this.pageOption.itemsPerPage = 20,
      this.statusCertificated ='';
      this.$emit("updateDetailCertificated", this.isGenerate);
    },
    confirmKeyword(keyword) {
      this.pageOption = {
        ...this.pageOption,
        keyword,
      };
      this.getDetailCertificated(this.pageOption);
    },
    async getDetailCertificated({ page, keyword, itemsPerPage: limit } = {}) {
      try {
        this.loading = true;
        const [itemRegisRound, countItemRegisRound] = await Promise.allSettled([
          this.axios.get("/register_round/api/v1/get_regis_round", {
            params: {
              offset: (page - 1) * limit,
              round: this.round,
              limit: limit === -1? 'all' :limit ,
              keyword,
              status: this.statusCertificated
            },
          }),
          this.axios.get("/register_round/api/v1/count_regis_round", {
            params: {
              round: this.round,
              keyword,
              status: this.statusCertificated
            },
          }),
        ]);
        if (
          itemRegisRound["status"] === "fulfilled" &&
          itemRegisRound["value"]["data"]["status"]
        ) {
          this.itemList = itemRegisRound["value"]["data"]["data"].map(
            (item) => {
              return {
                ...item,
                regenerate_loading: false,
              };
            }
          );
        } else {
          throw new Error(itemRegisRound["value"]["data"]["message"]);
        }

        if (
          countItemRegisRound["status"] === "fulfilled" &&
          countItemRegisRound["value"]["data"]["status"]
        ) {
          this.itemTableCount = countItemRegisRound["value"]["data"]["total"];
        } else {
          throw new Error(countItemRegisRound["value"]["data"]["message"]);
        }

        // const tableElement = document.getElementsByClassName(
        //   "more-detail-certificated-table"
        // )[0];
        // tableElement.getElementsByClassName(
        //   "v-data-table__wrapper"
        // )[0].scrollTop = 0;
      } catch (error) {
        this.itemList = [];
        this.itemTableCount = 0;
        const errorMsg = error?.message ?? error;
        this.alertError("เกิดข้อผิดพลาด!<br />กรุณาลองใหม่อีกครั้ง");
        console.log(errorMsg);
      } finally {
        this.loading = false;
      }
    },
    async reGenerateCertificate(item, index) {
      const { _id, full_name_th } = item;
      if (index < 0) {
        return;
      }
      this.isGenerate = true;
      this.$set(this.itemList[index], "regenerate_loading", true);
      try {
        const { data } = await this.axios.post(
          `/register_round/api/v1/get_certificate`,
          {
            regis_id: _id,
          }
        );
        if (data["status"]) {
          this.$set(this.itemList[index], "status", "in_progress");
          this.getDetailCertificated(this.pageOption);
        } else {
          if (data["message"] === "missing information") {
            this.alertError(
              `สร้างประกาศนียบัตรของ<br />${full_name_th}<br /><b>ไม่สำเร็จ!</b> ข้อมูลไม่ครบ`
            );
          } else if (data["message"] === "image missing") {
            this.alertError(
              `สร้างประกาศนียบัตรของ<br />${full_name_th}<br /><b>ไม่สำเร็จ!</b> ไม่พบรูปภาพ`
            );
          } else {
            throw new Error();
          }
        }
      } catch (error) {
        this.alertError(
          `สร้างประกาศนียบัตรของ<br />${full_name_th}<br /><b>ไม่สำเร็จ!</b> กรุณาติดต่อเจ้าหน้าที่`
        );
        console.log(error);
      } finally {
        this.$set(this.itemList[index], "regenerate_loading", false);
      }
    },
    confirmModal(item, index) {
      const { full_name_th, status } = item;
      if (!["fail", "waiting"].includes(status)) {
        return;
      }
      this.$swal({
        title: `คุณต้องการสร้างประกาศนียบัตรของ${full_name_th} หรือไม่?`,
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ปิด",
        width: "363px",
        customClass: {
          title: "sweet-confirm-text",
          popup: "sweet-confirm-card",
          confirmButton: "sweet-confirm-btn",
          cancelButton: "sweet-confirm-cancel-btn",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          this.reGenerateCertificate(item, index);
        }
      });
    },
    alertError(html = "", timer = 4000) {
      this.$swal({
        icon: "error",
        html,
        showCloseButton: true,
        showConfirmButton: false,
        timer: timer,
        customClass: {
          htmlContainer: "alert-text",
        },
      });
    },
    async getStaffDetail(item,action){
      try {
        this.loading = true;
        const { data } = await this.axios.get("register_round/api/v1/get_regis_user", {
            params: {
              _id : item._id
            },
          })
        if(data.status){
          this.openDetailRegisLog(data.data,action);
        }
      } catch (error) {
        this.itemList = [];
        this.itemTableCount = 0;
        const errorMsg = error?.message ?? error;
        this.alertError("เกิดข้อผิดพลาด!<br />กรุณาลองใหม่อีกครั้ง");
        console.log(errorMsg);
      } finally {
        this.loading = false;
      }
    },
    openDetailRegisLog(data,action) {
        EventBus.$emit('openDetailStaffByRoundModal', data,action);
    }
  },
};
</script>

<style scoped>
* {
  color: #000;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Noto Sans Thai", sans-serif !important;
}

.row {
  padding: 0;
  margin: 0;
  width: 100% !important;
  box-sizing: border-box !important;
}

.card-container {
  padding: 0;
  border-radius: 15px;
}

.tab-header {
  padding: 8px 12px !important;
  color: #fff9c4;
  background-color: rgb(60, 86, 147);
}

.text-header {
  color: #fff9c4;
}

.text-filter-input >>> .v-input__control > .v-input__slot {
  min-height: auto;
}

.text-filter-input
  >>> .v-input__control
  > .v-input__slot
  > .v-text-field__slot
  > .v-label {
  top: 50%;
  transform: translateY(-50%);
}

.text-filter-input
  >>> .v-input__control
  > .v-input__slot
  > .v-input__append-inner {
  margin: 0;
  height: 100%;
  display: flex;
  align-items: center;
}

.data-table {
  width: 100%;
}

.data-table >>> .v-data-table__wrapper {
  height: calc(100vh - 330px);
  scroll-behavior: smooth;
  overflow: auto;
}

.data-table
  >>> .v-data-table__wrapper
  > table
  > .v-data-table-header
  > tr
  > th {
  background-color: #f0f2f5;
  font-weight: 700 !important;
  font-size: 13px;
}

.data-table >>> .v-data-table__wrapper > table > tbody > tr > td {
  padding: 0 8px;
  font-size: 13px;
}

.data-table >>> .v-data-table__wrapper > table > tbody > tr > td:nth-child(3) {
  min-width: 180px !important;
}

.data-table >>> .v-data-table__wrapper > table > tbody > tr > td:nth-child(5) {
  min-width: 220px !important;
}

.data-table >>> .v-data-table__wrapper > table > tbody > tr:hover {
  background-color: #f0f2f5 !important;
}

.data-table
  >>> .v-data-table__wrapper
  > table
  > .v-data-table-header
  > tr
  > th:first-child {
  border-radius: 10px 0 0 0;
}

.data-table
  >>> .v-data-table__wrapper
  > table
  > .v-data-table-header
  > tr
  > th:last-child {
  border-radius: 0 10px 0 0;
}

.tooltip-action-btn {
  font-family: "Noto Sans Thai", sans-serif !important;
  padding: 0 12px;
  font-size: 12px !important;
  color: #fff;
}

.list-action-menu {
  border-radius: 24px;
}

.menu-action-btn >>> .v-btn__content {
  justify-content: end;
}

.list-text-action-menu {
  font-size: 14px;
  color: #000;
}

@media only screen and (max-width: 37.5rem) {
  .text-header {
    font-size: 22px;
  }

  .card-text-container {
    padding: 12px !important;
  }

  .data-table >>> .v-data-table__wrapper {
    border: 1px solid #d1d1d1;
    border-radius: 15px;
    height: unset;
    max-height: calc(100vh - 300px);
  }

  .data-table >>> .v-data-table__wrapper > table > tbody > tr:nth-child(odd) {
    background-color: #f0f2f5;
  }

  .data-table >>> .v-data-table__wrapper > table > tbody > tr > td {
    min-height: 32px;
    font-size: 12px;
  }

  .data-table >>> .v-data-table__wrapper > table > tbody > tr > td:first-child {
    padding-top: 16px;
  }

  .data-table >>> .v-data-table__wrapper > table > tbody > tr > td:last-child {
    padding-bottom: 16px;
  }

  .data-table
    >>> .v-data-table__wrapper
    > table
    > tbody
    > tr:hover:nth-child(even) {
    background-color: #fff !important;
  }

  .data-table >>> .v-data-footer {
    margin-right: 0;
    justify-content: end;
  }

  .data-table >>> .v-data-footer > .v-data-footer__select {
    display: none;
  }

  .text-status--success {
    font-weight: 700;
    color: #2ecc71;
  }

  .text-status--fail {
    font-weight: 700;
    color: #9ea5aa;
  }

  .regenerate-btn--mobile {
    font-size: 12px;
    padding: 0 8px !important;
    color: #fff;
    font-weight: 700;
  }
}

@media only screen and (max-width: 25rem) {
  .text-header {
    font-size: 18px;
  }
}
</style>
