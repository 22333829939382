<template>
  <v-app>
    <v-content>
      <router-view />
    </v-content>
  </v-app>
</template>

<script>
import { EventBus } from './EventBus'
export default {
  name: "App",

  data: () => ({
    //
  }),
  mounted() {
    EventBus.$on('alert-success', this.alertSuccess)
    EventBus.$on('alert-error', this.alertError)
    EventBus.$on('alert-warning', this.alertWarning)
  },
  methods: {
    alertSuccess(html = '', timer = 4000) {
      this.$swal({
        icon: 'success',
        html,
        showCloseButton: true,
        showConfirmButton: false,
        timer: timer,
        customClass: {
          htmlContainer: 'alert-text'
        }
      })
    },
    alertError(html = '', timer = 4000) {
      this.$swal({
        icon: 'error',
        html,
        showCloseButton: true,
        showConfirmButton: false,
        timer: timer,
        customClass: {
          htmlContainer: 'alert-text'
        }
      })
    },
    alertWarning(html = '', timer = 4000) {
      this.$swal({
        icon: 'warning',
        html,
        showCloseButton: true,
        showConfirmButton: false,
        timer: timer,
        customClass: {
          htmlContainer: 'alert-text'
        }
      })
    }
  },
  beforeDestroy() {
    EventBus.$off('alert-success')
    EventBus.$off('alert-warning')
    EventBus.$off('alert-error')
  }
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Thai&display=swap');

/* ================================== alert css ============================ */
.alert-text {
  font-family: 'Noto Sans Thai', sans-serif;
  color: black !important;
}

/*======================= Sweet Alert confirm pop-up css ==========================*/
.sweet-confirm-text {
  font-family: 'Noto Sans Thai', sans-serif;
  color: black !important;
  font-size: 21px;
  padding-bottom: 35px;
  padding-top: 30px;
}

.sweet-confirm-card {
  padding-bottom: 15px !important;
}

.sweet-confirm-btn {
  font-family: 'Noto Sans Thai', sans-serif;
  background-color: rgb(60,86,147) !important;
}

.sweet-confirm-btn:focus {
  box-shadow: none !important;
}

.sweet-confirm-cancel-btn {
  font-family: 'Noto Sans Thai', sans-serif;
  background-color: rgb(154,179,209) !important;
  width: 74px;
}

/*========================================*/

@media only screen and (max-width:600px) {

  /*css for mobile screen*/
  .display-pc-only {
    display: none !important;
  }
}

/*==========================================*/

@media only screen and (min-width: 600px) {

  /*css for pc screen*/
  .display-mobile-only {
    display: none !important;
  }
}</style>
